import { Container, Image, Group, Title, Avatar, useMantineTheme } from "@mantine/core";
import { useEffect, useState } from "react";
import { AAMiddleware } from "../../store/AAMiddleware";
import * as ih from '../../lib/ImageHelper'

const api = new AAMiddleware()

export function CollectionHeader({collection}) {

    const [colTheme,setColTheme] = useState<any>()
    const [themeLoading,setThemeLoading] = useState(true)

    
    const theme = useMantineTheme();

    const secondaryColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[1]
      : theme.colors.gray[7];

    const baseTextColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[7]
      : theme.colors.gray[9];

    const lightTextColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[3]
      : theme.colors.gray[5];


    useEffect( () => {
        async function fetchColTheme() {
            if ( ! collection ) { return }
            const data = await api.getCollectionTheme(collection.name)
            setColTheme(data)
        }    
        fetchColTheme();
    }, []);

    const images = collection?.data.images ? JSON.parse(collection.data.images) : {}
    const heroImgUrl = images['banner_1920x500'] ? ih.resolveUrl(images['banner_1920x500'],'',true) : colTheme?.banner ? ih.resolveUrl(colTheme.banner) : '/images/default-banner.jpg'
    const collectionImgUrl = ih.resolveUrl(collection ? collection.img : '', 'h=130')

    return (
        <Container fluid px={0} py={0}>
            <Image id='heroImage' height={220} width="100%" src={heroImgUrl} />
            <Container px='xl'>
                <Group position='center'>
                    <Avatar mt={'-65px'} styles={{root: { backgroundColor: 'white', border: '2px solid white'  }}} radius={130} size={130} src={collectionImgUrl} />
                </Group>
                <Group position='center'>
                    <Title pt='xl' order={1}>{collection?.name}</Title>
                </Group>
                <Group position='center'>
                    <Title color={lightTextColor} order={6} style={{fontWeight:'normal'}}>Created by {collection?.author}</Title>
                </Group>
            </Container>
        </Container>
    )
}