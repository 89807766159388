import { Space, Grid, Chip, Card, Image, Text, Badge, Button, Group, useMantineTheme, Anchor } from '@mantine/core';
import { ITemplate, ITemplateStats } from 'atomicassets/build/API/Explorer/Objects';

import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IElasticTemplate } from 'nftbackers-elastic-interfaces'
import { CardImage } from './CardImage';
import { IGenericCardProps } from './GenericCard';
import { AAMiddleware } from '../../../store/AAMiddleware';

const api = new AAMiddleware()

export interface ITemplateExtended extends ITemplate {
  img?: string;
  name?: string;
}

interface ITemplateCardProps extends IGenericCardProps {
  item: ITemplateExtended | IElasticTemplate;
}

export function TemplateCard({item, buttons, options, path} : ITemplateCardProps) {
    const theme = useMantineTheme();

    const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];
 
    if ( ! buttons ) { buttons = [] }
    if ( ! options ) { options = {} }

    const toPath = path + item.template_id

    // Negotiate the various interfaces
    let itemTmp : any = item
    const img = itemTmp.immutable_data ? itemTmp.immutable_data.img : item.img
    const name = itemTmp.immutable_data ? itemTmp.immutable_data.name : item.name
    const collection = itemTmp.collection_name ?? itemTmp.collection.name 
  
    const image =  (
      <CardImage
        src={img}
        alt={name}
        onClick={()=>{}} />
    )

    return (
      <Card radius="lg" shadow={options.shadow} p="lg">
        <Card.Section>
            <Group position="center">
                { options.nolink ?
                    image
                    :   <Anchor component={Link} to={toPath}>
                          {image}
                        </Anchor>
                }
            </Group>
        </Card.Section>
                 
        <Group position='apart'>
          <div>
            <Group position='left' style={{ marginBottom:0, marginTop: 0 }}>
              <Text align='left' pt='xl' pb={0} px='md' size='xs' weight={700} color={theme.colors.dark[3]}>{collection}</Text> 
            </Group>
            <Group position='left' style={{ marginBottom: theme.spacing.md, marginTop: 0 }}>
              <Text
                style={{
                  overflow: 'hidden',
                  maxWidth: '25ch',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }} 
                align='left' pt={0} px='md' size='xs' weight={700} color={theme.colors.dark[5]}>{item.name}</Text> 
            </Group>
          </div>
          <div>
            <Text size='xs' align='right'>
              Minted
            </Text>
            <Text size='sm' weight={700} align='right'>{item.issued_supply}</Text>
          </div>

        </Group>



        {
          buttons.map((b,i) => {
            return (
              <Button key={i} leftIcon={b.icon} variant="light" color="blue" fullWidth style={{ marginTop: 14 }} onClick={b.onClick}>
                {b.label}
              </Button>
            )
          })
        }
      </Card>    
  
    )
  }