import { Card, Image, Text, Button, Group, useMantineTheme, Container, TextInput } from '@mantine/core';
import { useState } from "react";

import LinesEllipsis from 'react-lines-ellipsis'

import { IToken } from '../../../config/tokens';
import { useModals } from '@mantine/modals';
import DepositWithdrawForm from '../../../pages/nftbacker/User/Creator/DepositWithdrawForm';
import { NavLink } from 'react-router-dom';


interface IBalanceCardProps {
  token: IToken;
  balance: string;
  balanceAsset: any;
  buttons: any;
  selected?: boolean;
  onClick?: any;
}

export function BalanceManageCard({token, balance, balanceAsset, buttons, selected, onClick} : IBalanceCardProps) {
    const modals = useModals();
    const theme = useMantineTheme();
    const [amount,setAmount] = useState(token.tokenTemplate.split(' ')[0])
    const [value,setValue] = useState('')
    const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];

    const ucfirst = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const openTransactModal = (mode) => {
      const id = modals.openModal({
        title: `${ucfirst(mode)} ${token.symbol}`,
        children: (
          <DepositWithdrawForm token={token} balanceAsset={balanceAsset} mode={mode} onClose={() => modals.closeModal(id)} />
        ),
      });
    };
    
    const imgSrc = token.image
  
    const image =  (
      <Image
        src={imgSrc}
        alt={token.name}
        style={{ maxWidth:100 }} />
    )

    return (
      <Card radius="lg" p="lg" onClick={onClick}>

        <Group position="apart" grow>
          {image}
          <Container>
            <Text weight={700} size='md'>{token.name}</Text>
            <Text weight={500} size='sm'>{balance}</Text>
            <Text weight={500} size='xs' color={secondaryColor}>
            <LinesEllipsis
              text={token.description}
              maxLine='3'
              basedOn='words'
              />
          </Text>
          </Container>
        </Group>

        {balanceAsset ? (
          <>
              <Group pt='lg' position="center" style={{ marginBottom: theme.spacing.md, marginTop: 0 }}>
                <Text size='xs'><strong>Deposited:</strong> {balanceAsset.deposited.toString()}</Text>
                <Text size='xs'><strong>Wallet:</strong> {balanceAsset.wallet.toString()}</Text>
                <Text size='xs'><strong>Total:</strong> {balanceAsset.total.toString()}</Text>
              </Group>

              <Group pb='md' position="center">
                <Button onClick={()=>{openTransactModal('deposit')}}>Deposit</Button>
                <Button onClick={()=>{openTransactModal('withdraw')}}>Withdraw</Button>
                {
                  token.sourceType == 'crowdsale'
                  ?
                  <Button component={NavLink} to={`/creator/buy/${token.symbol.toLowerCase()}`}>Purchase</Button>
                  : <Button component='a' target="_blank" href={token.sourceLink}>Purchase</Button>

                }
              </Group>
          </>
        ) : (<></>)}

      </Card>    
  
    )
  }