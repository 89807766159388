import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Grid, Skeleton, Container, useMantineTheme, Title, Center, createStyles, Avatar, ThemeIcon, SimpleGrid, Divider, Accordion, CardSection, ScrollArea, Anchor, Space } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { UALContext } from 'ual-reactjs-renderer';
import ReactMarkdown from 'react-markdown'
import { Asset } from '@greymass/eosio';

import { Table, Card, Image, Group, Text, Badge, Button } from '@mantine/core';

import { Stack2, Book, ArrowsRightLeft, LayoutGridAdd, Flame, User, Barcode, AlignJustified, ListDetails, Briefcase, File, InfoCircle, CirclePlus, ArrowsLeftRight, Receipt, Replace, BuildingBank, Clock, Tag, FloatLeft, Database, Sum } from 'tabler-icons-react';

import Moment from 'react-moment'
import { IAsset, IAssetStats, ICollection, ILog, ITemplate, ITemplateStats } from 'atomicassets/build/API/Explorer/Objects';
import { useModals } from '@mantine/modals';
import { rpc } from '../..';
import LogTable from '../../components/NFT/LogTable';
import { AAMiddleware } from '../../store/AAMiddleware';
import InfiniteDataProvider, { IGridConfig } from '../../providers/InfiniteItemProvider';
import { FixedItemGrid } from '../../components/NFT/FixedItemGrid';
import { configs } from '../../providers/InfiniteItemProvider'
import _ from 'lodash'
import BackedTokenAmount from '../../components/NFT/BackedTokenAmount';
import { tokens } from '../../config/tokens';
import BackingDescription from '../../components/NFT/BackingDescription';
import { ITabItem, TabbedContent } from '../../components/TabbedContent';
import { resolveUrl } from '../../lib/ImageHelper';
import { BackedAssetCard } from '../../components/NFT/cards/BackedAssetCard';

interface ContactIconProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
    icon: React.FC<any>;
    title: React.ReactNode;
    description: React.ReactNode;
}

const dataTypeFilterMap = {
    int8: 'number',
    int16: 'number',
    int32: 'number',
    int64: 'text',
    uint8: 'number',
    uint16: 'number',
    uint32: 'number',
    uint64: 'text',
    fixed8: 'number',
    fixed16: 'number',
    fixed32: 'number',
    fixed64: 'text',
    float: 'number',
    double: 'number',
    string: 'text',
    ipfs: 'text',
    bool: 'bool',
    byte: 'text', // Unsure about this one
    image: 'text',
}

const getDataTypeFilterParam = (template:ITemplate, attribute:string) => {
    const format = template.schema.format.find( f => f.name == attribute )
    if ( ! format ) { return }
    return `data:${dataTypeFilterMap[format.type]}.${attribute}`
}

const useStyles = createStyles((theme) => ({
    card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },
  
    imageSection: {
      padding: theme.spacing.md,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottom: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,
    },
  
    label: {
      marginBottom: theme.spacing.xs,
      lineHeight: 1,
      fontWeight: 700,
      fontSize: theme.fontSizes.xs,
      letterSpacing: -0.25,
      textTransform: 'uppercase',
    },
  
    section: {
      padding: theme.spacing.md,
      borderTop: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,
    },
  
    icon: {
      marginRight: 5,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
    },

    title: {
    color: theme.colors.gray[6],
    },

    description: {
    color: theme.black,
    },

}));

const api = new AAMiddleware();

export function Template() {
    const [template,setTemplate] = useState<ITemplate>()
    const [collection,setCollection] = useState<ICollection>()
    const [filters,setFilters] = useState<Array<any>>([])
    const [stats,setStats] = useState<ITemplateStats>({assets:'0',burned:'0'})
    const [freqencyMap,setFrequencyMap] = useState({})
    const [selectedImage, setSelectedImage] = useState(0)
    const [authorDesc,setAuthorDesc] = useState('')
    const { id } = useParams()

    const ual = useContext(UALContext) as any;

    const theme = useMantineTheme();
    const { classes } = useStyles();

    const modals = useModals()

    const navigate = useNavigate();

    const secondaryColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[1]
      : theme.colors.gray[7];

    const accentColor = theme.colorScheme === 'dark'
      ? theme.colors.blue[0]
      : theme.colors.blue[5];

    const lightTextColor = theme.colorScheme === 'dark'
      ? theme.colors.gray[1]
      : theme.colors.gray[7];

   
    const child = <Skeleton height={140} radius="md" animate={false}    />;

    const descFields = ['desc','description']
    const legalFields = ['legal']
    const websiteFields = ['website','url']
    const unpackFields = ['unpack','unpackurl','unpack_url']

    const imageConfig = { fullHeight: 300, thumbHeight: 40 }


    const navigateToAuthorProfile = useCallback(() => {
        navigate(`/explorer/profile/${template?.collection.author}`, {replace: false})
    }, [navigate]);
 
    
    const getAttributes = () => {
        if ( ! template ) { return [] }
        // Only return non-image attributes
        return Object.keys(template?.immutable_data).filter(
            (a) => { return !! template.schema.format.find(
                (s) => (
                    s.name==a 
                    && s.name != 'name'
                    && s.name != 'image'
                    && s.name != 'video'
                    && s.type != 'image'
                    && ! descFields.includes(a)
                    && ! legalFields.includes(a)
                    && ! websiteFields.includes(a)
                    && ! unpackFields.includes(a)
                    // Do not include long content
                    && ! ( template.immutable_data[a] && template.immutable_data[a].length > 20 ) 
                )
            )}
        )
    }

    // Load the template
    useEffect( () => {
        const loadTemplate = async () => {        
            if ( ! id ) { return }
            // Try first to get it through elasticsearch
            let t = await api.getTemplateById(id)
            // Fallback to Atomic Assets
            if ( ! t ) {
                t = await api.getTemplates({ids:id})
            }
            setTemplate(t)
        }
        loadTemplate()
    }, [id] )

    useEffect(()=>{
      if ( ! template ) { return }
      const loadStats = async () => {
        const newStats = await api.getTemplateStats(template.collection.collection_name,template.template_id)
        if ( stats ) { setStats(newStats) }
      }
      loadStats()
    },[template])

    useEffect( () => {
        async function calcAttrFreqency() {
            if ( ! template ) { return; }
            const attrs = getAttributes()
            let freqMap = {}

            const baseQuery = {
                collection_name: template?.collection.collection_name,
                schema_name: template?.schema.schema_name,
            }

            const total = await api.countAssets(baseQuery)

            for ( let i = 0; i < attrs.length; i++ ) {
                const a = attrs[i]
                let filterParam = getDataTypeFilterParam(template,a)

                // If we can't resolve a filter param, assume the value occurs everywhere
                if ( ! filterParam ) {
                    freqMap[a] = 100
                    continue;
                }

                // Localize our query for the current attribute
                let query = Object.assign({},baseQuery)
                query[filterParam] = template.immutable_data[a]

                const count = await api.countAssets(query)

                freqMap[a] = Math.round( count / total * 100 )
            }
            setFrequencyMap(freqMap)
        }
        calcAttrFreqency();
    }, [template]);
    
    // Load the collection info
    useEffect( () => {
        const loadCollection = async () => {    
            if ( ! template?.collection.collection_name ) { return }    
            const c = await api.getCollection(template.collection.collection_name)
            setCollection(c)
        }
        loadCollection()
    }, [template] )

    // Load the author info
    useEffect( () => {
        const loadAuthorInfo = async () => {    
            if ( ! template?.collection.author ) { return }
            const query = {
                "code": "atomhubtools",
                "table": "acctexts",
                "scope": template?.collection.author,
                "lower_bound": "description",
                "upper_bound": "description",
                "limit": 1,
                "json": true
            }
            const res = await rpc.get_table_rows(query)
            if ( res.rows.length ) {
                const desc = res.rows[0].value
                setAuthorDesc(desc)
            }
        }
        loadAuthorInfo()
    }, [template] )

    useEffect( () => {
        async function fetchColFilters() {
            if ( ! template ) { return }
            let request = {
                json: true,              // Get the response as json
                code: 'atomhubtools',    // Contract that we target
                scope: template?.collection.collection_name,    // Account that owns the data
                table: 'colfilters',     // Table name
                limit: '1000',             // Maximum number of rows that we want to get PER REQUEST PAGE
                reverse: false,          // Optional: Get reversed data
                show_payer: false,       // Optional: Show ram payer
                lower_bound: null,
            };
    
            const res = await rpc.get_table_rows(request) as any
            const newFilters = res.rows
    
            setFilters(newFilters)  
        }
    
        fetchColFilters();
    
    }, [rpc, template]);


    const getFeatures = () => {
        if ( ! template ) { return [] }
        return [
            { title: 'Collection', label: <Anchor href={'/explorer/collection/'+template.collection.collection_name}>{template.collection.collection_name}</Anchor>, icon: Stack2, visible: true },
            { title: 'Schema', label: template.schema.schema_name, icon: Book, visible: true },
            { title: 'Template', label: '#' + template.template_id, icon: Barcode, visible: true },
            { title: 'Author', label: template.collection.author, icon: User, visible: true },
            { title: 'Minted', label: stats.assets, icon: Sum, visible: true },
            { title: ( template.is_burnable ? 'Burned' : false ), label: ( template.is_burnable ? stats.burned : 'Not Burnable'), icon: Flame, visible: true },
            { title: false, label: ( template.is_transferable ? 'Transferable' : 'Non-Transferable'), icon: ArrowsRightLeft, visible: true },
            // { title: 'Burned', label: stats.burned, icon: Flame, visible: template.is_burnable },
            { title: 'Backed By', label: <Text size='sm' weight={700}>{template.immutable_data.backedby}</Text>, icon: Database, visible: !! template.immutable_data.backedby },
        ];
    }
    const getImages = () => {
        if ( ! template ) { return [] }
        return template.schema.format.filter(i=>( i.type == 'image' && template.immutable_data[i.name])).map(i=>template.immutable_data[i.name])
    }

    const thumbs = getImages().map((img,i)=>{
        const click = () => {
            setSelectedImage(i)
        }
        return (
            <Avatar 
                key={`thumb-${i}`} 
                size={imageConfig.thumbHeight} 
                src={resolveUrl( img, '?h=' + imageConfig.thumbHeight )}
                // src={process.env.REACT_APP_IMAGE_PROXY+img+'?h=' + imageConfig.thumbHeight} 
                radius={imageConfig.thumbHeight} 
                style={{border: ( selectedImage == i ? '1px' : '0px' ) + ' solid ' + theme.colors.gray[5]}}
                onClick={click}
            />
        )
    })

    const attributes1 = getAttributes().map((a) => {
        return (
            <Grid key={'attribute-'+a}>
                <Grid.Col span={2}><Text size='sm' weight='bold'>{a}</Text></Grid.Col>
                <Grid.Col span={10}><Text size='sm' style={{overflowWrap: 'anywhere'}}>{template?.immutable_data[a]}</Text></Grid.Col>
            </Grid>
        )
    })

    const attributes = (
        <Group>
            {
                getAttributes().map((a) => {
                    return (
                        <Card
                            key={'attribute-card-'+a}
                            withBorder={true}
                            radius='md'
                            px='xs'
                            py='xs'
                            sx={(theme) => ({
                                borderWidth: '1px',
                                borderColor: theme.colors.gray[5],
                                width: '150px',
                                backgroundColor: theme.colors.gray[3],
                                '&:hover': {
                                    backgroundColor: theme.colors.gray[4],
                                },
                            })}
                        >
                            <Group position="center">
                                <Text size='xs' style={{textTransform:'uppercase'}} color={accentColor} weight='bold'>{a}</Text>
                            </Group>
                            <Group position="center">
                                <Text size='sm' style={{overflowWrap: 'anywhere'}}>{template?.immutable_data[a]}</Text>
                            </Group>
                            <Group position="center">
                                <Text size='xs' color={lightTextColor} weight='normal'>{freqencyMap[a]}% have this trait</Text>
                            </Group>
                        </Card>
                    )
                })
            }
        </Group>
    )

    const features = getFeatures().map((feature)=>{
        if ( ! feature.visible ) { return }
        return (
            <Grid.Col span={6} key={`detail-feature-${feature.title}-${feature.label}`}>
                <Group noWrap>
                    <ThemeIcon size={40} radius="md" className={classes.icon} variant="light">
                        <feature.icon size={24} />
                    </ThemeIcon>
                    <div>
                        <Text size="xs" className={classes.title}>
                            {feature.title}
                        </Text>
                        <Text size='sm' className={classes.description}>{feature.label}</Text>                
                    </div>
                </Group>
            </Grid.Col>
        )
    })

    const openImageModal = () => {
        const id = modals.openModal({
          size: '80%',
          withCloseButton: false,
          children: (
            <Image
                withPlaceholder 
                src={resolveUrl( getImages()[selectedImage] )}
                // src={process.env.REACT_APP_IMAGE_PROXY + getImages()[selectedImage]} 
                alt={template?.immutable_data.name}
                onClick={modals.closeAll}
            />
          ),
        });
      };
    const imageCard = (
        template ? 
            <>
                <Card>
                    <Card.Section>
                        <Image 
                            fit="contain"
                            height={imageConfig.fullHeight}
                            withPlaceholder 
                            radius="lg" 
                            src={resolveUrl( getImages()[selectedImage], '?h='+imageConfig.fullHeight )}
                            // src={process.env.REACT_APP_IMAGE_PROXY + getImages()[selectedImage] + '?h='+imageConfig.fullHeight} 
                            alt={template.immutable_data.name}
                            onClick={openImageModal}
                        />
                    </Card.Section>
                </Card>
                <Group position="center" style={{ marginBottom: theme.spacing.lg, marginTop: theme.spacing.lg }}>
                    {thumbs}
                </Group>
            </>
        : <Skeleton height={140} radius="md" animate={false} />
    )


    const summaryCard = (
        template ? 
            <Card shadow="sm" p="lg" radius="lg">
                <Title order={1}>{template.immutable_data.name}</Title>
                <Group style={{ marginBottom: theme.spacing.lg, marginTop: theme.spacing.lg }}>
                        <Grid>
                            {features}                
                        </Grid>
                </Group>

                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                </Group>
            </Card>
        : <Skeleton height={140} radius="md" animate={false} />
    )

    const collectionContent = (
        collection ?
        (
            <>
                <Text size='sm' style={{ marginBottom: theme.spacing.sm }} color={secondaryColor}>Created by <Anchor onClick={navigateToAuthorProfile}>{collection.author}</Anchor></Text>
                <ReactMarkdown>
                    {collection?.data.description}
                </ReactMarkdown>
                {/* {
                    collection?.data.description?.split("\n").map((line,i) => {
                        return (
                            <Text key={'about-collection-'+i} size='sm' style={{ marginBottom: theme.spacing.sm }}>{line}</Text>
                        )
                    })
                } */}
            </>
        )
        : ''
    )

    const authorContent = (
        collection ?
        (
            <>
                {
                    authorDesc.split("\n").map((line,i) => {
                        return (
                            <Text key={'about-author-'+i} size='sm' style={{ marginBottom: theme.spacing.sm }}><ReactMarkdown>{line}</ReactMarkdown></Text>
                        )
                    })
                }
            </>
        )
        : ''
    )

    const getField = (names,type) => {
        if (! template) { return }
        return Object.keys(template.immutable_data).find((a) => {
            return template.schema.format.find((f) => {
                return names.includes(f.name) && f.name == a && f.type == type
            })
        })
    }

    const getDescription = () => {
        if (! template || ! descField ) { return }
        return template.immutable_data[descField].split("\n").map((line,i)=>{
            return (
                <Text key={'asset-description-'+i} size='sm' style={{ marginBottom: theme.spacing.sm }}>{line}</Text>                
            )
        })
    }

    // Common attribute fields
    const descField = getField(descFields,'string')
    const legalField = getField(legalFields,'string')
    const websiteField = getField(websiteFields,'string')
    const unpackField = getField(unpackFields,'string')

    const description = descField ? template?.immutable_data[descField] : ''
    const legal = legalField ? template?.immutable_data[legalField] : ''
    const website = websiteField ? template?.immutable_data[websiteField] : ''
    const unpack = unpackField ? template?.immutable_data[unpackField] : ''

    const tabItems : ITabItem[] = [
        { visible: !! collection, label: 'About', icon: FloatLeft, content: <Card shadow="sm" p="lg" radius="lg">{collectionContent}</Card> },
        { visible: !! authorDesc, label: 'Author', icon: User, content: <Card shadow="sm" p="lg" radius="lg">{authorContent}</Card> },
        { visible: !! legalField, label: 'Legal', icon: Briefcase, content: <Card shadow="sm" p="lg" radius="lg"><Text size='sm'>{legal}</Text></Card> },
    ]

    const getRelatedTabItems = () => {
        if ( ! template ) { return [] }
        const otherTemplateConfig : IGridConfig = _.cloneDeep(configs.templateSearch)
        otherTemplateConfig.filters.push( (i) => i.template_id != id )
        otherTemplateConfig.filters.push( (i) => i.issued_supply > 0 )

        const thisTemplateAssetConfig : IGridConfig = _.cloneDeep(configs.asset)
        if ( template.immutable_data['backed tokens'] || template.immutable_data.backedby ) {
            thisTemplateAssetConfig.grid.gridCard = BackedAssetCard
        }
    
        const relatedTabItems : ITabItem[] = [
            { 
                label: 'NFTs',
                component: InfiniteDataProvider,
                props: {
                    additionalQueryParams: {template_id: template?.template_id, collection_name: template?.collection.collection_name},
                    title: 'NFTs',
                    withSearch: false,
                    config: thisTemplateAssetConfig,
                    pageSize: 30,
                },
                icon: File,
                visible: true,
            },
            { 
                label: 'Other Templates',
                component: InfiniteDataProvider,
                props: {
                    additionalQueryParams: {collection_name: template?.collection.collection_name },
                    title: 'Other Templates',
                    withSearch: true,
                    component: FixedItemGrid,
                    config: otherTemplateConfig,
                    pageSize: 30,
                },
                icon: File,
                visible: true,
            }
        ]
        return relatedTabItems
    }

    // Accordian Configs
    const accordians = {
        description: { visible: !! descField, label: 'Description', icon: <AlignJustified />, content: getDescription() },
        backing: { visible: !! template?.immutable_data.backedby, label: 'Backed by NFT Backers', icon: <Database />, content: <BackingDescription backedby={template?.immutable_data.backedby} redeem_url={template?.immutable_data.redeem_url} /> },
        attributes: { visible: getAttributes().length, label: 'Properties', icon: <Tag />, content: attributes },
        // collection: { visible: !! collection, label: 'About ' + collection?.name, icon: <FloatLeft />, content: collectionContent },
        // author: { visible: !! authorDesc, label: 'Author', icon: <User />, content: authorContent },
        // legal: { visible: !! legalField, label: 'Legal', icon: <Briefcase />, content: <Text size='sm'>{legal}</Text> },
    }

    const firstAccordianRef = useRef<HTMLButtonElement>();

    const accordianCard = (
        <Card shadow="sm" p="lg" radius="lg">
            <CardSection>
                <Accordion initialItem={0} iconPosition="right">
                    {
                        Object.keys(accordians).filter((i)=>accordians[i].visible).map((i,idx)=>{
                            const a = accordians[i]
                            // if (!a.visible) { return }
                            const label = (
                                <Group noWrap>
                                    {a.icon}
                                    <div>
                                        <Text>{a.label}</Text>
                                    </div>
                                </Group>                                
                            )
                            return (
                                <Accordion.Item label={label} key={template?.template_id+'accordian-'+i}>
                                    {a.content}
                                </Accordion.Item>
                            )
                        })
                    }
                </Accordion>
            </CardSection>
        </Card>
    )

    return (
        <Container px='xl' py='xl'>
            <Grid mb='xl'>
                <Grid.Col xs={4}>{imageCard}</Grid.Col>
                <Grid.Col xs={8}>{summaryCard}</Grid.Col>
                <Grid.Col xs={12}>{accordianCard}</Grid.Col>
                <Grid.Col xs={12} my='xl'>
                    <Title mb='lg' order={2}>More Information</Title>
                    <TabbedContent items={tabItems} />
                </Grid.Col>
                {/* <Grid.Col xs={4}>{child}</Grid.Col>
                <Grid.Col xs={3}>{child}</Grid.Col>
                <Grid.Col xs={3}>{child}</Grid.Col>
                <Grid.Col xs={6}>{child}</Grid.Col> */}
                <Grid.Col xs={12}>
                    {
                        template ?
                        <TabbedContent items={getRelatedTabItems()} />
                        : ''
                    }
                </Grid.Col>
            </Grid>

            {/* { template?.collection.collection_name ?
                <InfiniteDataProvider additionalQueryParams={{collection_name: template?.collection.collection_name}} title='Other Templates' withSearch={true} component={FixedItemGrid} config={otherTemplateConfig} pageSize={30} />
                : <></>
            } */}

        </Container>
    );
}