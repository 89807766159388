import { Space, Grid, Chip, Card, Image, Text, Badge, Button, Group, useMantineTheme } from '@mantine/core';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flame, Plus } from 'tabler-icons-react';

interface AssetGridProps {
  assets: any;
  buttons?: any;
  onCollectionFilter: any;
  GridCard: any;
}

interface AssetCardProps {
  asset: any;
  buttons?: any;
  onCollectionFilter?: any;
  options?: any;
  onDetails: any;
}

export default function AssetGrid({assets, buttons, onCollectionFilter, GridCard} : AssetGridProps) {
    const theme = useMantineTheme();

    const navigate = useNavigate();

    const handleOnDetails = useCallback((id) => {
      navigate(`/explorer/asset/${id}`, {replace: false})
    }, [navigate]);
  

    if ( ! GridCard ) {
      GridCard = GenericAssetCard
    }

    if ( ! buttons ) { buttons = [] }

    const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];


    return (
        <Grid gutter="xl">
          {
            assets.map((asset) => { 
              return (
                <Grid.Col xs={12} md={6} lg={4} key={asset.asset_id}>
                  <GridCard asset={asset} buttons={buttons} onCollectionFilter={onCollectionFilter} onDetails={handleOnDetails}></GridCard>
                </Grid.Col>
              )
            })
          }
        </Grid>
      )

}

// export function GenericAssetCard({asset,buttons, onCollectionFilter} : AssetCardProps) {
//   const theme = useMantineTheme();
//   const secondaryColor = theme.colorScheme === 'dark'
//   ? theme.colors.dark[1]
//   : theme.colors.gray[7];
//   const collectionClick = () => { if (onCollectionFilter) { onCollectionFilter(asset.collection.collection_name) } }

//   if ( ! buttons ) { buttons = [] }

//   return (
//     <Card radius="lg" shadow="md" p="lg">
//       <Group position="center" style={{ marginBottom: theme.spacing.md, marginTop: 0 }}>
//         {
//           asset.collection ? 
//             <Chip value={asset.asset_id}>{asset.collection.name}</Chip> :
//             ''
//         }
//       </Group>

//       <Group position='center'  style={{ marginBottom: theme.spacing.md, marginTop: theme.spacing.sm }}>
//         <Image 
//           height={300}
//           withPlaceholder 
//           radius="lg" 
//           src={process.env.REACT_APP_IMAGE_PROXY+asset.img+'?h=300'} 
//           alt={asset.name} />
//       </Group>

//       <Group position="apart" style={{ marginBottom: theme.spacing.md, marginTop: theme.spacing.sm }}>
//         <Text weight={500}>{asset.name}</Text>
//         {
//           asset.collection ? 
//           <Badge component="a" color='blue' variant="light" onClick={collectionClick}>
//             {asset.collection.name}
//           </Badge>
//            :
//             ''
//         }
//       </Group>

//       {/* <Text size="sm" style={{ color: secondaryColor, lineHeight: 1.5 }}>
//         {asset.name}
//       </Text> */}

//       {
//         buttons.map((b,i) => {
//           return (
//             <Button key={i} leftIcon={b.icon} variant="light" color="blue" fullWidth style={{ marginTop: 14 }} onClick={() => {b.onClick(asset.asset_id)}}>
//               {b.label}
//             </Button>
//           )
//         })
//       }
//     </Card>    
//   )
// }

export function GenericAssetCard({asset,buttons, onCollectionFilter, options, onDetails} : AssetCardProps) {
  const theme = useMantineTheme();
  const secondaryColor = theme.colorScheme === 'dark'
  ? theme.colors.dark[1]
  : theme.colors.gray[7];
  const collectionClick = () => { if (onCollectionFilter) { onCollectionFilter(asset.collection.collection_name) } }

  if ( ! buttons ) { buttons = [] }
  if ( ! options ) { options = {} }

  const image =  (
    <Image 
      height={300}
      withPlaceholder 
      radius="lg" 
      src={process.env.REACT_APP_IMAGE_PROXY+asset.img+'?h=300'} 
      alt={asset.name}
      onClick={()=>{onDetails(asset.asset_id)}} />
  )

  return (
    <Card radius="lg" shadow={options.shadow} p="lg">
      {
        options.minimal ?
        '' :
        (
          <>
            <Group position="center" style={{ marginBottom: theme.spacing.md, marginTop: 0 }}>
              {
                asset.collection ? 
                  <Chip value={asset.asset_id} onClick={collectionClick}>{asset.collection.name}</Chip> :
                  ''
              }
            </Group>
          </>  
        )
      }

      <Group position='center'  style={{ marginBottom: theme.spacing.md, marginTop: theme.spacing.sm }}>
        { options.nolink ?
              image
          :   <a href={'/explorer/asset/'+asset.asset_id} onClick={(e)=>{e.preventDefault();}}>
                {image}
              </a>
        }
      </Group>

      <Group position="apart" style={{ marginBottom: theme.spacing.md, marginTop: theme.spacing.sm }}>
        <Text weight={500}>
          {asset.name}
          { asset.template_mint ? 
            <em> - #{asset.template_mint}</em>
            : <></>
          }
        </Text>
      </Group>

      {/* <Group position="apart" style={{ marginBottom: theme.spacing.md, marginTop: theme.spacing.sm }}>
        {
          asset.backed_tokens.map((t) => {
            return (
              <Badge color='green' variant="light" key={t}>
                {t}
              </Badge>
            )
          })
        }
      </Group> */}

      {
        buttons.map((b,i) => {
          return (
            <Button key={i} leftIcon={b.icon} variant="light" color="blue" fullWidth style={{ marginTop: 14 }} onClick={() => {b.onClick(asset.asset_id)}}>
              {b.label}
            </Button>
          )
        })
      }
    </Card>    

  )
}


export function BackedAssetCard({asset,onCollectionFilter, buttons, options, onDetails} : AssetCardProps) {
  const theme = useMantineTheme();
  const secondaryColor = theme.colorScheme === 'dark'
  ? theme.colors.dark[1]
  : theme.colors.gray[7];

  if ( ! buttons ) { buttons = [] }

  if ( ! options ) { options = {} }
  if ( ! options.shadow ) { options.shadow = 'md' }

  const collectionClick = () => { if (onCollectionFilter) { onCollectionFilter(asset.collection.collection_name) } }

  const image =  (
    <Image 
      height={300}
      withPlaceholder 
      radius="lg" 
      src={process.env.REACT_APP_IMAGE_PROXY+asset.img+'?h=300'} 
      alt={asset.name}
      onClick={()=>{onDetails(asset.asset_id)}} />
  )

  return (
    <Card radius="lg" shadow={options.shadow} p="lg">
      {
        options.minimal ?
        '' :
        (
          <>
            <Group position="center" style={{ marginBottom: theme.spacing.md, marginTop: 0 }}>
              {
                asset.collection ? 
                  <Chip value={asset.asset_id} onClick={collectionClick}>{asset.collection.name}</Chip> :
                  ''
              }
            </Group>
          </>  
        )
      }

      <Group position='center'  style={{ marginBottom: theme.spacing.md, marginTop: theme.spacing.sm }}>
      { options.nolink ?
              image
          :   <a href={'/explorer/asset/'+asset.asset_id} onClick={(e)=>{e.preventDefault();}}>
                {image}
              </a>
      }
      </Group>

      <Group position="apart" style={{ marginBottom: theme.spacing.md, marginTop: theme.spacing.sm }}>
        <Text weight={500}>
          {asset.name}
          { asset.template_mint ? 
            <em> - #{asset.template_mint}</em>
            : <></>
          }
        </Text>
      </Group>

      <Group position="apart" style={{ marginBottom: theme.spacing.md, marginTop: theme.spacing.sm }}>
        {
          asset.backed_tokens.map((t) => {
            return (
              <Badge color='green' variant="light" key={t}>
                {t}
              </Badge>
            )
          })
        }
      </Group>

      {
        buttons.map((b,i) => {
          return (
            <Button key={i} leftIcon={b.icon} variant="light" color="blue" fullWidth style={{ marginTop: 14 }} onClick={() => {b.onClick(asset.asset_id)}}>
              {b.label}
            </Button>
          )
        })
      }
    </Card>    
  )
}