import { forwardRef } from 'react';
import { Group, Avatar, Text, Select } from '@mantine/core';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image: string;
  label: string;
  description: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={image} />

        <div>
          <Text size="sm">{label}</Text>
          <Text size="xs" color="dimmed">
            {description}
          </Text>
        </div>
      </Group>
    </div>
  )
);

const BackedByDropdown = (props) => {
  const { tokens, onChange, value, size } = props;

  const onChangeCallback = (value) => {
    if ( onChange ) { onChange(value) }
  }

  const getImageName = (token) => {
    return `${token.symbol.toLowerCase()}_${token.contract}.png`
  }

  let options = [] as any
  tokens.forEach((token) => {
    token.backingOptions.forEach((o) => {
      options.push( {
        image: `/tokens/${getImageName(token)}`,
        label: `${token.name} - ${o}`,
        value: o,
        description: token.description,
        others: { key: o.replace(' ','') }
      } )
    })
  })

  return (
    <Select
      itemComponent={SelectItem}
      data={options}
      maxDropdownHeight={400}
      onChange={onChange}
      value={value}
      label='backedby'
      size={size}
    />    
  )

}

export default BackedByDropdown;
