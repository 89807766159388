import { Space, Grid, Chip, Card, Image, Text, Badge, Button, Group, useMantineTheme, Anchor, CardSection, FloatingTooltip, Container, Popover, Title } from '@mantine/core';
import { ISchema, ISchemaStats } from 'atomicassets/build/API/Explorer/Objects';
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CardImage } from './CardImage';
import { AAMiddleware } from '../../../store/AAMiddleware';
import { Database, DatabaseOff, List, ShieldCheck } from 'tabler-icons-react';
import { UALContext } from "ual-reactjs-renderer";


import _ from 'lodash'
import UpgradeSchema from '../../../lib/UpgradeSchema';

const api = new AAMiddleware()

export interface IBackedSchemaCardProps {
  item: ISchema;
  buttons?: any;
  options?: any;
  path: string;
}

export function BackedSchemaCard({item, buttons, options, path} : IBackedSchemaCardProps) {
    const theme = useMantineTheme();
    const ual = useContext(UALContext) as any
    const [opened,setOpened] = useState(false)

    const [stats,setStats] = useState<ISchemaStats>()

    const secondaryColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[1]
      : theme.colors.gray[7];

    const accentColor = theme.colorScheme === 'dark'
      ? theme.colors.blue[0]
      : theme.colors.blue[5];

    const lightTextColor = theme.colorScheme === 'dark'
      ? theme.colors.gray[1]
      : theme.colors.gray[7];
    

    if ( ! buttons ) { buttons = [] }
    if ( ! options ) { options = {} }

    const onUpgradeSchema = () => {
      UpgradeSchema(item.collection.collection_name,item.schema_name,ual)
    }

    const upgradeButton = () => {
      if ( ! _.find(item.format,(f)=>f.name == 'backedby') ) {
        return (
            <Button variant="light" color="blue" fullWidth style={{ marginTop: 14 }} onClick={onUpgradeSchema}>
              Add NFT Backer Attribute
            </Button>
        )
      }
    }

    useEffect(() => {
      const loadSchemaStats = async () => {
        if ( ! item.schema_name || ! item.collection ) { return }
        setStats( await api.aaapi.getSchemaStats(item.collection.collection_name, item.schema_name))
      }
      loadSchemaStats()
    },[])

    const backedIcon = _.find(item.format,(f)=>f.name == 'backedby') ? 
      (
        <FloatingTooltip label='NFT Backers supported schema'>
          <ShieldCheck size={16} color={accentColor} />
        </FloatingTooltip>
      ) : ''
  
    const card = (
        <Card radius="lg" shadow={options.shadow} p="lg" onMouseEnter={() => setOpened(true)} onMouseLeave={() => setOpened(false)}> 
          <Group position='apart'>
            <div>
              <Group position='left' style={{ marginBottom:0, marginTop: 0 }}>
                <Text align='left' pt='lg' pb={0} px='md' size='md' weight={700} color={theme.colors.dark[5]}>{item.schema_name} {backedIcon}</Text> 
              </Group>
              <Group position='left' style={{ marginBottom: theme.spacing.md, marginTop: 0 }}>
                <Text
                  style={{
                    overflow: 'hidden',
                    maxWidth: '25ch',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }} 
                  align='left' pt={0} px='md' size='xs' weight={700} color={theme.colors.dark[3]}>{item.collection.name}</Text> 
              </Group>
            </div>
            <div >
              <Text pr='xs' size='xs' align='right'>
                Templates
              </Text>
              <Text pr='xs' size='sm' weight={700} align='right'>{stats?.templates ?? 0}</Text>
            </div>

          </Group>
    
          {
            buttons.map((b,i) => {
              return (
                <Button key={i} leftIcon={b.icon} variant="light" color="blue" fullWidth style={{ marginTop: 14 }} onClick={(e) => { b.onClick(e,item.schema_name) }}>
                  {b.label}
                </Button>
              )
            })
          }
          {upgradeButton()}
        </Card>
    )

    // Without stats, return the card itself
    if ( ! stats ) {
      return card
    }

    // Otherwise return the card wrapped in a stats popover
    return (
      <Popover
        opened={opened}
        onClose={() => setOpened(false)}
        position="bottom"
        placement="center"
        withArrow
        trapFocus={false}
        closeOnEscape={false}
        transition="pop-top-left"
        styles={{ body: { pointerEvents: 'none' } }}
        target={card}
      >
        <Text weight={700} size='sm'>{item.schema_name}</Text>
          <Container>
          {
            item.format.map((f) => ( <Text size='xs' key={f.name}><strong>{f.name}</strong> ({f.type})</Text> ) )
          }

          </Container>
      </Popover>
    );

  }