import { useContext } from 'react';
import { UALContext } from 'ual-reactjs-renderer';

import { showNotification, updateNotification } from '@mantine/notifications';
import { Flame, AlertTriangle, Check, Speakerphone } from 'tabler-icons-react';

export default async function EnableCollectionNotifications(collection, ual) {

    const auth = [{
        actor: ual.activeUser.accountName,
        permission: ual.activeUser.requestPermission,
    }]

    let actions : any = []

    actions.push({
        account: 'atomicassets',
        name: 'addnotifyacc',
        authorization: auth,
        data: {
            collection_name: collection,
            account_to_add: process.env.REACT_APP_NFTBACKER_CONTRACT,
        }
    });

    try {
        showNotification({
            id: `enable-notifications-${collection}`,
            loading: true,
            title: `Enabling Notifications`,
            message: `Preparing to enable collection notifications on collection ${collection}.`,
            autoClose: false,
            disallowClose: true,
            icon: <Speakerphone />,
        });

        const res = await ual.activeUser.signTransaction(
            {
                actions: actions
            },
            {
                blocksBehind: 3,
                expireSeconds: 60
            }
        );
        updateNotification({
            id: `enable-notifications-${collection}`,
            color: 'teal',
            title: `Success!`,
            message: `NFT Backer has been added to notifications on collection ${collection}.`,
            icon: <Check />,
            autoClose: 10000,
        });
        return res
    } catch (e : any ) {
        console.log(e);

        const msg = e.message.replace('assertion failure with message:','');

        updateNotification({
            id: `enable-notifications-${collection}`,
            color: 'red',
            title: `Unable to Enable Notifications`,
            message: msg,
            icon: <AlertTriangle />,
            autoClose: false,
        });
    }

}