import { Button, Group, Text, TextInput, Title, useMantineTheme } from "@mantine/core";
import { useForm } from "@mantine/form";
import { UALContext } from 'ual-reactjs-renderer';
import { IToken } from "../../../../config/tokens";
import { useContext, useEffect, useState } from "react"
import { AAMiddleware } from "../../../../store/AAMiddleware";
import { Asset } from '@greymass/eosio';
import Withdraw from "../../../../lib/Withdraw";
import DepositTokens from "../../../../lib/DepositTokens";

const api = new AAMiddleware()

interface IDepositWithdrawFormProps {
    token: IToken;
    balanceAsset: any;
    mode: 'deposit' | 'withdraw';
    onClose: any;
}
export default function DepositWithdrawForm({token,balanceAsset,mode,onClose}:IDepositWithdrawFormProps) {

    const theme = useMantineTheme();
    const ual = useContext(UALContext) as any

    const form = useForm({
        initialValues: {
          amount: token.tokenTemplate.split(' ')[0]
        },
        validate: {
            amount: (value) => {
                const amtAsset = Asset.from(token.tokenTemplate)
                const amt = parseFloat(value)
                if ( ! amt || amt == 0.00 || amt < 0 ) { return `Please specify an amount to ${mode}` }
                amtAsset.value = parseFloat(value)
                if ( amtAsset.value <= 0 ) { return `Please specify an amount to ${mode}` }
                if ( mode == 'deposit' && amtAsset.value > balanceAsset.wallet.value ) { return `The max you can deposit right now is ${balanceAsset.wallet.toString()}` }
                if ( mode == 'withdraw' && amtAsset.value > balanceAsset.deposited.value ) { return `The max you can withdraw is ${balanceAsset.deposited.toString()}` }
                return null
            },
          },
    });

    const secondaryColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[1]
      : theme.colors.gray[7];

    const accentColor = theme.colorScheme === 'dark'
      ? theme.colors.blue[0]
      : theme.colors.blue[5];

    const lightTextColor = theme.colorScheme === 'dark'
      ? theme.colors.gray[1]
      : theme.colors.gray[7];

    const lighterTextColor = theme.colorScheme === 'dark'
      ? theme.colors.gray[3]
      : theme.colors.gray[5];

    const confirmTransfer = () => {
        const doTransfer = async () => {
            const amtAsset = Asset.from(token.tokenTemplate)
            amtAsset.value = parseFloat(form.values.amount)
            if ( mode == 'deposit' ) {
                await DepositTokens(amtAsset,token,ual)
            } 
            else if ( mode == 'withdraw' ) {
                await Withdraw([amtAsset],ual)
            }
            if ( onClose ) { onClose() }
        }

        const result = form.validate()

        if ( ! result.hasErrors ) {
            doTransfer()
        }

    }

      const getTransactionMessage = () => {
        if ( mode == 'deposit' ) {
          return (
            <>
              <Text size="sm">
                You are about to deposit {token.symbol} to the NFT Backers contract. By doing so, you agree that any
                deposited balances may be used to back NFTs being minted by your collection. Once an NFT is
                backed, the attached value transfers ownership with the minted NFT. You may withdraw any unused
                balance whenever you like.
              </Text>
              <Text pt='lg' size="sm">
                NOTE: The NFT Backers contract will only ever allow backed NFTs to be minted while there is
                sufficient deposited balance to back those NFTs.
              </Text>
            </>
          )
        }
        else {
          return (
            <Text size="sm">
              You are about to withdraw {token.symbol} from the NFT Backers contract. You should maintain
              a balance of {token.symbol} to ensure your backed NFTs can be properly minted.
            </Text>
          )
        }
      }
  
      const getAvailableMessage = () => { 
        return (
            <>
                <Text align='center' mt='lg' size='sm'><strong>Your Current Balances Are</strong></Text>
                <Group mb='lg' position="center">
                    <Text size='xs'><strong>Deposited:</strong> {balanceAsset.deposited.toString()}</Text>
                    <Text size='xs'><strong>Wallet:</strong> {balanceAsset.wallet.toString()}</Text>
                    <Text size='xs'><strong>Total:</strong> {balanceAsset.total.toString()}</Text>
                </Group>
            </>
        )
      }      


    return (
        <>
            {getTransactionMessage()}
            {getAvailableMessage()}
            <TextInput
                required
                label="Amount"
                onBlur={()=>{
                    const amt = parseFloat(form.values.amount)
                    if ( ! amt ) {
                        form.setFieldValue('amount',token.tokenTemplate.split(' ')[0])
                    }
                    else {
                        const amtAsset = Asset.from(token.tokenTemplate)
                        amtAsset.value = amt
                        form.setFieldValue('amount',amtAsset.toString().split(' ')[0])    
                    }
                    form.validate()
                }}
                {...form.getInputProps('amount')}                
                />

                <Group position="center">
                    <Button my='xl' onClick={onClose}>Cancel</Button>
                    <Button my='xl' onClick={confirmTransfer}>Confirm</Button>
                </Group>

        </>

    );

}

