import React, { createContext, useState, useContext, Dispatch, SetStateAction, useReducer } from "react";
import AppReducer, { ActionType } from "./AppReducer";

export interface GlobalStateInterface {
    isLoading: boolean;
}

const defaultState : GlobalStateInterface = {
    isLoading: false,
}

const GlobalStateContext = createContext({
  state: {} as Partial<GlobalStateInterface>,
  dispatch: {} as Dispatch<ActionType>,
});

const GlobalStateProvider = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value?: Partial<GlobalStateInterface>;
}) => {
    const newState : Partial<GlobalStateInterface> = {...defaultState, ...value}
    const [state, dispatch] = useReducer(AppReducer,defaultState);
    return (
        <GlobalStateContext.Provider value={{ state: newState, dispatch }}>
        {children}
        </GlobalStateContext.Provider>
    );
};

const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateContext");
  }
  return context
};

export { GlobalStateProvider, useGlobalState, defaultState };