import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  createStyles,
  Image,
  Container,
  Title,
  Button,
  Group,
  Text,
  List,
  ThemeIcon,
  Overlay,
  Anchor,
  Grid,
} from '@mantine/core';
import { Check } from 'tabler-icons-react';
import image from './illustration-v1.png';
import InfiniteDataProvider from '../../../providers/InfiniteItemProvider'
import { configs as infiniteItemConfigs } from '../../../providers/InfiniteItemProvider'
import { FixedItemGrid } from '../../../components/NFT/FixedItemGrid';

const useStyles = createStyles((theme) => ({
      hero: {
        position: 'relative',
        backgroundImage:
          'url(https://unsplash.com/photos/N_SPFNaITCE/download?ixid=MnwxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjUzMzU2NDQ5&force=true&w=1920&q=80)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
    
      heroContainer: {
        height: 700,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingTop: theme.spacing.xl * 5,
        paddingBottom: theme.spacing.xl * 5,
        zIndex: 1,
        position: 'relative',
    
        [theme.fn.smallerThan('sm')]: {
          height: 500,
          paddingBottom: theme.spacing.xl * 3,
        },
      },
    
      heroTitle: {
        color: theme.white,
        fontSize: 60,
        fontWeight: 900,
        lineHeight: 1.1,
    
        [theme.fn.smallerThan('sm')]: {
          fontSize: 40,
          lineHeight: 1.2,
        },
    
        [theme.fn.smallerThan('xs')]: {
          fontSize: 28,
          lineHeight: 1.3,
        },
      },
    
      heroDescription: {
        color: theme.white,
        maxWidth: 750,
    
        [theme.fn.smallerThan('sm')]: {
          maxWidth: '100%',
          fontSize: theme.fontSizes.sm,
        },
      },
    
      heroControl: {
        marginTop: theme.spacing.xl * 1.5,
    
        [theme.fn.smallerThan('sm')]: {
          width: '100%',
        },
      },



    root: {
        backgroundColor: '#11284b',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage:
          'linear-gradient(180deg, rgba(255, 255, 255, 0.7  ) 0%, #ffffff 70%), url(https://images.unsplash.com/photo-1600377927594-ceae8f8981a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80)',
        paddingTop: theme.spacing.xl * 3,
        paddingBottom: theme.spacing.xl * 3,
    },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing.xl * 3,
    paddingBottom: theme.spacing.xl * 3,
  },

  content: {
    maxWidth: 480,
    marginRight: theme.spacing.xl * 3,

    [theme.fn.smallerThan('md')]: {
      maxWidth: '100%',
      marginRight: 0,
    },
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 44,
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan('xs')]: {
      fontSize: 28,
    },
  },

  control: {
    [theme.fn.smallerThan('xs')]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,

    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  highlight: {
    position: 'relative',
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.fn.rgba(theme.colors[theme.primaryColor][6], 0.55)
        : theme.colors[theme.primaryColor][2],
    borderRadius: theme.radius.sm,
    padding: '4px 12px',
  },

  primaryColor: {
      color: theme.colorScheme === 'dark'
      ? theme.colors[theme.primaryColor][6]
      : theme.colors[theme.primaryColor][2],
  }
}));

export function Home() {
  const { classes } = useStyles();
  return (
      <>
          <div className={classes.hero}>
              <Overlay
                  gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
                  opacity={1}
                  zIndex={0}
              />
              <Container className={classes.heroContainer}>
                  <Title className={classes.heroTitle}>Add Real-World Value to NFTs</Title>
                  <Text className={classes.heroDescription} size="xl" mt="xl">
                      NFT Backers is the premier <strong>NFT 2.0</strong> solution on WAX. We bring real-world backing and price stability to NFTs.
                      And we're the ONLY place you'll find in-real-life silver bullion (<Anchor size="xl" className={classes.primaryColor} href="https://ld2coin.io/" target="_blank">LD2</Anchor>)
                      attached to digital assets. Value, for real.
                  </Text>

                  <Group>
                      <Button variant="filled" size="xl" radius="xl" className={classes.heroControl}
                          component={Link}
                          to='/explorer/backers'
                      >
                          Explore
                      </Button>
                      <Button variant="default" size="xl" radius="xl" className={classes.heroControl}
                          component='a'
                          href='#home-more'
                      >
                          Learn More
                      </Button>

                  </Group>

              </Container>
          </div>

          <div className={classes.root}>
              <a id='home-more' />
              <Container>
                  <div className={classes.inner}>
                      <div className={classes.content}>
                          <Title className={classes.title}>
                              Back Atomic NFTs<br />with <span className={classes.highlight}>anything</span>!
                          </Title>
                          <Text size="sm" mt="md">
                              We're breaking through the limits of Atomic Assets! While their "backed tokens" feature is novel,
                              they only back with WAX. NFT Backers allows NFT creators to set their collections apart with
                              real-world backing for their NFTs. Beginning with <NavLink to="/explorer/collection/theld2coinio">LD2 Silver (IRL!)</NavLink>, but ultimately NFT Backers can
                              back NFTs with any real-world asset utilizing <a href="https://ld2coin.io/">the LD2 Protocol</a>.</Text>

                          <List
                              mt={30}
                              spacing="sm"
                              size="sm"
                              icon={
                                  <ThemeIcon size={20} radius="xl">
                                      <Check size={12} />
                                  </ThemeIcon>
                              }
                          >
                              <List.Item>
                                  <b>Real-Life Silver</b> – <a href="https://ld2coin.io">LD2</a> provides REAL SILVER backing on the WAX blockchain.
                                  Add real-world value to your NFTs to preserve their value.
                              </List.Item>
                              <List.Item>
                                  <b>Contests &amp; Staking</b> – Back your collection's NFTs for contest winners, or use NFT Backer to distribute staking rewards.
                              </List.Item>
                              <List.Item>
                                  <b>Value Transfers With the NFTs</b> – Once backed, the attached value transfers with the asset from owner to owner.
                              </List.Item>
                              <List.Item>
                                  <b>Burn to Extract</b> – To receive all the backed tokens, simply burn the backed NFT. To ensure delivery, we recommend burning on our site.
                              </List.Item>
                          </List>

                          <Group mt={30}>
                              <Button radius="xl" size="md" className={classes.control} component={Link} to="/explorer/backers">
                                  Explore
                              </Button>
                              <Button variant="default" radius="xl" size="md" className={classes.control} component='a' href="https://docs.google.com/forms/d/e/1FAIpQLSeGAeNZ8-5VvmLMYoLTJGwOkY3Z7Hod60lY6pOIRjhJWApThg/viewform?usp=sf_link">
                                  NFT Creators
                              </Button>
                          </Group>
                      </div>
                      <Image src={image} className={classes.image} />
                  </div>
              </Container>
          </div>

          <Container py="xl">
              <Grid py="md">
                  {/* <Grid.Col xs={12}>{accordianCard}</Grid.Col> */}
                  <Grid.Col xs={12}>
                      <InfiniteDataProvider withSearch={false} title="Featured Backers" pageSize={20} config={infiniteItemConfigs.backer} additionalQueryParams={{ }} component={FixedItemGrid}></InfiniteDataProvider>
                  </Grid.Col>
              </Grid>
          </Container>



      </>
  );
}