import { Group, useMantineColorScheme,  } from '@mantine/core';
import { Sun, MoonStars } from 'tabler-icons-react';
import { Logo } from './_logo';
import Blockchains from '../Blockchains/Blockchains';

export function Brand() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <Group position="apart">
      <Logo colorScheme={colorScheme} />
      {/* <ActionIcon variant="default" onClick={() => toggleColorScheme()} size={30}>
          {colorScheme === 'dark' ? <Sun size={16} /> : <MoonStars size={16} />}
      </ActionIcon> */}
      <Blockchains></Blockchains>
    </Group>
  );
}