import { Avatar, Container, Group, Select, Space, Text, Title } from "@mantine/core"
import { forwardRef, useState } from "react";
import { TabbedContent } from "../../../../../components/TabbedContent"

import { tokens, IToken } from "../../../../../config/tokens"
import Crowdsale from "../../../Crowdsale/Crowdsale";
import _ from 'lodash'
import { Database, DatabaseImport } from "tabler-icons-react";
import { Balances } from "../Balances/Balances";

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    image: string;
    label: string;
    description: string;
}

export default function ChooseBacking() {

    const [token, setToken] = useState<IToken>(tokens[0])
    const [activeTab, setActiveTab] = useState(0);

    const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
        ({ image, label, description, ...others }: ItemProps, ref) => (
          <div ref={ref} {...others}>
            <Group noWrap>
              <Avatar src={image} />
              <div>
                <Text size="sm" weight={700}>{label}</Text>
                <Text size="xs" color="dimmed">
                    {description}
                </Text>
              </div>
            </Group>
          </div>
        )
      );


    const tokenOptions = tokens.map((t) => {
        return {
            image: t.image,
            label: t.name,
            description: t.description,
            value: t.symbol
        }
    })

    const message = (
        <Container>
            <Title order={3}>What to Expect</Title>
            <Text>{token.backingDesc}</Text>
        </Container>
    )

    const tabItems = [
        { 
            label: 'Use Existing Balance',
            component: Text,
            props: { showtokens: [ token.symbol ], message: message },
            icon: Database,
            visible: true
        },        
        { 
            label: `Get ${token.symbol} Tokens`,
            component: Crowdsale,
            props: { symbol: token.symbol }, 
            icon: DatabaseImport,
            visible: true
        },        
    ]

    return (
        <Container className="pt-5">

            <Select
                size='lg'
                itemComponent={SelectItem}
                data={tokenOptions}
                maxDropdownHeight={400}
                value={token?.symbol}
                onChange={ (symbol)=> { setToken( _.find(tokens, (t)=>t.symbol == symbol) ) } }
                py='xl'
            />

            <Balances showtokens={[token.symbol]} message={message} />
            <Space py='lg' />
            <TabbedContent items={tabItems} onChange={() => { window.scrollTo(0,0) }} />
        </Container>
    )
}