import { useState, useEffect, useContext, useCallback } from 'react';
import { UALContext } from 'ual-reactjs-renderer';


import { Center, Loader, Space, Container, LoadingOverlay, Grid, Card, Image, Text, Badge, Button, Group, useMantineTheme, ListItem, Input, Title, Select } from '@mantine/core';
import { Api, InfoCircle, ListCheck, Plus, Search } from 'tabler-icons-react';

import { rpc } from '../..';
import { useNavigate } from 'react-router-dom';
import { IAsset, ICollection, ITemplate } from 'atomicassets/build/API/Explorer/Objects';

import InfiniteDataProvider, { configs, IList } from '../../providers/InfiniteItemProvider'

import { useForm } from '@mantine/form';
import { FixedItemGrid } from '../../components/NFT/FixedItemGrid';

export function Backers() {
  const theme = useMantineTheme();

  // Get the config
  const config = configs.backer

  return (
    <Container px='xl' py='xl'>
      <Title order={1} py='xl'>NFT Backers</Title>
      <InfiniteDataProvider config={config} pageSize={20}  component={FixedItemGrid} />
    </Container>
  );
}