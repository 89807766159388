import React, { useState } from 'react';

import { Tabs, Tab, createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  body: {
    paddingTop: 20,
  },

  tabControl: {
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2]
    }`,
    backgroundColor: `${
      theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white
    } !important`,

    '@media (max-width: 755px)': {
      fontSize: theme.fontSizes.xs,
    },
  },

  tabActive: {
    backgroundColor: `${theme.colors.blue[theme.colorScheme === 'dark' ? 8 : 6]} !important`,
    color: `${theme.white} !important`,
  },
}));

export interface ITabItem {
    label: string;
    icon: any;
    content?: any;
    component?: any;
    props?: any;
    visible: boolean;
}

interface ITabsParameters {
    items: Array<ITabItem>,
    onChange?: any,
    initialTab?: number,
}

export function TabbedContent({items, onChange, initialTab}:ITabsParameters) {

  const iconStyles = { width: 16, height: 16 };
  const { classes } = useStyles();
  const [activeTab, setActiveTab] = useState(initialTab ?? 0);

  if ( ! items || items.filter((i) => i.visible).length == 0 ) {
    return <></>
  }

  const onChangeTab = (i) => {
    if ( onChange ) { onChange() }
    setActiveTab(i)
  }

  return (
    <Tabs active={activeTab} onTabChange={onChangeTab} grow variant="pills" classNames={classes}>
      {
        items.filter((i) => i.visible).map((item,i)=>{
          const Icon = item.icon
          if ( item.component ) {
            const Component = item.component
            const props = item.props ?? {}
            return (
              <Tab key={'tabbed-content-'+item.label} label={item.label} icon={<Icon style={iconStyles} />}>
                { i == activeTab ? <Component {...props} /> : <></> }
              </Tab>
            )  
          }
          else {
            return (
              <Tab key={'tabbed-content-'+item.label} label={item.label} icon={<Icon style={iconStyles} />}>
                { i == activeTab ? item.content : <></> }
              </Tab>
            )  
          }
        })
      }
    </Tabs>
  );
}