import { Space, Grid, Chip, Card, Image, Text, Badge, Button, Group, useMantineTheme, Anchor } from '@mantine/core';
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CardImage } from './CardImage';

export interface IGenericCardProps {
  item: any;
  buttons?: any;
  options?: any;
  path: string;
}

export function GenericCard({item, buttons, options, path} : IGenericCardProps) {
    const theme = useMantineTheme();
    const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];
  
    if ( ! buttons ) { buttons = [] }
    if ( ! options ) { options = {} }
  
    const image =  (
        <CardImage
          src={item.img}
          alt={item.name}
          onClick={()=>{}} />
      )
    
    return (
      <Card radius="lg" shadow={options.shadow} p="lg">
  
        <Group position='center'  style={{ marginBottom: theme.spacing.md, marginTop: theme.spacing.sm }}>
          { options.nolink ?
              image
              :   <Anchor component={Link} to={path}>
                    {image}
                  </Anchor>
          }
        </Group>
  
        <Group position="apart" style={{ marginBottom: theme.spacing.md, marginTop: theme.spacing.sm }}>
          <Text weight={500}>
            {item.name}
          </Text>
        </Group>
  
        {
          buttons.map((b,i) => {
            return (
              <Button key={i} leftIcon={b.icon} variant="light" color="blue" fullWidth style={{ marginTop: 14 }} onClick={b.onClick}>
                {b.label}
              </Button>
            )
          })
        }
      </Card>    
  
    )
  }