// Atomic Assets APIs
import { RpcApi } from 'atomicassets';
import AAMarketPost, { IStatsCollectionParams, IStatsTemplateParams } from './AAMarketPost';
import { ExplorerApi } from 'atomicassets';
// import { Client as ElasticClient } from '@elastic/elasticsearch'
import ElasticClient from 'elasticsearch-browser'
import esb from 'elastic-builder'

import chains from '../components/Blockchains/assets/blockchains.json'
import { find, slice } from 'lodash'

import { getLowerBound256, getUpperBound256 } from '../lib/EOSHelper';

import { AccountApiParams, AssetsApiParams, CollectionApiParams, TemplateApiParams } from "atomicassets/build/API/Explorer/Params";
import { SaleApiParams } from 'atomicmarket/build/API/Explorer/Params';
import { DataOptions } from 'atomicmarket/build/API/Explorer';
import _ from 'lodash'
import { rpc } from '../index'
import { IDrop } from '../components/NFT/interfaces/DropInterfaces';
import { IToken } from '../config/tokens';
import { Asset } from '@greymass/eosio';
import { ITemplate } from 'atomicassets/build/API/Explorer/Objects';
import { tokens } from '../config/tokens';

const chain = find(chains, {key: process.env.REACT_APP_CHAIN })
const aaapiBaseUrl = chain.aaEndpoint ?? ''
const elasticUrl = chain.elasticApi ?? ''

// const aaapiBaseUrl = process.env.REACT_APP_AA_API ?? ''
// const elasticUrl = process.env.REACT_APP_ELASTIC_API ?? ''


const globalAaapi = new ExplorerApi(aaapiBaseUrl, "atomicassets", {});
const globalAmapi = new AAMarketPost(aaapiBaseUrl, "atomicmarket", {});
const globalElasticApi = new ElasticClient.Client({ host: elasticUrl })

export interface IExtendedSymbol {
    symbol: string;
    contract: string;
}

export interface INFTBackerConfig {
    supported_tokens: IExtendedSymbol[];
    blender_contracts: string[];
}

export interface IBalances {
    owner: string;
    quantities: string[];
}

export class AAMiddleware {

    aaapiBaseUrl = chain.aaEndpoint ?? ''
    elasticUrl = chain.elasticApi ?? ''
    
    // aaapiBaseUrl = process.env.REACT_APP_AA_API ?? ''
    // elasticUrl = process.env.REACT_APP_ELASTIC_API ?? ''

    aaapi = globalAaapi
    amapi = globalAmapi
    elasticApi = globalElasticApi

    backedCollectionsFromContract = [] as any
    backedCollections = [] as any

    getCollections = (options : CollectionApiParams = {} ) => this.aaapi.getCollections(options)
    getBackedCollections = async (options : CollectionApiParams = {}, page: number = 1, limit: number = 200 ) => {
        if ( page > 1 ) { return [] }
        if ( this.backedCollections.length ) { return this.backedCollections }

        const collections = await this.getBackedCollectionsFromContract(limit)
        this.backedCollections = await this.aaapi.getCollections({
            ...options,
            collection_whitelist: collections.map((c)=> c.collection_name).join(',')
        })
        const balances = {}
        for (const col of this.backedCollections) {
            const bal = await this.getLD2Balance( col.author )
            const balVal = bal ? bal.split(' ')[0] : "0"
            balances[col.collection_name] = parseFloat(balVal)
        }
        this.backedCollections = this.backedCollections.sort( (a,b) => {
            if ( balances[a.collection_name] < balances[b.collection_name] ) { return 1 }
            if ( balances[a.collection_name] > balances[b.collection_name] ) { return -1 }
            return 0
        })
        return this.backedCollections
    }
    getBackedCollectionsFromContract = async (limit: number = 200) => {
        if ( this.backedCollectionsFromContract.length ) { return this.backedCollectionsFromContract }
        let request = {
            json: true,               // Get the response as json
            code: process.env.REACT_APP_NFTBACKER_CONTRACT,     // Contract that we target
            scope: process.env.REACT_APP_NFTBACKER_CONTRACT,    // Account that owns the data
            table: 'collections',     // Table name
            limit: limit,               // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
        };

        this.backedCollectionsFromContract = await this.getAllTableRows(request)

        // const res = await rpc.get_table_rows(request)
        // this.backedCollectionsFromContract = res.rows
        return this.backedCollectionsFromContract
    }

    getBackedCollection = async (name) => {
        if ( this.backedCollectionsFromContract.length ) { return this.backedCollectionsFromContract }
        let request = {
            json: true,               // Get the response as json
            code: process.env.REACT_APP_NFTBACKER_CONTRACT,     // Contract that we target
            scope: process.env.REACT_APP_NFTBACKER_CONTRACT,    // Account that owns the data
            table: 'collections',     // Table name
            limit: 1,               // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
            lower_bound: name,
            upper_bound: name,
        };    

        return await this.getOneTableRow(request)
        // const res = await rpc.get_table_rows(request)
        // if ( res.rows && res.rows.length ) { return res.rows[0] }
    }


    hasBackedAssets = async (collection:string) => {
        let request = {
            json: true,               // Get the response as json
            code: process.env.REACT_APP_NFTBACKER_CONTRACT,     // Contract that we target
            scope: collection,    // Account that owns the data
            table: 'assets',     // Table name
            limit: 1,                // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
        };

        return !! ( await this.getOneTableRow(request))
        // const res = await rpc.get_table_rows(request)
        // return res.rows.length > 0 ? true : false
    }

    getBalances = async (name : string) => {
        let request = {
            json: true,               // Get the response as json
            code: process.env.REACT_APP_NFTBACKER_CONTRACT,     // Contract that we target
            scope: process.env.REACT_APP_NFTBACKER_CONTRACT,    // Account that owns the data
            table: 'balances',     // Table name
            limit: 1,               // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
            lower_bound: name,
            upper_bound: name,
        };
        return (await this.getOneTableRow(request))?.quantities as IBalances[] ?? [] as IBalances[]
        // const res = await rpc.get_table_rows(request)
        // return res.rows.length ? res.rows[0].quantities as IBalances[] : [] as IBalances[]
    }

    getAllBalances = async () => {

        let request = {
            json: true,               // Get the response as json
            code: process.env.REACT_APP_NFTBACKER_CONTRACT,     // Contract that we target
            scope: process.env.REACT_APP_NFTBACKER_CONTRACT,    // Account that owns the data
            table: 'balances',     // Table name
            limit: 1000,               // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
        };
        
        const rows = await this.getAllTableRows(request) as IBalances[]
        return rows

        // const res = await rpc.get_table_rows(request)
        // return res.rows.length ? res.rows[0].quantities as IBalances[] : [] as IBalances[]
    }

    getLD2Balance = async (owner : string) => this.getTokenBalance(owner, 'XLDZ', 'theld2coinio')

    getTokenBalance = async (owner:string, symbol:string, contract:string) => {
        let request = {
            json: true,               // Get the response as json
            code: contract,     // Contract that we target
            scope: owner,    // Account that owns the data
            table: 'accounts',     // Table name
            limit: 1,               // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
        };

        const rows = await this.getAllTableRows(request)
        if ( rows && rows.length ) { return (_.find( rows, b => b.balance.endsWith(symbol) )).balance }
        else { return }

        // const res = await rpc.get_table_rows(request)
        // if ( res.rows && res.rows.length ) { return (_.find( res.rows, b => b.balance.endsWith(symbol) )).balance }
        // else { return }
    }

    getAvailableBalances = async (tokens:IToken[], owner) => {
        const balances = [] as any
        if ( ! owner ) { return balances }
        const deposited = await this.getBalances(owner)
        for ( const token of tokens ) {
            const tokenBalance = await this.getTokenBalance(owner, token.symbol, token.contract)
            const walletBalance = tokenBalance ?? token.tokenTemplate
            const depositedBalance = _.find(deposited,d=>d.endsWith(token.symbol)) ?? token.tokenTemplate
            const walletAsset = Asset.from(walletBalance)
            const depositedAsset = Asset.from(depositedBalance)
            const totalAsset = Asset.from(token.tokenTemplate)
            totalAsset.value = walletAsset.value + depositedAsset.value
            const balance = {
                wallet: walletAsset,
                deposited: depositedAsset,
                total: totalAsset,
            }
            balances.push(balance)
        }
        return balances
    }

    getCollectionTheme = async (name: string) => {
        let request = {
            json: true,
            code: "neftyblocksa",
            scope: "neftyblocksa",
            table: "colthemedata",
            lower_bound: name,
            upper_bound: name,
            index_position: 1,
            key_type: "",
            limit: 1,
            reverse: false,
            show_payer: false
        }

        const row = await this.getOneTableRow(request) as any
        if ( row ) {
            const data = JSON.parse(row.theme_data)
            return data
        }
        else {
            return {}
        }

        // const res = await rpc.get_table_rows(request) as any
        // if ( res.rows.length ) {
        //     const data = JSON.parse(res.rows[0].theme_data)
        //     return data
        // }
        // else {
        //     return {}
        // }
    }

    getCollection = (name : string) => this.aaapi.getCollection(name)
    countCollections = (options: CollectionApiParams = {}) => this.aaapi.countCollections(options)
    getCollectionStats = (name: string) => this.aaapi.getCollectionStats(name)
    getCollectionLogs = (name: string, page: number = 1, limit: number = 100, order: string = 'desc') => this.aaapi.getCollectionLogs(name, page, limit, order)
    getCollectionMarketStats = (options: IStatsCollectionParams, page: number = 1, limit: number = 100 ) => this.amapi.getCollectionStats(options, page, limit).then((o) => o.results)

    collectionSearch = async (options:any, page: number = 1, limit: number = 100) => {
        return this.indexSearch(chain.elasticIndexes.collections, ["name^5","collection_name","description^2"], options, page, limit)
    }

    getTemplates = (options : TemplateApiParams ) => this.aaapi.getTemplates(options)

    getTemplate = (collection : string, id : string) => this.aaapi.getTemplate(collection, id)

    getTemplateById = async (id : string) => {
        const res = await this.indexSearch( chain.elasticIndexes.templates, ["id"], {template_id:id}, 1, 1 )

        const t = res.length ? res[0] : undefined
        if ( ! t ) { return t }

        return await this.aaapi.getTemplate(t.collection_name, id)
    }

    getTemplateStats = (collection: string, id: string) => this.aaapi.getTemplateStats(collection, id)
    getTemplateLogs = (collection: string, id: string, page: number = 1, limit: number = 100, order: string = 'desc') => this.aaapi.getTemplateLogs(collection, id, page, limit, order)
    getTemplateMarketStats = (options: IStatsTemplateParams, page: number = 1, limit: number = 100 ) => this.amapi.getTemplateStats(options, page, limit).then((o)=> o.results)

    countTemplates = async (collection:string) => {
        return this.indexCount(chain.elasticIndexes.templates,`collection_name:${collection}`)
    } 

    templateSearch = async (options:any, page: number = 1, limit: number = 100) => {
        return this.indexSearch(chain.elasticIndexes.templates, ["name^5","description^2"], options, page, limit)
    }

    getBackedTemplates = async (options:any, page: number = 1, limit: number = 100) => {
        const schemas = await this.getBackedSchemas(options.collection_name);
        const templates = await this.indexSearch(chain.elasticIndexes.templates, ["name^5","description^2"], page, limit)
        return templates.filter((t) => schemas.includes(t.schema_name) )
    }

    countBackedTemplates = async (collection:string) => {
        return this.indexCount(chain.elasticIndexes.templates,`collection_name:${collection} `)
    }

    // getBackedSchemas = async (options:any, page: number = 1, limit: number = 100) => {
    //     const schemas = await this.indexSearch(chain.elasticIndexes.schemas, ["name"], page, limit)
    //     return schemas.filter((s) => !! _(s.format,{name: 'backedby', type: 'string'}) )
    // }

    countSchemas = async (collection:string) => {
        return this.indexCount(chain.elasticIndexes.schemas,`collection_name:${collection}`)
    } 

    getBackedTemplatesAA = async (options:any, page: number = 1, limit: number = 100) => {
        const backedSchemas = await this.getBackedSchemas({collection_name:options.collection_name})
        let templates : ITemplate[] = []
        for ( const schema of backedSchemas ) {
            const rows = await this.aaapi.getTemplates({...options, schema_name: schema.schema_name})
            templates = [...templates, ...rows.filter( r => r.immutable_data.backedby )]
        }
        return templates
    }

    getBackedSchemas = async (options:any, page: number = 1, limit: number = 100) => {
        const schemas = await this.getSchemas(options, page, limit)
        return schemas.filter((s) => !! _.find(s.format,{name: 'backedby', type: 'string'}) )
    }

    getSchemas = async (options:any, page: number = 1, limit: number = 100) => {
        return this.aaapi.getSchemas(options, page, limit)
    }

    getBackedAsset = async (collection:string, asset_id: string) => {
        if ( ! collection || ! asset_id ) { return }

        let request = {
            json: true,               // Get the response as json
            code: process.env.REACT_APP_NFTBACKER_CONTRACT,     // Contract that we target
            scope: collection,                // Account that owns the data (will provide below)
            table: 'assets',          // Table name
            limit: 1,               // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
            lower_bound: asset_id,
            upper_bound: asset_id,
        };


        return await this.getOneTableRow(request)

        // const res = await rpc.get_table_rows(request)

        // if ( res.rows && res.rows.length ) {
        //     return res.rows[0]
        // }
    }

    getAllBackedAssetBalances = async () => {
        let request = {
            json: true,               // Get the response as json
            code: process.env.REACT_APP_NFTBACKER_CONTRACT,     // Contract that we target
            scope: '',                // Account that owns the data (will provide below)
            table: 'assets',          // Table name
            limit: 1000,               // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
        };

        const collections = await this.getBackedCollections()

        // Initialize balances
        const balances = {}
        for ( const t of tokens ) {
            balances[t.symbol] = Asset.from(t.tokenTemplate)
        }

        for ( const collection of collections  ) {
            request.scope = collection.collection_name
            const rows = await this.getAllTableRows(request)
            for ( const row of rows ) {
                for ( const bt of row.backed_tokens ) {
                    const a = Asset.from(bt)
                    balances[a.symbol.code.toString()].value += a.value    
                }
            }
        }

        return balances
    }

    getBackedAssetMapForAccount = async (account : string) => {
        if ( ! account ) { return {} }

        let request = {
            json: true,               // Get the response as json
            code: process.env.REACT_APP_NFTBACKER_CONTRACT,     // Contract that we target
            scope: '',                // Account that owns the data (will provide below)
            table: 'assets',          // Table name
            limit: 1000,               // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
            index_position: 2,
            key_type: 'i64',
            lower_bound: account,
            upper_bound: account,
        };

        let newAccountAssetMap ={}

        const collections = await this.getBackedCollections()

        for ( let c = 0; c < collections.length; c++ ) {
            const collection = collections[c]
            request.scope = collection.collection_name

            const rows = await this.getAllTableRows(request,1000)
            // const res = await rpc.get_table_rows(request)
            for (let i = 0; i < rows.length; i++ ) {
                let asset = rows[i]
                newAccountAssetMap[asset.asset_id] = asset.backed_tokens;
            }
        }

        return newAccountAssetMap
    }

    getBackedAssets = async (options,page,limit) => {

        const { owner } = options
        if ( ! owner ) { return }
        const map = await this.getBackedAssetMapForAccount(owner)

        // const whitelist = Object.keys(map)
        // console.log(map)

        // console.log('whitelist')
        // console.log(whitelist)

        // // If no collections, then there are no assets to load
        // if ( whitelist.length == 0 ) { return [] }

        // let ids = [] as any
        // for ( const collection of whitelist ) {
        //     ids = [ ...ids, ...Object.keys(map[collection])]
        // }

        const ids = Object.keys(map)

        // If no ids, there won't be any results to find
        if ( ids.length == 0 ) {
            return []
        }

        const rows = await this.getAssets(
            {
                ...options,
                ids: ids,
                // collection_whitelist: whitelist.join(',')
            },
            page,
            limit
        )

        // Add nftbacker backed tokens
        return rows.map(r=>{
            for ( const a of map[r.asset_id] ) {
                const asset = Asset.from(a)
                const token = _.find(tokens,{symbol:asset.symbol.code.toString()})
                r.backed_tokens.push({
                    amount: a.split(' ')[0],
                    token_contract: token.contract,
                    token_precision: asset.symbol.precision,
                    token_symbol: asset.symbol.code.toString()
                })
            }
            return r
        })

        return rows
    }

    getAssets = (options : AssetsApiParams, page: number = 1, limit: number = 20 ) => this.aaapi.getAssets(options, page, limit)
    countAssets = (options : AssetsApiParams) => this.aaapi.countAssets(options)
    getAsset = async (id : string) => {
        const asset = await this.aaapi.getAsset(id)
        if ( ! asset ) { return }
        const backing = await this.getBackedAsset(asset.collection.collection_name,asset.asset_id)
        if ( ! backing ) { return asset }
        // Add nftbacker backed tokens
        for ( const b of backing.backed_tokens ) {
            const assetBacking = Asset.from(b)
            const token = _.find(tokens,{symbol:assetBacking.symbol.code.toString()})
            asset.backed_tokens.push({
                amount: b.split(' ')[0],
                token_contract: token.contract,
                token_precision: assetBacking.symbol.precision,
                token_symbol: assetBacking.symbol.code.toString()
            })
        }
        return asset
    }

    getAssetStats = (id: string) => this.aaapi.getAssetStats(id)
    getAssetLogs = (id: string, page: number = 1, limit: number = 100, order: string = 'desc') => this.aaapi.getAssetLogs(id, page, limit, order)

    getAccounts = (options : AccountApiParams ) => this.aaapi.getAccounts(options)
    getAccount = (name : string) => this.aaapi.getAccount(name)

    countSales = (options: SaleApiParams, data?: DataOptions) => this.amapi.countSales(options, data);

    indexCount = async (index:string,q:string) => {
        const body = {
            index: index,
            q: q,
        }
        return this.elasticApi.count(body).then( res => res.count );        
    }

    indexSearch = async (index:string, fields:string[], options:any, page: number = 1, limit: number = 100) => {
        let esq = esb.boolQuery()

        // The reserved options. (All others are valid for search matching)
        const reserved = ['page','limit','match','sort','order','filters']

        // Add all the required fields for the query
        for ( const key of Object.keys(options) ) {
            if ( reserved.includes(key) ) {
                continue;
            }
            esq.must( esb.termQuery(key, options[key]) )
        }

        // Add the match field as a multi-match
        if ( ! options.match || options.match == '' ) {
            esq.must( esb.matchAllQuery() )           
        }
        else {
            esq.must( esb.multiMatchQuery()
                .query(options.match)
                .fields(fields)
            )
        }

        // Add filters
        if ( options.filters ) {
            for ( const f of options.filters ) {
                if ( f.range ) {
                    for ( const term of Object.keys(f.range) ) {
                        const range = esb.rangeQuery(term)
                        for ( const rule of Object.keys(f.range[term]) ) {
                            range[rule](f.range[term][rule])
                        }
                        esq.filter( range )
                    }
                }
                if ( f.exists ) {
                    for ( const term of f.exists ) {
                        esq.filter( esb.existsQuery(term) )
                    }
                }
                if ( f.existsAny ) {
                    const existsAnyQuery = new esb.BoolQuery()
                    for ( const term of f.existsAny ) {
                        existsAnyQuery.should( esb.existsQuery(term) )
                    }
                    esq.filter( existsAnyQuery )
                }
            }
        }

        const body = {
            index: index,
            size: limit,
            body: { query: esq } 
        } as any

        // Add sort and order
        // Both sort and order mean single field sort
        if ( options.sort && options.order) {
            let order = {}
            order[options.sort] = options.order
            // Add our single field sort, plus a required score
            body.sort = [ order, '_score' ]      
        }
        // Just sort means a preconfigured sort
        else if ( options.sort ) {
            body.sort = options.sort
        }

        const from = (page-1)*limit
        if ( from > 0 ) {
            body.from = from
        }   

        return this.elasticApi.search(body).then( res => res.hits.hits.map(h => h._source ) );

    }

    hasDrops = async (collection : string) => {
        const drops = await this.getNeftyDrops({collection_name:collection},1,1)
        return drops.length > 0
    }

    getAtomicDrops = async (options : any, page: number = 1, limit: number = 100 ) => {
        // We handle all in one page
        if ( page > 1 ) { return [] }
        return this.getDrops(options.collection_name, 'atomicdropsx', limit)
    }
    getNeftyDrops = async (options : any, page: number = 1, limit: number = 100 ) => {
        // We handle all in one page
        if ( page > 1 ) { return [] }
        return this.getDrops(options.collection_name, 'neftyblocksd', limit)
    }
   
    getDrops = async (collection : string, contract : string, limit: number = 100 ) => {

        if ( ! collection ) { return [] }

        let request = {
            json: true,               // Get the response as json
            code: contract,     // Contract that we target
            scope: contract,    // Account that owns the data (will provide below)
            table: 'drops',           // Table name
            limit: 1000,              // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
            index_position: 2,
            key_type: 'sha256',
            lower_bound: getLowerBound256(collection),
            upper_bound: getUpperBound256(collection),
        };

        const allRows = this.getAllTableRows(request,1000)

        // const res = await rpc.get_table_rows(request)

        // One day in the past
        const startDropCutoff = Math.floor( ( (new Date()).getTime() - (24*60*60) ) / 1000 )
        // One day in the future
        const endDropCutoff = Math.floor( ( (new Date()).getTime() - (24*60*60) ) / 1000 )

        const rows = _.filter(allRows, (d) => (
            // Filter drops by time frame
            ( d.end_time == 0 || d.end_time >= endDropCutoff )
            && ( d.start_time <= startDropCutoff )
            // Filter by completed drops
            && ( d.current_claimed < d.max_claimable )
        ));

        const sorted = _.orderBy(rows,['start_time'],['desc'])

        if ( sorted.length > limit ) {
            return sorted.slice(0,limit)
        }
        else {
            return sorted
        }
    }

    getAtomicDrop = async (id : string) => {
        return this.getDrop(id, 'atomicdropsx')
    }

    getNeftyDrop = async (id : string) => {
        return this.getDrop(id, 'neftyblocksd')
    }

    getDrop = async (id : string, contract : string ) => {

        if ( ! id ) { return {} }

        let request = {
            json: true,               // Get the response as json
            code: contract,     // Contract that we target
            scope: contract,    // Account that owns the data (will provide below)
            table: 'drops',           // Table name
            limit: 1,              // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
            lower_bound: id,
            upper_bound: id,
        };

        return await this.getOneTableRow(request)

        // const res = await rpc.get_table_rows(request)

        // if (res.rows.length) { return res.rows[0] }
        // else { return }
    }    

    getNotifyAccounts = async (collection : string ) => {

        if ( ! collection ) { return {} }

        let request = {
            json: true,               // Get the response as json
            code: 'atomicassets',     // Contract that we target
            scope: 'atomicassets',    // Account that owns the data (will provide below)
            table: 'collections',           // Table name
            limit: 1,              // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
            lower_bound: collection,
            upper_bound: collection,
        };

        const row = await this.getOneTableRow(request)
        if (row) { return row.notify_accounts }
        else { return [] }
 
    //     const res = await rpc.get_table_rows(request)

    //     if (res.rows.length) { return res.rows[0].notify_accounts }
    //     else { return [] }
    }

    isCollectionConfigured = async (collection : string ) => {
        return ( await this.getNotifyAccounts(collection) ).includes(process.env.REACT_APP_NFTBACKER_CONTRACT)
    }

    getColFilters = async (name:string) => {
        if ( ! name ) { return []}
        let request = {
            json: true,              // Get the response as json
            code: 'atomhubtools',    // Contract that we target
            scope: name,    // Account that owns the data
            table: 'colfilters',     // Table name
            limit: '1000',             // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,          // Optional: Get reversed data
            show_payer: false,       // Optional: Show ram payer
            lower_bound: null,
        };

        return await this.getAllTableRows(request,1000)

        // const res = await rpc.get_table_rows(request) as any
        // return res.rows
    }

    getBackedConfig = async () => {
        let request = {
            json: true,              // Get the response as json
            code: 'thenftbacker',    // Contract that we target
            scope: 'thenftbacker',    // Account that owns the data
            table: 'config',     // Table name
            limit: '1',             // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,          // Optional: Get reversed data
            show_payer: false,       // Optional: Show ram payer
            lower_bound: null,
        };

        return await this.getOneTableRow(request) as INFTBackerConfig

        // const res = await rpc.get_table_rows(request) as any
        // return res.rows[0] as INFTBackerConfig
    }


    getAllTableRows = async (request, limit:number = 0, filter?) => {
        let rows : any[] = []
        while ( true ) {
          const res = await rpc.get_table_rows(request) as any
          if ( filter ) {
              rows = [...rows, ...res.rows.filter(filter)]
          }
          else {
            rows = [...rows, ...res.rows]
          }
          if ( limit != 0 && rows.length >= limit ) {
              rows = slice(rows, 0, limit-1)
              break
          } 
          if ( res.more ) {
            request.lower_bound = res.next_key
          }
          else {
            break;
          }  
        }
        return rows
    }
      
    getOneTableRow = async (request) => {
        const res = await rpc.get_table_rows(request) as any
        if ( res.rows.length ) {
          return res.rows[0]
        }
        else { return false }
    }
    

    getProtocolTokenData = async (token:IToken) => {
        let request = {
            json: true,               // Get the response as json
            code: token.contract,     // Contract that we target
            scope: token.contract,    // Account that owns the data (will provide below)
            table: 'data',           // Table name
            limit: 100,              // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
        };

        const rows = await this.getAllTableRows(request)
        const data = rows.find((r)=>r.on_deposit.endsWith(token.symbol))
        if ( data ) { return data as IProtocolData }
    }

    getProtocolTokenStats = async (token:IToken) => {
        let request = {
            json: true,               // Get the response as json
            code: token.contract,     // Contract that we target
            scope: token.symbol,    // Account that owns the data (will provide below)
            table: 'stat',           // Table name
            limit: 100,              // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
        };

        return (await this.getAllTableRows(request)).find(s=>s.supply.endsWith(token.symbol)) as IProtocolStat | undefined
    }

}

export interface IProtocolStat {
    supply: string
    max_supply: string
    issuer: string
}

export interface IProtocolData {
    on_deposit: string
    certified: string
    auditor: string
    depository: string
    depository_delegate: string
}
