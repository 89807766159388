import { useContext } from 'react';

import { showNotification, updateNotification } from '@mantine/notifications';
import { Flame, AlertTriangle, Check, Database } from 'tabler-icons-react';

export default async function CreateTemplate(collection,schemaName,transferable,burnable,maxSupply,data,ual) {

    const auth = [{
        actor: ual.activeUser.accountName,
        permission: ual.activeUser.requestPermission,
    }]

    const actions = [
        {
            account: 'atomicassets',
            name: 'createtempl',
            authorization: auth,
            data: {
                authorized_creator: ual.activeUser.accountName,
                collection_name: collection,
                schema_name: schemaName,
                transferable,
                burnable,
                max_supply: maxSupply,
                immutable_data: data
            }
        }
    ]

    const actionId = crypto.randomUUID()

    try {
        showNotification({
            id: `create-template-${actionId}`,
            loading: true,
            title: `Creating Template`,
            message: `Preparing to create a new template`,
            autoClose: false,
            disallowClose: true,
            icon: <Database />,
        });

        const res = await ual.activeUser.signTransaction(
            {
                actions: actions
            },
            {
                blocksBehind: 3,
                expireSeconds: 60
            }
        );
        updateNotification({
            id: `create-template-${actionId}`,
            color: 'teal',
            title: `Success!`,
            message: `Created template`,
            icon: <Check />,
            autoClose: 10000,
        });
        return res
    } catch (e : any ) {
        console.log(e);

        const msg = e.message.replace('assertion failure with message:','');

        updateNotification({
            id: `create-template-${actionId}`,
            color: 'red',
            title: `Unable to create template`,
            message: msg,
            icon: <AlertTriangle />,
            autoClose: false,
        });
    }

    return false
}