import { Box, Container, Title, useMantineTheme } from '@mantine/core';
import { AlertCircle } from 'tabler-icons-react';

export function NotFound() {
  const theme = useMantineTheme();
  return (
    <Container>
      <Box
        sx={(theme) => ({
          display: 'block',
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
          color: theme.colorScheme === 'dark' ? theme.colors.blue[0] : theme.colors.dark[7],
          textAlign: 'center',
          textDecoration: 'none',
          padding: theme.spacing.xl,
          borderRadius: theme.radius.md,
        })}
      >
        <AlertCircle size={64} />
        <Title color={ theme.colorScheme === 'dark' ? theme.white : theme.black } order={1}>Not Found</Title>
        <p>We couldn't find the page you requested.</p>
      </Box>
    </Container>
  );
}