import { Space, Grid, Chip, Card, Image, Text, Badge, Button, Group, useMantineTheme, Anchor } from '@mantine/core';

import { useCallback, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IElasticTemplate } from 'nftbackers-elastic-interfaces'
import { ITemplate } from 'atomicassets/build/API/Explorer/Objects';
import { CardImage } from './CardImage';
import { IGenericCardProps } from './GenericCard';

import {AAMiddleware} from '../../../store/AAMiddleware'
const api = new AAMiddleware()

interface IAssetToMint {
  template_id: number;
  tokens_to_back: any;
  use_pool?: boolean | 0 | 1;
}

interface IAssetsToMint extends Array<IElasticTemplate>{}
interface ITemplates extends Array<ITemplate>{}

interface IDrop {
  drop_id: number;
  collection_name: string;
  assets_to_mint: IAssetsToMint;
  listing_price: string;
  settlement_symbol: string;
  price_recipient: string;
  fee_rate: number;
  auth_required: boolean | 0 | 1;
  account_limit: number;
  account_limit_cooldown: number;
  max_claimable: number;
  current_claimed: number;
  start_time: number;
  end_time: number;
  display_data?: string;
  accounts_distribution?: Array<any>;
  is_hidden?: boolean | 0 | 1;
}

interface IDropCardProps extends IGenericCardProps {
  item: IDrop;
}

export function DropCard({item, buttons, options, path} : IDropCardProps) {
    const theme = useMantineTheme();
    const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];

    const [templates,setTemplates] = useState<ITemplates>([])
  
    if ( ! buttons ) { buttons = [] }
    if ( ! options ) { options = {} }

    const toPath = path + item.drop_id

    useEffect(() => {
      const loadTemplates = async () => {
         const loadedTemplates : Array<ITemplate> = [] 
         for ( const m of item.assets_to_mint ) {
           loadedTemplates.push( await api.getTemplateById(m.template_id))
         }
         setTemplates(loadedTemplates)
      }
      loadTemplates()
    }, [])

    const images = [] as any

    for ( const t of templates ) {



      images.push(
        <CardImage
        src={t.immutable_data.img}
        alt={t.immutable_data.name}
        onClick={()=>{}} />
      )
    }

    const displayData = item.display_data ? JSON.parse(item.display_data) : {}

    return (
      <Card radius="lg" shadow={options.shadow} p="lg">
        <Card.Section>
            <Group position="center">
                { options.nolink ?
                    images.shift()
                    :   <Anchor component='a' href={toPath}>
                          {images.shift()}
                        </Anchor>
                }
            </Group>
        </Card.Section>

        <Group position='apart'>
          <div>
            <Group position='left' style={{ marginBottom:0, marginTop: 0 }}>
              <Text align='left' pt='xl' pb={0} px='md' size='xs' weight={700} color={theme.colors.dark[3]}>{item.collection_name}</Text> 
            </Group>
            <Group position='left' style={{ marginBottom: theme.spacing.md, marginTop: 0 }}>
              <Text
                style={{
                  overflow: 'hidden',
                  maxWidth: '25ch',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }} 
                align='left' pt={0} px='md' size='xs' weight={700} color={theme.colors.dark[5]}>{displayData.name}</Text> 
            </Group>
          </div>
          <div>
            <Text size='xs' align='right'>
              Claimed
            </Text>
            <Text size='sm' weight={700} align='right'>{item.current_claimed} / {item.max_claimable}</Text>
          </div>

        </Group>



        {
          buttons.length ?
          buttons.map((b,i) => {
            return (
              <Button key={i} leftIcon={b.icon} variant="light" color="blue" fullWidth style={{ marginTop: 14 }} onClick={b.onClick}>
                {b.label}
              </Button>
            )
          })
          : <Button component='a' href={toPath} color="blue" fullWidth>Details</Button>
        }
      </Card>    
  
    )
  }