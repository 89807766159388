import { ICollection, ITemplate } from "atomicassets/build/API/Explorer/Objects";
import { AtomicMarketApi } from "atomicmarket";

type Fetch = (input?: Request | string, init?: RequestInit) => Promise<Response>;
type ApiArgs = { fetch?: Fetch };

export interface IStatsTemplateParams {
    symbol: string;
    search?: string;
    match?: string;
    collection_name?: string;
    schema_name?: string;
    template_id: string;
    before?: number;
    after?: number;
    page?: number;
    limit?: number;
    order?: 'desc' | 'asc';
    sort?: 'volume' | 'listings';
}

export interface IStatsCollectionParams {
    symbol: string;
    search?: string;
    match?: string;
    ids?: string[];
    lower_bound?: string;
    upper_bound?: string;
    before?: number;
    after?: number;
    page?: number;
    limit?: number;
    order?: 'desc' | 'asc';
    collection_blacklist: string;
    collection_whitelist: string;
    sort?: 'volume' | 'listings';
}

export interface IStatsTemplate {
    volume: string;
    sales: string;
    template: ITemplate;
}

export interface IStatsCollection extends ICollection {
    volume: string;
    sales: string;
}

export interface ISymbol {
    token_contract: string;
    token_symbol: string;
    token_precision: number
}

export interface IStatsTemplateResults {
    symbol: ISymbol;
    results: IStatsTemplate[];
}
export interface IStatsCollectionResults {
    symbol: ISymbol;
    results: IStatsCollection[];
}

export default class AAMarketPost extends AtomicMarketApi {
    constructor(endpoint: string, namespace: string, args: ApiArgs) {
        super(endpoint, namespace, args)
    }

    async getCollectionStats(
        options : IStatsCollectionParams, page: number = 1, limit: number = 100
    ): Promise<IStatsCollectionResults> {
        return await this.fetchEndpoint('/v1/stats/collections', {page, limit, ...options});
    }    

    async getTemplateStats(
        options : IStatsTemplateParams, page: number = 1, limit: number = 100
    ): Promise<IStatsTemplateResults> {
        return await this.fetchEndpoint('/v1/stats/templates', {page, limit, ...options});
    }    

}


