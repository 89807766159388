import { create, CID, IPFSHTTPClient } from "ipfs-http-client";
import { createRef, useState } from "react";
import { create as ipfsHttpClient } from 'ipfs-http-client'
import { Badge, Button, Container, Group, Image, Loader, Stack, Text, useMantineTheme } from "@mantine/core";
import { AlertTriangle, Photo } from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";


// const ipfsClientUrl = 'http://localhost:5001/api/v0'
const ipfsClientUrl = "https://ipfs.infura.io:5001/api/v0"
const ipfs = ipfsHttpClient({url: ipfsClientUrl})

const imageConfig = { fullHeight: 300, thumbHeight: 40 }

export default function ImageUpload({setUrl,whenEmpty=undefined, defaultUrl='',details=true,allowedTypes=['image/jpg','image/png','image/gif','image/webp']}) {

    const theme = useMantineTheme();

    const lightColor = theme.colorScheme === 'dark'
      ? theme.colors.gray[3]
      : theme.colors.gray[5];    

    const [image, setImage] = useState<any>()
    const [imagePreview, setImagePreview] = useState('')
    const [loading, setLoading] = useState(false)
    const [uploaded, setUploaded] = useState(false)

    const inputRef = createRef<HTMLInputElement>()

    const allowedTypesDisplay = allowedTypes.map(t=>t.split('/')[1]).join(', ')

    const createPreview = (e) => {
        const file = e.target.files[0]
        if ( ! allowedTypes.includes(file.type) ) {
            setImage({} as any)
            showNotification({
                id: `upload-image-${file.name}-`+(new Date()).getMilliseconds(),
                loading: false,
                color: 'red',
                title: `Unsupported Image Type`,
                message: 'Supported image formats are: ' + allowedTypesDisplay,
                autoClose: true,
                disallowClose: true,
                icon: <AlertTriangle />,
            })
            return
        }
        if (e.target.value !== '') {
            setImage(e.target.files[0])
            const src = URL.createObjectURL(e.target.files[0])
            setImagePreview(src)
            uploadFile(e)
        } else {
            setImagePreview('')
        }
    }    

    const uploadFile = async (e) => {       
        setLoading(true)
        e.preventDefault()

        const file = e.target.files[0]

        try {
            const added = await ipfs.add(file)
            const url = `https://ipfs.infura.io/ipfs/${added.path}`
            setUrl(url)
            setImagePreview(url)
            setUploaded(true)
        } catch (err) {
            console.log('Error uploading the file : ', err)
        }
        setLoading(false)
    }

    const previewAndUploadButton = () => {
        if (imagePreview !== '' || defaultUrl !== '' ) {
            if (!loading) {
                return (
                    <Container>
                            <Image
                                style={{ height: '300px' }}
                                className='mb-3'
                                src={defaultUrl ?? imagePreview}
                                onClick={clickFileInput}
                            />
                            {
                                image && details ?
                                (
                                    <Group position='center'>
                                        <h5>{image?.name}</h5>
                                        <Badge>{image?.size} kb</Badge>
                                    </Group>    
                                ) : ''
                            }
                    </Container>
                )
            } else {
                return (
                    <Container>
                        <h4>Uploading Image</h4>
                        <Loader variant="dots" />
                        <h4>Please Wait ...</h4>
                    </Container>
                )
            }
        }
    }

    const clickFileInput = (e) => {
        if ( inputRef && inputRef.current ) {
            inputRef.current.click()
        }
    }

    if ( ! ipfs ) {
        return (<span>Unable to connect to IPFS.</span>)
    }

    return (
        <>
            <form onSubmit={uploadFile}>
                <input
                    ref={inputRef}
                    required
                    type='file'
                    accept='image/*'
                    onChange={(e) => createPreview(e)}
                    className='mb-3'
                    hidden
                />
                {
                    (! imagePreview && ! defaultUrl) ? 
                    <Image onClick={clickFileInput} 
                        fit="contain"
                        height={imageConfig.fullHeight}
                        withPlaceholder
                        placeholder={
                            <Stack align='center'>
                                <Photo color={lightColor} style={{ width: 70, height: 70 }} />
                                <Text align="center">{whenEmpty ?? <>Click to<br />Upload Image</>}</Text>
                            </Stack>
                        }
                        radius="lg" 
                    />
                    : <></>
                }
                {previewAndUploadButton()}
            </form>
        </>
    )
}