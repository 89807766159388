import { Accordion, Box, Button, Card, Checkbox, Collapse, Container, Grid, Group, InputWrapper, NumberInput, Skeleton, Text, TextInput, Title, useMantineTheme } from "@mantine/core";
import { useForm } from "@mantine/form";
import { CreatableSelect } from "../../../../components/controls/CreatableSelect";
import BackedByDropdown from "../../../../components/NFT/BackedByDropdown";
import { UALContext } from 'ual-reactjs-renderer';

import CreateTemplate from '../../../../lib/CreateTemplate'

import JSONPretty from 'react-json-pretty';

import { tokens, IToken } from "../../../../config/tokens";
import _ from 'lodash'
import { Api, Check, CloudUpload, FileUpload, Photo, SquareCheck } from "tabler-icons-react";
import InlineEdit from "../../../../components/controls/InlineEdit";
import InlineMultilineEdit from "../../../../components/controls/InlineMultilineEdit";
import { useContext, useEffect, useState } from "react";
import { ImageUploadManager } from "../../../../components/ImageUploadManager";
import { AAMiddleware } from "../../../../store/AAMiddleware";
import { useModals } from "@mantine/modals";
import { ITemplateExtended, TemplateCard } from "../../../../components/NFT/cards/TemplateCard";
import { SimpleTemplateCard } from "../../../../components/NFT/cards/SimpleTemplateCard";
import { PreviewTemplateCard } from "../../../../components/NFT/cards/PreviewTemplateCard";

const api = new AAMiddleware()

export default function CreateTemplateForm({schema,filters,onComplete,ignoreFields=[] as string[]}) {

    const theme = useMantineTheme();
    const modals = useModals();
    
    const ual = useContext(UALContext) as any
    const [enabledTokens,setEnabledTokens] = useState<Array<IToken>>([])

    useEffect(()=>{
        const loadEnabledTokens = async () => {
            const conf = await api.getBackedCollection(schema.collection_name)
            const newEnabledTokens = tokens.filter((t)=>!! _.find(conf.supported_tokens, (s)=> s.symbol == t.symbolCode)) as IToken[]
            setEnabledTokens(newEnabledTokens)
        }
        loadEnabledTokens()
 
    },[])

    const secondaryColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[1]
      : theme.colors.gray[7];

    const accentColor = theme.colorScheme === 'dark'
      ? theme.colors.blue[0]
      : theme.colors.blue[5];

    const lightTextColor = theme.colorScheme === 'dark'
      ? theme.colors.gray[1]
      : theme.colors.gray[7];

    const lighterTextColor = theme.colorScheme === 'dark'
      ? theme.colors.gray[3]
      : theme.colors.gray[5];    

    const types = {
        int8: 'number',
        int16: 'number',
        int32: 'number',
        int64: 'number',
        uint8: 'number',
        uint16: 'number',
        uint32: 'number',
        uint64: 'number',
        fixed8: 'number',
        fixed16: 'number',
        fixed32: 'number',
        fixed64: 'number',
        float: 'text',
        double: 'text',
        string: 'text',
        image: 'image',
        ipfs: 'text',
        bool: 'checkbox',
        byte: 'text',
    }

    const descFields = ['description','desc','about']

    const imageNames = [
        'image','image1','image2','image3','image4','img','img1','img2','img3','img4',
        'video','video1','video2','video3','video4','vid','vid1','vid2','vid3','vid4',
    ]

    const getImages = () => {
        if ( ! schema ) { return [] }
        return schema.format.filter(f=>( f.type == 'image' || imageNames.includes(f.name))).map(i=>i.name)
    }    

    const isImageField = (field) => {
        if ( ! schema ) { return false }
        const format = _.find(schema.format, (f)=>f.name == field)
        return format ? format.type == 'image' || imageNames.includes(field) : false
    }

    const initialValues = ! schema ? {} : Object.fromEntries( schema.format.map((f)=>[f.name,'']))

    const form = useForm({
        initialValues: {
            max_supply: 0,
            burnable: true,
            transferable: true,
            ...initialValues,
        } as any,
        validate: {
        //   email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
            backedby: (v) => v != '' ? null : 'Backing is required',
        },
    });

    const updateValue = (field,value) => {
        form.setFieldValue(field,value)
    }

    const hasDescription = () => {
        return Object.keys(form.values).some((k)=> descFields.includes(k))
    }

    const getDescriptionField = () => {
        for ( const field of Object.keys(form.values) ) {
            if ( descFields.includes(field) ) {
                return field
            }
        }
        return ''
    }

    const getDescription = () => {
        const field = getDescriptionField()
        return field != '' ? form.values[field] : ''
    }

    const createTextInput = (f) => {
        return (
            <InputWrapper my='lg' key={f.name} label={f.name}>
                <TextInput
                    {...form.getInputProps(f.name)}
                    size='md'
                    onChange={(e)=>{form.setFieldValue(f.name,e.target.value)}}
                />
            </InputWrapper>
        )
    }

    const createNumberInput = (f,label:undefined|string=undefined) => {
        return (
            <InputWrapper my='lg' key={f.name} label={label??f.name}>
                <NumberInput
                    {...form.getInputProps(f.name)}
                    size='md'
                />
            </InputWrapper>
        )
    }

    const createSelectInput = (f,filter) => {
        const data = filter.possible_values.map((json) => {
            const o = JSON.parse(json)
            return o.value
        })
        return (
            <CreatableSelect key={f.name} initialData={data} selectProps={{ label: f.name, size:'md', my:'lg'  }} />
        )

    }

    const createBackedByInput = (f) => {
        return (
            <BackedByDropdown my='lg' key={f.name} tokens={enabledTokens} size='md' onChange={(v)=>{ form.setFieldValue(f.name,v) }} />
        )
    }

    const createImageInput = (f) => {
        const rightSection = (
            <Photo size={16} style={{ display: 'block', opacity: 0.5 }} />
        );
        return (
            <InputWrapper my='lg' key={f.name} label={f.name}>
                <TextInput
                    {...form.getInputProps(f.name)}
                    rightSection={rightSection}
                    size='md'
                    readOnly
                />
            </InputWrapper>
        )
    }

    const createInput = (f) => {
        // Handle BackedBy Input
        if ( f.name == 'backedby' ) { return createBackedByInput(f) }

        // Handle fields with configured filters (make them dropdowns)
        const filter = _.find(filters,i=>(/*i.schema_name == schema?.schema_name && */i.attribute_name == f.name ) )
        if ( filter ) { return createSelectInput(f,filter) }

        // Handle number fields
        if ( Object.keys(types).filter((t)=>types[t] == 'number').includes(f.type) ) { return createNumberInput(f) }

        // Text Fields
        if ( Object.keys(types).filter((t)=>types[t] == 'string').includes(f.type) ) { return createTextInput(f) }

        // Image Fields
        if ( f.name == 'video' || Object.keys(types).filter((t)=>types[t] == 'image').includes(f.type) ) {
            return createImageInput(f)
        }

        // For all others, a text field will work
        return createTextInput(f)
    }

    const getDataType = (t:string) => {
        const typeMap = {
            image: 'string',
            ipfs: 'string',
        }
        return typeMap[t] ?? t
    }

    const createTemplate = () => {
        const callCreateTemplate = async () => {
            const usedFields = schema.format.filter(f=> form.values[f.name] !== undefined && form.values[f.name] != '')
            let data = [] as any
            usedFields.map(f=>data.push({key:f.name,value:[getDataType(f.type),form.values[f.name]]}))
            if ( await CreateTemplate(
                    schema.collection.collection_name,
                    schema.schema_name,
                    true, // Transferable
                    true, // Burnable
                    form.values.max_supply,
                    data,
                    ual
                ) && onComplete ) {
                onComplete()
            }
        }
        callCreateTemplate()
    }

    const confirmTemplateModal = () => modals.openConfirmModal({
        title: 'Create this Template?',
        children: (
            <>
                <PreviewTemplateCard item={{ ...form.values, immutable_data: {...form.values}, schema: schema, collection: schema.collection_name } } />
                <Text size="sm">
                    You are about to create an NFT Backer template. This action is not reversible. 
                    To ensure all assets are properly backed, each asset minted will require you to 
                    provide <strong> {form.values.backedby}</strong>, placed on deposit with NFT Backers. 
                </Text>
            </>
        ),
        labels: { confirm: 'Confirm', cancel: 'Cancel' },
        onCancel: () => console.log('Cancel'),
        onConfirm: createTemplate,
    });

    const summaryCard = (
        <Card shadow="sm" p="lg" radius="lg">
            <InlineMultilineEdit 
                value={form.values.name}
                setValue={(v) => {form.setFieldValue('name',v)}}
                activeColor={'black'}
                inactiveColor={lighterTextColor}
                options={{
                    size:'xl', 
                    placeholder:'Title',
                }}
                styles={{
                    fontSize: 34,
                    fontWeight: 700,
                }}
                showSkeleton={false}
            />
            {
                hasDescription() ? (
                    <Text my='xl' style={{color: getDescription() == '' ?lighterTextColor:'black'}} size='sm'>
                        <InlineMultilineEdit
                            value={form.values[getDescriptionField()]}
                            setValue={(v) => {form.setFieldValue(getDescriptionField(),v)}}
                            activeColor={'black'}
                            inactiveColor={lighterTextColor}
                            options={{
                                size:'md', 
                            }}
                            styles={{
                            }}
                        />
                    </Text>
                ) :
                <></>
            }
            {createNumberInput({name:'max_supply',type:'int'},'Max Supply (0 for no limit)')}
            <Text size='xs'>All NFT Backers templates must be both burnable and transferable.</Text>
        </Card>
    )

    const preview = Object.entries(form.values).reduce((a,[k,v]) => (v === undefined || v == '' ? a : (a[k]=v, a)), {})
    const images = Object.entries(form.values).reduce((a,[k,v]) => ( ! isImageField(k) ? a : (a[k]=v, a)), {})

    return (
        <Container px='xl' py='xl'>
            <Grid>
                <Grid.Col xs={4}>
                    <ImageUploadManager values={images} onUpdateImage={(name,url)=>{ form.setFieldValue(name,url) }} />
                </Grid.Col>
                <Grid.Col xs={8}>{summaryCard}</Grid.Col>
                <Grid.Col xs={12}>
                    <form onSubmit={form.onSubmit((values) => console.log(values))}>
                        <Accordion initialItem={0}>
                            <Accordion.Item label="Attributes">
                                <Box sx={{ maxWidth: 700 }} mx="auto">
                                    <Text size='sm'><em>NOTE: Any attributes left blank here will be ignored.</em></Text>
                                    {
                                        schema.format.filter((f:any)=> f.name != 'name' && !isImageField(f.name) && !ignoreFields.includes(f.name) && f.name != getDescriptionField() ).map((f)=>{
                                            return ( createInput(f) )
                                        })
                                    }
                                </Box>
                            </Accordion.Item>
                            <Accordion.Item label="Images (readonly)">
                                <Box sx={{ maxWidth: 500 }} mx="auto">
                                    {
                                        schema.format.filter((f:any)=>!ignoreFields.includes(f.name) && isImageField(f.name) ).map((f)=>{
                                            return ( createInput(f) )
                                        })
                                    }
                                </Box>
                            </Accordion.Item>
                            <Accordion.Item label="Advanced Preview">
                                <JSONPretty data={preview} />
                            </Accordion.Item>
                        </Accordion>
                        <Group my='xl' position='center'>
                            <Button size='lg' onClick={()=>{ console.log(form.validate()); confirmTemplateModal() }}>Create</Button>
                        </Group>
                    </form>
                </Grid.Col>
            </Grid>
        </Container>
    );

}

