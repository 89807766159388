
import { Image } from '@mantine/core';

import {resolveUrl} from '../../../lib/ImageHelper'

export function CardImage({src, alt, onClick}) {

    let imgSrc = '';
    let hash = '';

    const height = 300
    const params = '?tr=h-' + height

    if ( ! src ) {
        return (
            <Image 
            height={height}
            withPlaceholder 
            src={''} 
            alt={alt}
            onClick={onClick} />
        )
    }

    imgSrc = resolveUrl( src, params )

    // // IPFS with URL
    // if ( src.startsWith('http') && src.includes('ipfs') ) {
    //     const parts = src.split('ipfs/')
    //     let hash = parts.pop()
    //     imgSrc = process.env.REACT_APP_IMAGE_PROXY+ hash+params
    // }
    // // Self-hosted, just pass the url along
    // else if ( src.startsWith('http') && src.includes('ipfs') ) {
    //     imgSrc = src
    // }
    // // Hash only (ipfs)
    // else if ( src.startsWith('Qm') ) {
    //     imgSrc = process.env.REACT_APP_IMAGE_PROXY+src+params
    // }
    // // Default, just in case
    // else {
    //     imgSrc = process.env.REACT_APP_IMAGE_PROXY+src+params
    // }

    return (
    <Image 
        height={height}
        withPlaceholder 
        src={imgSrc} 
        alt={alt}
        onClick={onClick} />

    )
}