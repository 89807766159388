import { forwardRef, useState } from 'react';
import { Group, Avatar, Text, Select, MediaQuery } from '@mantine/core';

import eosLogo from './assets/eos.png';
import fioLogo from './assets/fio.svg';
import jungleLogo from './assets/jungle.png';
import protonLogo from './assets/proton.png';
import telosLogo from './assets/telos.png';
import waxLogo from './assets/wax.png';

import blockchains from './assets/blockchains.json';
import { find } from 'lodash';
import { useNavigate } from 'react-router-dom';

import './Blockchains.css'

export const logoMap = {
  // Testnets
  "0db13ab9b321c37c0ba8481cb4681c2788b622c3abfd1f12f0e5353d44ba6e72": eosLogo,
  "b20901380af44ef59c5918439a1f9a41d83669020319a80574b804a5f95cbd7e": fioLogo,
  "e70aaab8997e1dfce58fbfac80cbbb8fecec7b99cf982a9444273cbc64c41473": jungleLogo,
  "2a02a0053e5a8cf73a56ba0fda11e4d92e0238a4a2aa74fccf46d5a910746840": jungleLogo,
  "71ee83bcf52142d61019d95f9cc5427ba6a0d7ff8accd9e2088ae2abeaf3d3dd": protonLogo,
  "1eaa0824707c8c16bd25145493bf062aecddfeb56c736f6ba6397f3195f33c9f": telosLogo,
  "f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12": waxLogo,
  // Production
  "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4": waxLogo,
  "aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906": eosLogo,
};

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  image: string;
  label: string;
  description: string;
}

const urlMap = process.env.REACT_APP_URLS ? JSON.parse(process.env.REACT_APP_URLS) : {};

const enabledChains = [] as any;
const enabledChainList = process.env.REACT_APP_ENABLED_CHAINS ? process.env.REACT_APP_ENABLED_CHAINS : ''
enabledChainList.split(',').forEach((key)=> {
  let item = find(blockchains,{key:key});
  if ( item ) { item.url = urlMap[key]; enabledChains.push(item); }
});

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar src={image} />

        <div>
          <Text size="sm">{label}</Text>
        </div>
      </Group>
    </div>
  )
);

const Blockchains = (props) => {
  const { chain } = props;

  const navigate = useNavigate();

  let selectedChainUrl = window.location.protocol + '//' + window.location.host

  const onChange = (value) => {
    document.location = value;
  }
 
  const options = enabledChains.map((chain) => ({
    image: logoMap[chain.chainId],
    label: chain.name,
    value: chain.url,
    description: chain.name,
  }));

  return (
    <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
      <Select
        itemComponent={SelectItem}
        data={options}
        maxDropdownHeight={400}
        value={selectedChainUrl}
        onChange={onChange}
      />    
    </MediaQuery>
  )

}

export default Blockchains;
