import { Space } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import InfiniteScroll from "react-infinite-scroll-component";
import GridLoader from "../GridLoader";
import { AssetCard } from "./cards/AssetCard";
import { BackedAssetCard } from "./cards/BackedAssetCard";
import { CollectionCard } from "./cards/CollectionCard";
import { GenericCard } from "./cards/GenericCard";
import { TemplateCard } from "./cards/TemplateCard";
import { TemplateStatCard } from "./cards/TemplateStatCard";
import ItemGrid from "./ItemGrid";

export interface ILoadDataCallback {
    () : Promise<void>;
}

export interface IEventCallback {
    (e?:any) : void;
}

export interface IButton {
    label: string;
    onClick: IEventCallback | any;
    icon: React.ReactNode;
}

export interface IInifiteDataProvider {
    next: ILoadDataCallback;
    readonly items: any[];
    readonly dataLength: number;
    readonly hasMore: boolean;
}

export interface IInfiniteItemGridProps {
    provider: IInifiteDataProvider;
    gridCard:     typeof AssetCard 
                | typeof BackedAssetCard 
                | typeof CollectionCard 
                | typeof GenericCard 
                | typeof TemplateCard 
                | typeof TemplateStatCard;
    buttons: IButton[];
    itemBaseUri: string;
}

export function InfiniteItemGrid(props : IInfiniteItemGridProps ) {
    return (
        <InfiniteScroll
            style={{overflow: "hidden"}}
            dataLength={props.provider.dataLength} //This is important field to render the next data
            next={props.provider.next}
            hasMore={props.provider.hasMore}
            loader={<GridLoader />}
            endMessage={<></>}
        >
            <ItemGrid path={props.itemBaseUri} GridCard={props.gridCard} items={props.provider.items} buttons={props.buttons} />
            <Space h="md" />
        </InfiniteScroll>
    )
}