import { useState } from 'react';
import { Select } from '@mantine/core';

interface ICreatableSelectProps {
    initialData: string[]
    selectProps: any
}

export function CreatableSelect({initialData, selectProps} : ICreatableSelectProps) {

    const [data, setData] = useState(initialData);

    return (
        <Select
            label="Creatable Select"
            data={data}
            placeholder="Select"
            nothingFound="Nothing found"
            searchable
            creatable
            getCreateLabel={(query) => `+ Create ${query}`}
            onCreate={(query) => setData((current) => [...current, query])}
            {...selectProps}
        />
    );
}