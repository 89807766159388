import { Space, Grid, Chip, Card, Image, Text, Badge, Button, Group, useMantineTheme, Anchor } from '@mantine/core';
import { ICollection } from 'atomicassets/build/API/Explorer/Objects';
import { useCallback, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IElasticCollection } from 'nftbackers-elastic-interfaces'

import { CardImage } from './CardImage';
import { CardImageFull } from './CardImageFull';
import { IGenericCardProps } from './GenericCard';
import { AAMiddleware } from '../../../store/AAMiddleware';
import _ from "lodash"

import LinesEllipsis from 'react-lines-ellipsis'

interface ICollectionCardProps extends IGenericCardProps {
  item: ICollection | IElasticCollection;
}

const api = new AAMiddleware()

export function CollectionManageCard({item, buttons, options, path} : ICollectionCardProps) {
    const theme = useMantineTheme();

    const [backed,setBacked] = useState(false)
    const [configured,setConfigured] = useState(false)

    useEffect(()=>{
      const loadBacked = async () => {
        const backedCollection = await api.getBackedCollection(item.collection_name)
        console.log('found backedCollection')
        console.log(backedCollection)
        console.log(`backed is ${!! backedCollection}`)
        setBacked( !! backedCollection )
      }
      loadBacked()
    },[])

    useEffect(()=>{
      const checkConfigured = async () => {
        if ( ! process.env.REACT_APP_NFTBACKER_CONTRACT ) { return }
        let isConfigured = false
        const notify_accounts = await api.getNotifyAccounts(item.collection_name)
        console.log('notify_accounts are')
        console.log(notify_accounts)
        console.log(`looking for contract`)
        console.log(process.env.REACT_APP_NFTBACKER_CONTRACT)
        console.log(notify_accounts.includes(process.env.REACT_APP_NFTBACKER_CONTRACT))

        isConfigured = isConfigured || notify_accounts.includes(process.env.REACT_APP_NFTBACKER_CONTRACT)
        console.log(`isconfigured is ${isConfigured}`)
        setConfigured( isConfigured )
      }
      checkConfigured()
    },[])

    const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];
  
    if ( ! buttons ) { buttons = [] }
    if ( ! options ) { options = {} }

    const toPath = path + item.collection_name

    // data to decode
    const decodeFields = ['images', 'socials', 'creator_info' ]
    for ( const field of decodeFields ) {
        if ( typeof item.data[field] === 'string' || item.data[field] instanceof String ) {
            item.data[field] = JSON.parse(item.data[field])
        }
    }

    const imgSrc = (item.data && item.data.images && item.data.images['banner_1920x500']) ? item.data.images['banner_1920x500'] : item.img
  
    const image =  (
      <CardImageFull
        src={imgSrc}
        alt={item.name} />
    )

    const buttonPath = (backed && configured) ? `/creator/collection/${item.collection_name}` : `/creator/collection/${item.collection_name}/enroll`
  
    return (
      <Card radius="lg" shadow={options.shadow} p="lg">

        <Card.Section>
          { options.nolink ?
                image
            :   <Anchor component={Link} to={buttonPath}>
                  {image}
                </Anchor>
          }
        </Card.Section>
 
        <Group position="center" style={{ marginBottom: 0, marginTop: theme.spacing.sm }}>
          <Text weight={700} size='md'>
            {item.name}
          </Text>
        </Group>

        <Button component={NavLink} to={buttonPath} variant="light" color="blue" fullWidth style={{ marginTop: 14 }}>{(backed && configured) ? 'Manage' : 'Enroll'}</Button>
       
      </Card>    
  
    )
  }