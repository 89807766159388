import { Container, Grid, Title, Text, Card, Group, useMantineTheme, SimpleGrid, Button } from "@mantine/core";
import { ICollection, ITemplate } from "atomicassets/build/API/Explorer/Objects";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import { TemplateCard } from "../../components/NFT/cards/TemplateCard";
import { IDrop } from "../../components/NFT/interfaces/DropInterfaces";
import { AAMiddleware } from "../../store/AAMiddleware"
import _ from "lodash"
import { ThumbTemplateGallery } from "../../components/NFT/cards/ThumbTemplateGallery";
import { Price } from "../../components/NFT/Price";
import { Quantity } from "../../components/controls/Quantity";
import { CollectionHeader } from "../../components/headers/CollectionHeader";
import { CollectionSocials } from "../../components/NFT/CollectionSocials";
import InfiniteDataProvider from "../../providers/InfiniteItemProvider";

import { configs as infiniteItemConfigs } from "../../providers/InfiniteItemProvider";
import { FixedItemGrid } from "../../components/NFT/FixedItemGrid";
import { CollectionSubHeader } from "../../components/headers/CollectionSubHeader";

const api = new AAMiddleware()

interface ITemplates extends Array<ITemplate> {}

export function Drop() {
    const { type, id } = useParams()
    const [drop,setDrop] = useState<IDrop>()
    const [collection,setCollection] = useState<ICollection>()
    const [templates,setTemplates] = useState<ITemplates>([])
    const [quantity,setQuantity] = useState(1)
    const [otherDropConfig,setOtherDropConfig] = useState(infiniteItemConfigs.neftyDrops)
    const [hasAccess,setHasAccess] = useState(false)

    const theme = useMantineTheme();
    const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];

    const onUpdateQuantity = (qty:number) => {
        if ( ! drop ) { return }
        if ( qty > ( drop.max_claimable - drop.current_claimed ) ) { return }
        setQuantity(qty)
    }

    useEffect(() => {
        const loadDrop = async () => {
            // Should display 404
            if ( ! id ) { return }

            const contract = ( type == 'atomic' ) ? 'atomicdropsx' : 'neftyblocksd'
            const dropTmp = await api.getDrop(id,contract)

            // Should display 404
            if ( ! dropTmp ) { return }
            console.log(dropTmp)

            setDrop(dropTmp)
        }
        loadDrop()
    }, [id,type])

    useEffect(() => {
        if ( ! drop ) { return }
        const newOtherDropConfig = Object.assign({},otherDropConfig)
        newOtherDropConfig.filters = [ (o) => o.drop_id != drop.drop_id ]
        setOtherDropConfig(newOtherDropConfig)
    }, [drop])

    useEffect(() => {
        const loadCollection = async () => {
            if ( ! drop ) { return }
            let col = await api.getCollection(drop?.collection_name)
            setCollection(col)
        }
        loadCollection()

    }, [drop]);

    useEffect(() => {
        const loadTemplates = async () => {
            if ( ! drop ) { return }
            const list = await api.getTemplates({ids: drop.assets_to_mint.map((a)=>a.template_id).join(',')})
            setTemplates(list)
        }
        loadTemplates()
    }, [drop])

    const displayData = drop?.display_data ? JSON.parse(drop?.display_data) : {}

    const price = parseFloat(drop?.listing_price.split(' ')[0] || '0' ) == 0 ?
        (
            <Group position='center' mb='xl'>
                <Title order={3}>FREE</Title>
            </Group>
        ) :
        (
            <Group position='center' mb='xl'>
                <Title order={3}><Price price={drop?.listing_price} settlement={drop?.settlement_symbol} /></Title>
                (total <Price qty={quantity} price={drop?.listing_price} settlement={drop?.settlement_symbol} />)
            </Group>
        )

    const getDropUrl = () => {
        if ( type == 'nefty' ) {
            return `https://neftyblocks.com/c/${collection?.collection_name}/drops/${id}`
        }
        if ( type == 'atomic' ) {
            return `https://wax.atomichub.io/drops/${id}`
        }
        return ''
    }

    const ucfirst = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    
    return (
        <>
            <CollectionHeader collection={collection} />
            {/* <CollectionSubHeader collection={collection} /> */}
            <CollectionSocials collection={collection} />
            <Container px='xl' py='xl'>
                <Grid>
                    <Grid.Col md={4}>
                        <ThumbTemplateGallery items={templates} />
                    </Grid.Col>
                    <Grid.Col md={8}>
                        <Card mx='xl' radius='lg'>
                            <Title order={2}>{displayData.name}</Title>
                            <ReactMarkdown>
                                {displayData.description}
                            </ReactMarkdown>

                            <Group position='apart'>
                                <div>
                                    <Group position='left' style={{ marginBottom:0, marginTop: 0 }}>
                                    <Text align='left' pt='xl' pb={0} px='md' size='xs' weight={700} color={theme.colors.dark[3]}>{displayData.name}</Text> 
                                    </Group>
                                    <Group position='left' style={{ marginBottom: theme.spacing.md, marginTop: 0 }}>
                                    <Text
                                        style={{
                                        overflow: 'hidden',
                                        maxWidth: '25ch',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        }} 
                                        align='left' pt={0} px='md' size='xs' weight={700} color={theme.colors.dark[5]}>{drop?.assets_to_mint.length + ' NFT' + (drop?.assets_to_mint.length == 1 ? '':'s')}</Text> 
                                    </Group>
                                </div>
                                <div>
                                    <Text size='xs' align='right'>
                                    Available
                                    </Text>
                                    <Text size='sm' weight={700} align='right'>{drop?.current_claimed}/{drop?.max_claimable}</Text>
                                </div>

                            </Group>
                            
                            {price}

                            <Group position='center'>
                                <Button px='xl' component="a" href={getDropUrl()} >View On {ucfirst(type)}</Button>
                            </Group>

                            {/* <Group position='center'>
                                <Quantity initial={quantity} max={!drop?0:(drop.max_claimable-drop.current_claimed)} onChange={onUpdateQuantity}></Quantity>
                                <Button px='xl' >Claim</Button>
                            </Group> */}

                        </Card>
                    </Grid.Col>

                    <Grid.Col xs={12}>
                        {
                            drop ? 
                                <InfiniteDataProvider withSearch={false} title="Other Drops" pageSize={6} config={otherDropConfig} additionalQueryParams={{collection_name: collection?.collection_name}} component={FixedItemGrid}></InfiniteDataProvider>
                                : ''
                        }
                    </Grid.Col>

                </Grid>

            </Container>
        </>

    );
}