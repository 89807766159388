import { forwardRef, useState } from 'react';
import { Group, Avatar, Text, Select, Image, Container, Popover } from '@mantine/core';

import { Asset } from '@greymass/eosio';
import _ from 'lodash'
import { tokens } from '../../config/tokens';

const BackedTokenAmount = ({backed_token, showIcons=true, iconSize=32, textProps={}}) => {

  const [opened, setOpened] = useState(false);

  const token = _.find(tokens,{symbol:backed_token.token_symbol})

  if ( ! token ) { return ( <></> ) }

  const tokenImg = `/tokens/${token.symbol.toLowerCase()}_${token.contract}.png`

  // return (
  //   <Container>
  //     <Group position='center'>
  //       <Image src={tokenImg} height={32} />
  //       <Text weight={700}>{backed_token.amount} {token.symbol}</Text>
  //     </Group>
  //     <Text align='center' mt='md' size='xs'><em>{token.description}</em></Text>
  //   </Container>
  // )

  return (
    <Popover
      opened={opened}
      onClose={() => setOpened(false)}
      position="bottom"
      placement="center"
      withArrow
      trapFocus={false}
      closeOnEscape={false}
      transition="pop-top-left"
      width={260}
      styles={{ body: { pointerEvents: 'none' } }}
      target={
        <Group position='center' onMouseEnter={() => setOpened(true)} onMouseLeave={() => setOpened(false)}>
          {
            showIcons
            ? <Image src={tokenImg} height={iconSize} />
            : ''
          }
          <Text weight={700} {...textProps} >{backed_token.amount} {token.symbol}</Text>
        </Group>
      }
    >
      <div style={{ display: 'flex' }}>
          <Image
            src={tokenImg}
            width={iconSize}
            height={iconSize}
            sx={{ minWidth: iconSize }}
            mr="md"
          />
          <Text size="sm">{token.description}</Text>
      </div>
    </Popover>    
  )

}

export default BackedTokenAmount;
