import { Space } from "@mantine/core";
import { AssetCard } from "./cards/AssetCard";
import { BackedAssetCard } from "./cards/BackedAssetCard";
import { CollectionCard } from "./cards/CollectionCard";
import { GenericCard } from "./cards/GenericCard";
import { TemplateCard } from "./cards/TemplateCard";
import { TemplateStatCard } from "./cards/TemplateStatCard";
import ItemGrid from "./ItemGrid";

export interface ILoadDataCallback {
    () : Promise<void>;
}

export interface IEventCallback {
    (e?:any) : void;
}

export interface IButton {
    label: string;
    onClick: IEventCallback;
    icon: React.ReactNode;
}

export interface IInifiteDataProvider {
    next: ILoadDataCallback;
    readonly items: any[];
    readonly dataLength: number;
    readonly hasMore: boolean;
}

export interface IFixedItemGridProps {
    provider: IInifiteDataProvider;
    gridCard:     typeof AssetCard 
                | typeof BackedAssetCard 
                | typeof CollectionCard 
                | typeof GenericCard 
                | typeof TemplateCard 
                | typeof TemplateStatCard;
    buttons: IButton[];
    itemBaseUri: string;
}

export function FixedItemGrid(props : IFixedItemGridProps ) {
    return (
        <>
            <ItemGrid path={props.itemBaseUri} GridCard={props.gridCard} items={props.provider.items} buttons={props.buttons} />
            <Space h="md" />
        </>
    )
}