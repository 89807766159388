import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Grid, Skeleton, Container, useMantineTheme, Title, Center, createStyles, Avatar, ThemeIcon, SimpleGrid, Divider, Accordion, CardSection, ScrollArea, Anchor } from '@mantine/core';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { UALContext } from 'ual-reactjs-renderer';
import ReactMarkdown from 'react-markdown'

import { Asset } from '@greymass/eosio'

import { Table, Card, Image, Group, Text, Badge, Button } from '@mantine/core';

import { Stack2, Book, Template, ArrowsRightLeft, LayoutGridAdd, Flame, User, Barcode, AlignJustified, ListDetails, Briefcase, InfoCircle, CirclePlus, ArrowsLeftRight, Receipt, Replace, BuildingBank, Clock, Tag, FloatLeft, Database } from 'tabler-icons-react';

import Moment from 'react-moment'
import { IAsset, IAssetStats, ICollection, ILog } from 'atomicassets/build/API/Explorer/Objects';
import { useModals } from '@mantine/modals';
import { rpc } from '../..';
import LogTable from '../../components/NFT/LogTable';
import { AAMiddleware } from '../../store/AAMiddleware';
import BackingDescription from '../../components/NFT/BackingDescription';
import { ITabItem, TabbedContent } from '../../components/TabbedContent';
import BackedTokenAmount from '../../components/NFT/BackedTokenAmount'
import { resolveUrl } from '../../lib/ImageHelper';
import BackingDescriptionTokens from '../../components/NFT/BackingDescriptionTokens';

interface ContactIconProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
    icon: React.FC<any>;
    title: React.ReactNode;
    description: React.ReactNode;
}

const dataTypeFilterMap = {
    int8: 'number',
    int16: 'number',
    int32: 'number',
    int64: 'text',
    uint8: 'number',
    uint16: 'number',
    uint32: 'number',
    uint64: 'text',
    fixed8: 'number',
    fixed16: 'number',
    fixed32: 'number',
    fixed64: 'text',
    float: 'number',
    double: 'number',
    string: 'text',
    ipfs: 'text',
    bool: 'bool',
    byte: 'text', // Unsure about this one
    image: 'text',
}

const getDataTypeFilterParam = (asset:IAsset, attribute:string) => {
    const format = asset.schema.format.find( f => f.name == attribute )
    if ( ! format ) { return }
    return `data:${dataTypeFilterMap[format.type]}.${attribute}`
}

const useStyles = createStyles((theme) => ({
    card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },
  
    imageSection: {
      padding: theme.spacing.md,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottom: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,
    },
  
    label: {
      marginBottom: theme.spacing.xs,
      lineHeight: 1,
      fontWeight: 700,
      fontSize: theme.fontSizes.xs,
      letterSpacing: -0.25,
      textTransform: 'uppercase',
    },
  
    section: {
      padding: theme.spacing.md,
      borderTop: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,
    },
  
    icon: {
      marginRight: 5,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
    },

    title: {
    color: theme.colors.gray[6],
    },

    description: {
    color: theme.black,
    },

}));

const api = new AAMiddleware()

export function Details() {
    const [asset,setAsset] = useState<IAsset>()
    const [assetLogs,setAssetLogs] = useState<Array<ILog>>()
    const [assetStats,setAssetStats] = useState<Array<IAssetStats>>()
    const [collection,setCollection] = useState<ICollection>()
    const [filters,setFilters] = useState<Array<any>>([])
    const [freqencyMap,setFrequencyMap] = useState({})
    const [selectedImage, setSelectedImage] = useState(0)
    const [authorDesc,setAuthorDesc] = useState('')
    const { id } = useParams()

    const [initialAccordian,setInitialAccordian] = useState(0)

    const ual = useContext(UALContext) as any;

    const theme = useMantineTheme();
    const { classes } = useStyles();

    const modals = useModals()

    const navigate = useNavigate();

    const secondaryColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[1]
      : theme.colors.gray[7];

    const accentColor = theme.colorScheme === 'dark'
      ? theme.colors.blue[0]
      : theme.colors.blue[5];

    const lightTextColor = theme.colorScheme === 'dark'
      ? theme.colors.gray[1]
      : theme.colors.gray[7];

   
    const child = <Skeleton height={140} radius="md" animate={false}    />;

    const descFields = ['desc','description']
    const legalFields = ['legal']
    const websiteFields = ['website']
    const unpackFields = ['unpack','unpackurl','unpack_url']

    const imageConfig = { fullHeight: 300, thumbHeight: 40 }


    // const navigateToCollection = useCallback(() => {
    //     navigate(`/explorer/collection/${asset?.collection.collection_name}`, {replace: false})
    // }, [navigate]);

    const navigateToAuthorProfile = useCallback(() => {
        navigate(`/explorer/profile/${asset?.collection.author}`, {replace: false})
    }, [navigate]);
 
    
    const getAttributes = () => {
        if ( ! asset ) { return [] }
        // Only return non-image attributes
        return Object.keys(asset.data).filter(
            (a) => { return !! asset.schema.format.find(
                (s) => (
                    s.name==a 
                    && s.name != 'name'
                    && s.type != 'image'
                    && ! descFields.includes(a)
                    && ! legalFields.includes(a)
                    && ! websiteFields.includes(a)
                    && ! unpackFields.includes(a)
                    && a.indexOf('url') === -1
                    // Do not include long content
                    && ! ( asset.data[a] && asset.data[a].length > 20 ) 
                )
            )}
        )
    }

    // Load the asset
    useEffect( () => {
        const loadAsset = async () => {   
            if ( ! id ) { return }     
            const a = await api.getAsset(id??'')
            setAsset(a)
        }
        loadAsset()
    }, [id] )

    // Load the asset logs
    useEffect( () => {
        const loadAssetLogs = async () => {        
            if ( ! id ) { return }     
            const l = await api.getAssetLogs(id??'')
            setAssetLogs(l)
        }
        loadAssetLogs()
    }, [id] )

    useEffect( () => {
        async function calcAttrFreqency() {
            if ( ! asset ) { return; }
            const attrs = getAttributes()
            let freqMap = {}

            const baseQuery = {
                collection_name: asset?.collection.collection_name,
                schema_name: asset?.schema.schema_name,
            }

            const total = await api.countAssets(baseQuery)

            for ( let i = 0; i < attrs.length; i++ ) {
                const a = attrs[i]
                let filterParam = getDataTypeFilterParam(asset,a)

                // If we can't resolve a filter param, assume the value occurs everywhere
                if ( ! filterParam ) {
                    freqMap[a] = 100
                    continue;
                }

                // Localize our query for the current attribute
                let query = Object.assign({},baseQuery)
                query[filterParam] = asset?.data[a]

                const count = await api.countAssets(query)

                freqMap[a] = Math.round( count / total * 100 )
            }
            setFrequencyMap(freqMap)
        }
        calcAttrFreqency();
    }, [asset]);
    
    // Load the collection info
    useEffect( () => {
        if ( ! asset ) { return }
        const loadCollection = async () => {    
            if ( ! asset?.collection.collection_name ) { return }    
            const c = await api.getCollection(asset.collection.collection_name)
            setCollection(c)
        }
        loadCollection()
    }, [asset] )

    // Load the author info
    useEffect( () => {
        const loadAuthorInfo = async () => {    
            if ( ! asset?.collection.author ) { return }
            const query = {
                "code": "atomhubtools",
                "table": "acctexts",
                "scope": asset?.collection.author,
                "lower_bound": "description",
                "upper_bound": "description",
                "limit": 1,
                "json": true
            }
            const res = await rpc.get_table_rows(query)
            if ( res.rows.length ) {
                const desc = res.rows[0].value
                setAuthorDesc(desc)
            }
        }
        loadAuthorInfo()
    }, [asset] )

    useEffect( () => {
        async function fetchColFilters() {
            if ( ! asset ) { return }
            let request = {
                json: true,              // Get the response as json
                code: 'atomhubtools',    // Contract that we target
                scope: asset?.collection.collection_name,    // Account that owns the data
                table: 'colfilters',     // Table name
                limit: '1000',             // Maximum number of rows that we want to get PER REQUEST PAGE
                reverse: false,          // Optional: Get reversed data
                show_payer: false,       // Optional: Show ram payer
                lower_bound: null,
            };
    
            const res = await rpc.get_table_rows(request) as any
            const newFilters = res.rows
    
            setFilters(newFilters)
        }
    
        fetchColFilters();
    
    }, [rpc, asset]);

    const templateIdLinkLabel = (
        <Anchor component={Link} to={'/explorer/template/'+asset?.template?.template_id}>
            {'#' + asset?.template?.template_id}
        </Anchor>        
    )

    const backedByTokenContent = (
        <Group position='left'>
            { asset?.backed_tokens.map ( (t) => <BackedTokenAmount textProps={{size: 'sm'}} backed_token={t} key={t.token_symbol} iconSize={16} /> ) }
        </Group>        
    )

    const getFeatures = () => {
        if ( ! asset ) { return [] }
        return [
            { fullWidth: false, title: 'Collection', label: <Anchor href={'/explorer/collection/'+asset.collection.collection_name}>{asset.collection.collection_name}</Anchor>, icon: Stack2, visible: true },
            { fullWidth: false, title: 'Schema', label: asset.schema.schema_name, icon: Book, visible: true },
            { fullWidth: false, title: 'Template', label: templateIdLinkLabel, icon: Template, visible: !!asset.template },
            { fullWidth: false, title: 'Owner', label: asset.owner, icon: User, visible: true },
            { fullWidth: false, title: false, label: ( asset.is_transferable ? 'Transferable' : 'Non-Transferable'), icon: ArrowsRightLeft, visible: true },
            { fullWidth: false, title: false, label: ( asset.is_burnable ? 'Burnable' : 'Not Burnable'), icon: Flame, visible: true },
            { fullWidth: false, title: 'ID', label: '#' + asset.asset_id, icon: Barcode, visible: true },
            { fullWidth: false, title: 'Mint', label: '#' + asset.template_mint, icon: LayoutGridAdd, visible: true },
            // { fullWidth: false, title: 'Backed By', label: <Text size='sm' weight={700}>{asset.data.backedby}</Text>, icon: Database, visible: !! asset.data.backedby },
            { fullWidth: true, title: 'Backed By', label: backedByTokenContent, icon: Database, visible: !! asset.backed_tokens?.length },
        ];
    }

    const getImages = () => {
        if ( ! asset ) { return [] }
        return asset.schema.format.filter(i=>( i.type == 'image' && asset.data[i.name])).map(i=>asset.data[i.name])
    }

    const thumbs = getImages().map((img,i)=>{
        const click = () => {
            setSelectedImage(i)
        }
        return (
            <Avatar 
                key={`thumb-${i}`} 
                size={imageConfig.thumbHeight} 
                src={resolveUrl( img, '?h=' + imageConfig.thumbHeight )}
                // src={process.env.REACT_APP_IMAGE_PROXY+img+'?h=' + imageConfig.thumbHeight} 
                radius={imageConfig.thumbHeight} 
                style={{border: ( selectedImage == i ? '1px' : '0px' ) + ' solid ' + theme.colors.gray[5]}}
                onClick={click}
            />
        )
    })

    const attributes1 = getAttributes().map((a) => {
        const mutable = ( asset?.mutable_data[a] !== undefined )
        return (
            <Grid key={a}>
                <Grid.Col span={2}><Text size='sm' weight='bold'>{a}{mutable ? '*' : ''}</Text></Grid.Col>
                <Grid.Col span={10}><Text size='sm' style={{overflowWrap: 'anywhere'}}>{asset?.data[a]}</Text></Grid.Col>
            </Grid>
        )
    })

    const attributes = (
        <Group>
            {
                getAttributes().map((a) => {
                    const mutable = ( asset?.mutable_data[a] !== undefined )
                    return (
                        <Card
                            key={'attribute-card-'+a}
                            withBorder={true}
                            radius='md'
                            px='xs'
                            py='xs'
                            sx={(theme) => ({
                                borderWidth: '1px',
                                borderColor: theme.colors.gray[5],
                                width: '150px',
                                backgroundColor: theme.colors.gray[3],
                                '&:hover': {
                                    backgroundColor: theme.colors.gray[4],
                                },
                            })}
                        >
                            <Group position="center">
                                <Text size='xs' style={{textTransform:'uppercase'}} color={accentColor} weight='bold'>{a}{mutable ? '*' : ''}</Text>
                            </Group>
                            <Group position="center">
                                <Text size='sm' style={{overflowWrap: 'anywhere'}}>{asset?.data[a]}</Text>
                            </Group>
                            <Group position="center">
                                <Text size='xs' color={lightTextColor} weight='normal'>{freqencyMap[a]}% have this trait</Text>
                            </Group>
                        </Card>
                    )
                })
            }
        </Group>
    )

    const features = getFeatures().map((feature)=>{
        if ( ! feature.visible ) { return }
        return (
            <Grid.Col span={feature.fullWidth ? 12 : 6} key={`detail-feature-${feature.title}-${feature.label}`}>
                <Group noWrap>
                    <ThemeIcon size={40} radius="md" className={classes.icon} variant="light">
                        <feature.icon size={24} />
                    </ThemeIcon>
                    <div>
                        <Text size="xs" className={classes.title}>
                            {feature.title}
                        </Text>
                        <Text size='sm' className={classes.description}>{feature.label}</Text>                
                    </div>
                </Group>
            </Grid.Col>
        )
    })

    const openImageModal = () => {
        const id = modals.openModal({
          withCloseButton: false,
          children: (
            <Image 
                withPlaceholder 
                src={resolveUrl( getImages()[selectedImage] )}
                // src={process.env.REACT_APP_IMAGE_PROXY + getImages()[selectedImage]} 
                alt={asset?.name}
                onClick={modals.closeAll}
            />
          ),
        });
      };

    const imageCard = (
        asset ? 
            <>
                <Card>
                    <Card.Section>
                        <Image 
                            fit="contain"
                            height={imageConfig.fullHeight}
                            withPlaceholder 
                            radius="lg" 
                            src={resolveUrl( getImages()[selectedImage], '?h='+imageConfig.fullHeight )}
                            // src={process.env.REACT_APP_IMAGE_PROXY + getImages()[selectedImage] + '?h='+imageConfig.fullHeight} 
                            alt={asset.name}
                            onClick={openImageModal}
                        />
                    </Card.Section>
                </Card>
                <Group position="center" style={{ marginBottom: theme.spacing.lg, marginTop: theme.spacing.lg }}>
                    {thumbs}
                </Group>
            </>
        : <Skeleton height={140} radius="md" animate={false} />
    )


    const summaryCard = (
        asset ? 
            <Card shadow="sm" p="lg" radius="lg">
                <Title order={1}>{asset.name}</Title>
                <Group style={{ marginBottom: theme.spacing.lg, marginTop: theme.spacing.lg }}>
                        <Grid>
                            {features}                
                        </Grid>
                </Group>

                <Group position="apart" style={{ marginBottom: 5, marginTop: theme.spacing.sm }}>
                </Group>
            </Card>
        : <Skeleton height={140} radius="md" animate={false} />
    )

    const attributeContent = (
        asset ? 
        (
            <>
                {attributes}
                {
                    Object.keys(asset.mutable_data).length > 0 ?
                    <Text style={{ marginTop: theme.spacing.lg }} size='xs'>* a property that may change over time</Text>
                    : ''
                }
            </>
        )
        : ''
    )

    const collectionContent = (
        collection  && collection.data.description ?
        (
            <>
                <Text size='sm' style={{ marginBottom: theme.spacing.sm }} color={secondaryColor}>Created by <Anchor onClick={navigateToAuthorProfile}>{collection.author}</Anchor></Text>
                <ReactMarkdown>
                    {collection?.data.description}
                </ReactMarkdown>

                {/* {
                    collection?.data.description.split("\n").map((line,i) => {
                        return (
                            <Text key={'about-collection-'+i} size='sm' style={{ marginBottom: theme.spacing.sm }}>{line}</Text>
                        )
                    })
                } */}
            </>
        )
        : ''
    )

    const authorContent = (
        collection ?
        (
            <>
                {
                    authorDesc.split("\n").map((line,i) => {
                        return (
                            <Text key={'about-author-'+i} size='sm' style={{ marginBottom: theme.spacing.sm }}><ReactMarkdown>{line}</ReactMarkdown></Text>
                        )
                    })
                }
            </>
        )
        : ''
    )

    const getField = (names,type) => {
        if (! asset) { return }
        return Object.keys(asset.data).find((a) => {
            return asset.schema.format.find((f) => {
                return names.includes(f.name) && f.name == a && f.type == type
            })
        })
    }

    const getDescription = () => {
        if (! asset || ! descField ) { return }
        return asset.data[descField].split("\n").map((line,i)=>{
            return (
                <Text key={'asset-description-'+i} size='sm' style={{ marginBottom: theme.spacing.sm }}>{line}</Text>                
            )
        })
    }

    // Common attribute fields
    const descField = getField(descFields,'string')
    const legalField = getField(legalFields,'string')
    const websiteField = getField(websiteFields,'string')
    const unpackField = getField(unpackFields,'string')

    const description = descField ? asset?.data[descField] : ''
    const legal = legalField ? asset?.data[legalField] : ''
    const website = websiteField ? asset?.data[websiteField] : ''
    const unpack = unpackField ? asset?.data[unpackField] : ''


    const tabItems : ITabItem[] = [
        { visible: !! collection, label: 'About', icon: FloatLeft, content: <Card shadow="sm" p="lg" radius="lg">{collectionContent}</Card> },
        { visible: !! authorDesc, label: 'Author', icon: User, content: <Card shadow="sm" p="lg" radius="lg">{authorContent}</Card> },
        { visible: !! legalField, label: 'Legal', icon: Briefcase, content: <Card shadow="sm" p="lg" radius="lg"><Text size='sm'>{legal}</Text></Card> },
        { visible: !! assetLogs, label: 'History', icon: Clock, content: <Card shadow="sm" p="lg" radius="lg"><LogTable logs={assetLogs} /></Card> },
    ]

    // Accordian Configs
    const accordians = {
        description: { visible: !! descField, label: 'Description', icon: <AlignJustified />, content: getDescription() },
        backing: { visible: !!  asset?.backed_tokens?.length, label: 'Backed by NFT Backers', icon: <Database />, content: <BackingDescriptionTokens backed_tokens={asset?.backed_tokens} redeem_url={asset?.data.redeem_url} /> },
        attributes: { visible: getAttributes().length, label: 'Properties', icon: <Tag />, content: attributeContent },
        // collection: { visible: !! collection, label: 'About ' + collection?.name, icon: <FloatLeft />, content: collectionContent },
        // author: { visible: !! authorDesc, label: 'Author', icon: <User />, content: authorContent },
        // legal: { visible: !! legalField, label: 'Legal', icon: <Briefcase />, content: <Text size='sm'>{legal}</Text> },
        // logs: { visible: !! assetLogs, label: 'History', icon: <Clock />, content: <LogTable logs={assetLogs} /> },
    }

    const accordianCard = (
        <Card shadow="sm" p="lg" radius="lg">
            <CardSection>
                <Accordion initialItem={initialAccordian} iconPosition="right">
                    {
                        Object.values(accordians).filter((a)=>a.visible).map((a,i)=>{
                            const label = (
                                <Group noWrap>
                                    {a.icon}
                                    <div>
                                        <Text>{a.label}</Text>
                                        {/* <Text size="sm" color="dimmed" weight={400}>
                                            {description}
                                        </Text> */}
                                    </div>
                                </Group>                                
                            )
                            return (
                                <Accordion.Item label={label} key={asset?.asset_id+'accordian-'+i}>
                                    {a.content}
                                </Accordion.Item>
                            )

                        })
                    }
                </Accordion>
            </CardSection>
        </Card>
    )
    
    return (
        <Container px='xl' py='xl'>
            <Grid>
                <Grid.Col xs={4}>{imageCard}</Grid.Col>
                <Grid.Col xs={8}>{summaryCard}</Grid.Col>
                <Grid.Col xs={12}>{accordianCard}</Grid.Col>
                <Grid.Col xs={12} my='xl'>
                    <Title mb='lg' order={2}>More Information</Title>
                    <TabbedContent items={tabItems} />
                </Grid.Col>
                {/* <Grid.Col xs={4}>{child}</Grid.Col>
                <Grid.Col xs={3}>{child}</Grid.Col>
                <Grid.Col xs={3}>{child}</Grid.Col>
                <Grid.Col xs={6}>{child}</Grid.Col> */}
            </Grid>
        </Container>
    );
}