import React from 'react';
import { createStyles, Group, Paper, Text, ThemeIcon, SimpleGrid } from '@mantine/core';
import { ArrowUpRight, ArrowDownRight } from 'tabler-icons-react';

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl * 1.5,
  },

  label: {
    // fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

interface StatsGridIconsProps {
  data: { title: string; value: string; }[];
}

export function CollectionStats({ data }: StatsGridIconsProps) {
  const { classes } = useStyles();
  const stats = data.map((stat) => {

    return (
        <Paper withBorder p="md" radius="md" key={stat.title}>
            <Group position="center">
                <Text weight={700} size="xl">
                {stat.value}
                </Text>
            </Group>
            <Group position="center">
                <Text
                color="dimmed"
                transform="uppercase"
                weight='normal'
                size="xs"
                className={classes.label}
                >
                {stat.title}
                </Text>
            </Group>
        </Paper>
    );
  });

  return (
    <div className={classes.root}>
      <SimpleGrid cols={4} breakpoints={[{ maxWidth: 'sm', cols: 2 }]}>
        {stats}
      </SimpleGrid>
    </div>
  );
}