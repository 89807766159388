import { useContext } from 'react';

import { showNotification, updateNotification } from '@mantine/notifications';
import { Flame, AlertTriangle, Check, Database } from 'tabler-icons-react';

export default async function UpgradeSchema(collection,schema_name,ual) {

    const auth = [{
        actor: ual.activeUser.accountName,
        permission: ual.activeUser.requestPermission,
    }]

    const newFormat = [
        { name: 'backedby', type: 'string' }
    ]

    const actions = [
        {
            account: 'atomicassets',
            name: 'extendschema',
            authorization: auth,
            data: {
                authorized_editor: ual.activeUser.accountName,
                collection_name: collection,
                schema_name: schema_name,
                schema_format_extension: newFormat,
            }
        }
    ]

    try {
        showNotification({
            id: `upgrade-schema-${schema_name}`,
            loading: true,
            title: `Upgrading Schema`,
            message: `Preparing to upgrade schema ${schema_name}, adding the NFT Backer attribute.`,
            autoClose: false,
            disallowClose: true,
            icon: <Database />,
        });

        const res = await ual.activeUser.signTransaction(
            {
                actions: actions
            },
            {
                blocksBehind: 3,
                expireSeconds: 60
            }
        );
        updateNotification({
            id: `upgrade-schema-${schema_name}`,
            color: 'teal',
            title: `Success!`,
            message: `Schema ${schema_name} has been upgraded.`,
            icon: <Check />,
            autoClose: 10000,
        });
    } catch (e : any ) {
        console.log(e);

        const msg = e.message.replace('assertion failure with message:','');

        updateNotification({
            id: `upgrade-schema-${schema_name}`,
            color: 'red',
            title: `Unable to upgrade schema ${schema_name}`,
            message: msg,
            icon: <AlertTriangle />,
            autoClose: false,
        });
    }

}