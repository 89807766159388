import { useEffect, useState } from 'react';
import { Stepper, Button, Group, Container, Title, Text, Card, Space } from '@mantine/core';
import { Link, NavLink, useParams } from 'react-router-dom';
import { AAMiddleware } from '../../../store/AAMiddleware';
import { ICollection } from 'atomicassets/build/API/Explorer/Objects';
import ChooseBacking from './Main/ChooseBacking/ChooseBacking';
import ConfigureCollection from './Main/ConfigureCollection/ConfigureCollection';
import UpgradeSchema from './Main/UpgradeSchema/UpgradeSchema';
import { BackAssetForm } from './Creator/BackAssetForm'

import { Balances } from './Main/Balances/Balances';
import { ITabItem, TabbedContent } from '../../../components/TabbedContent';
import { Database, User, Plus, BuildingBank } from 'tabler-icons-react';
import InfiniteDataProvider from '../../../providers/InfiniteItemProvider';

import { configs as infiniteItemConfigs } from '../../../providers/InfiniteItemProvider';
import { ISchema } from 'atomicassets/build/Schema';
import { FixedItemGrid } from '../../../components/NFT/FixedItemGrid';

const api = new AAMiddleware()

export interface ISupportedToken {
    symbol: string;
    contract: string;
}
export interface IBackedCollection {
    collection_name: string;
    owner: string;
    supported_tokens: ISupportedToken[];
}

export function BalancesLayout({supportedTokens}) {
    return (
        <>
            <Title pt='xl' order={2}>Your Balances</Title>
            <Card px='xl' py='xl'>
                <Text size='md'>
                    As new backed NFTs are minted on your collection, NFT Backers will automatically attach
                    real-world value to them. We do this using funds you have on deposit with the NFT Backers
                    contract. The following are the assets available for backing on your collection:
                </Text>
            </Card>
            <Balances manage={true} showtokens={supportedTokens ?? []} />
        </>
    )
}

export function BackedTemplatesLayout({collection}) {

    const dataSort = 'created'
    // const dataSort = 'issued_supply:desc'
    const dataFilters = [
        {
            // range: { issued_supply: {gt: 0} }
        }
    ]


    const whenEmpty = (
        <>
            <Title mt='xl' pt='xl' align='center' order={3}>It looks like you haven't set up any backed templates.</Title>
            <Title mb='xl' pb='xl' align='center' order={3}>Now would be a great time to do that!</Title>
            <Group position='center'>
                <Button size='xl' component={NavLink} to={`/creator/collection/${collection}/template`}>Create Backed Template</Button>
            </Group>
        </>
    )

    return (
        <>
            <Title pt='xl' order={2}>Your Backed Templates</Title>
            <Card px='xl' py='xl'>
                <Text size='md'>
                    Backed templates are special in that they carry an attribute <em>backedby</em> that tells
                    NFT Backer what type of backing to automatically attach when minted.
                </Text>
            </Card>
            <InfiniteDataProvider 
                    withSearch={true}
                    title='BackedTemplates'
                    pageSize={20}
                    component={FixedItemGrid}
                    config={infiniteItemConfigs.backedTemplate}
                    additionalQueryParams={{collection_name: collection, 'format.name':'backedby', order: 'desc', sort: dataSort}}
                    whenEmpty={whenEmpty} />
        </>
    )
}

export function CollectionManage() {

    const { name, mode } = useParams()

    const [collection,setCollection] = useState<ICollection>()
    const [backedConfig,setBackedConfig] = useState<IBackedCollection>()
    const [supportedTokens,setSupportedTokens] = useState([] as any)

    useEffect(()=>{
        const loadCollection = async () => {
            if ( ! name ) { return }
            const col = await api.getCollection(name)
            setCollection(col)
        }
        loadCollection()
    },[name])

    useEffect(()=>{
        const loadCollection = async () => {
            if ( ! name ) { return }
            const col = await api.getCollection(name)
            setCollection(col)
        }
        loadCollection()
    },[name])

    useEffect(()=>{
        const loadBackedCollection = async () => {
            if ( ! name ) { return }
            const col = await api.getBackedCollection(name)
            const tokens = col?.supported_tokens.map(t=>t.symbol.split(',')[1])
            console.log( tokens)
            setBackedConfig(col)
            setSupportedTokens(tokens)
        }
        loadBackedCollection()
    },[name])



    const tabItems : ITabItem[] = [
        // { 
        //     label: 'Drops',
        //     component: InfiniteDataProvider,
        //     props: {
        //         withSearch: false,
        //         title: "Drops", 
        //         pageSize: 20,
        //         config: infiniteItemConfigs.neftyDrops,
        //         additionalQueryParams: {collection_name: name, filters: [], sort: dataSort},
        //         // component: FixedItemGrid
        //     }, 
        //     icon: Book,
        //     visible: hasDrops,
        // },
        { 
            label: 'Balances',
            // component: BalancesLayout,
            // props: {
            //     supportedTokens: supportedTokens
            // },
            content: <BalancesLayout supportedTokens={supportedTokens} />,
            icon: BuildingBank,
            visible: true,
        },
        { 
            label: 'Backed Templates',
            // component: BackedTemplatesLayout,
            // props: {
            //     collection: name
            // },
            content: <BackedTemplatesLayout collection={name} />,
            icon: Database,
            visible: true,
        },
        {
            label: 'Back Existing NFT',
            // component: BackAssetForm,
            // props: {},
            content: <BackAssetForm />,
            icon: Plus,
            visible: true,
        }
        // { 
        //     label: 'Templates',
        //     component: InfiniteDataProvider,
        //     props: {
        //         withSearch: true, 
        //         title: "Templates",
        //         pageSize: 20,
        //         config: infiniteItemConfigs.templateSearch,
        //         additionalQueryParams: {collection_name: name, filters: dataFilters, sort: dataSort},
        //         // component: FixedItemGrid,
        //     },
        //     icon: File,
        //     visible: ( templateCount > 0 ),
        // },
        // { 
        //     label: 'NFTs',
        //     component: <InfiniteDataProvider
        //         withSearch={true} 
        //         title="NFTs" 
        //         pageSize={20} 
        //         config={infiniteItemConfigs.asset} 
        //         additionalQueryParams={{collection_name: name}}
        //         component={FixedItemGrid}
        //         />,
        //     icon: LayoutGrid,
        //     visible: true,
        // },
        // { label: 'Schemas', component: <Text>Schemas here</Text>, icon: Book, visible: true },
        // { label: 'Accounts', component: <Text>Accounts here</Text>, icon: User, visible: false },
    ] 

    return (
        <Container px='xl' py='xl'>
            <Title pb='xl' order={1}>Manage {collection?.name ?? 'Your Collection'}</Title>
            <Card px='xl' py='xl'>
                <Text size='md'>We are glad to have <strong>{collection?.data.name}</strong> on board 
                as a certified <em>SilverBacked</em> issuer, and NFT 2.0 creator. You are bringing
                real-world value to your collection, and build trust and confidence with your community. 
                </Text>
            </Card>

            <Space my='xl'></Space>

            <TabbedContent items={tabItems} initialTab={ ! mode || mode == 'balances' ? 0 : 1 } />



        </Container>
    );
}