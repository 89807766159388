import React, { useState } from 'react';
import { Group, Box, Collapse, ThemeIcon, Text, UnstyledButton, createStyles, Divider } from '@mantine/core';
import { Icon as TablerIcon, CalendarStats, ChevronLeft, ChevronRight } from 'tabler-icons-react';
import { MainLink, MainLinkProps } from './_mainLinks';

const useStyles = createStyles((theme) => ({
    mainLinkText: {
        fontSize: theme.fontSizes.md,
        fontWeight: 700,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],  
        '&:hover': {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
      },
    control: {
//     fontWeight: 500,
//     display: 'block',
//     width: '100%',
//     padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
//     color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
//     fontSize: theme.fontSizes.sm,

//     '&:hover': {
//       backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
//       color: theme.colorScheme === 'dark' ? theme.white : theme.black,
//     },
  },

  link: {
//     fontWeight: 500,
//     display: 'block',
//     textDecoration: 'none',
//     padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
//     paddingLeft: 31,
//     marginLeft: 30,
//     fontSize: theme.fontSizes.sm,
//     color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
//     borderLeft: `1px solid ${
//       theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
//     }`,

//     '&:hover': {
//       backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
//       color: theme.colorScheme === 'dark' ? theme.white : theme.black,
//     },
  },

  chevron: {
    transition: 'transform 200ms ease',
  },
}));

interface LinksGroupProps {
  icon: TablerIcon;
  label: string;
  initiallyOpened?: boolean;
  authRequired: boolean;
  links?: MainLinkProps[];
  onClose?(): any;
}

export function LinksGroup({ icon: Icon, label, authRequired, initiallyOpened, links, onClose }: LinksGroupProps) {
  const { classes, theme } = useStyles();
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);
  const ChevronIcon = theme.dir === 'ltr' ? ChevronRight : ChevronLeft;
//   const items = (hasLinks ? links : []).map((link) => (
//     <Text<'a'>
//       component="a"
//       className={classes.link}
//       href={link.path}
//       key={link.label}
//       onClick={(event) => event.preventDefault()}
//     >
//       {link.label}
//     </Text>
//   ));
const items = (hasLinks ? links : []).map((link,i) => <MainLink onClose={onClose} {...link} key={`linkgroup-${label}-${i}`} linkStyle={{fontWeight:'normal'}} />)
const itemContainer = (
    <Box ml="md">
        {items}
        <Divider my="xs" mr="md" labelPosition="center" />
    </Box>
)

  const groupLabel = (
    <Group position="apart" spacing={0}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Icon size={18} />
        <Box ml="md">{label}</Box>
        </Box>
        {hasLinks && (
        <ChevronIcon
            className={classes.chevron}
            size={14}
            style={{
            transform: opened ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)` : 'none',
            }}
        />
        )}
    </Group>
  )

  return (
    <>
        <UnstyledButton
        onClick={() => setOpened((o)=> !o)}
        py={theme.spacing.xs}
        px={theme.spacing.xs}
        sx={(theme) => ({
            display: 'block',
            width: '100%',
            // padding: theme.spacing.xs,
            borderRadius: theme.radius.sm,
            color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

            '&:hover': {
            backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            },
        })}
        >
            <Group position="apart" spacing={0}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Icon size={18} />
                <Box ml="md">
                    <Text className={classes.mainLinkText} size="sm" underline={false} color={theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black}>{label}</Text>
                </Box>
                {/* <Box ml="md"><Text>{label}</Text></Box> */}
                </Box>
                {hasLinks && (
                <ChevronIcon
                    className={classes.chevron}
                    size={14}
                    style={{
                    transform: opened ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)` : 'none',
                    }}
                />
                )}
            </Group>
        </UnstyledButton>
        { opened ? itemContainer : '' }
    </>
  );




  return (
    <>
      <UnstyledButton onClick={() => setOpened((o) => !o)} className={classes.control}>
        <Group position="apart" spacing={0}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Icon size={18} />
            <Box ml="md">{label}</Box>
          </Box>
          {hasLinks && (
            <ChevronIcon
              className={classes.chevron}
              size={14}
              style={{
                transform: opened ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)` : 'none',
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
}

// const mockdata = {
//   label: 'Releases',
//   icon: CalendarStats,
//   links: [
//     { label: 'Upcoming releases', link: '/' },
//     { label: 'Previous releases', link: '/' },
//     { label: 'Releases schedule', link: '/' },
//   ],
// };

export function NavbarLinksGroup(props) {
  return (
    <Box
      px={0}
      sx={(theme) => ({
        // minHeight: 220,
        // padding: theme.spacing.md,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
      })}
    >
      <LinksGroup {...props} />
    </Box>
  );
}