import { Space, Grid, Chip, Card, Image, Text, Badge, Button, Group, useMantineTheme, Title, Center } from '@mantine/core';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flame, Plus } from 'tabler-icons-react';
import { GenericCard } from './cards/GenericCard';

interface ItemGridProps {
  items: any;
  buttons?: any;
  GridCard: any;
  path: string;
}

export default function ItemGrid({items, buttons, GridCard, path} : ItemGridProps) {
    const theme = useMantineTheme();

    if ( ! GridCard ) {
      GridCard = GenericCard
    }

    if ( ! buttons ) { buttons = [] }

    const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];

    return (
        <Grid gutter="xl">
          {
            items.map((item,i) => { 
              return (
                <Grid.Col xs={12} sm={6} md={4} lg={4} key={`item-grid-${i}`}>
                  <GridCard item={item} buttons={buttons} path={path}></GridCard>
                </Grid.Col>
              )
            })
          }
        </Grid>
      )

}
