import { Space, Grid, Chip, Card, Image, Text, Badge, Button, Group, useMantineTheme, Anchor } from '@mantine/core';
import { ICollection } from 'atomicassets/build/API/Explorer/Objects';
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IElasticCollection } from 'nftbackers-elastic-interfaces'
import { CardImage } from './CardImage';
import { CardImageFull } from './CardImageFull';
import { IGenericCardProps } from './GenericCard';
import removeMd from 'remove-markdown'


import LinesEllipsis from 'react-lines-ellipsis'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'

import ReactMarkdown from 'react-markdown';


interface ICollectionCardProps extends IGenericCardProps {
  item: ICollection | IElasticCollection;
}

export function CollectionCard({item, buttons, options, path} : ICollectionCardProps) {
    const theme = useMantineTheme();
    const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];
  
    if ( ! buttons ) { buttons = [] }
    if ( ! options ) { options = {} }

    const toPath = path + item.collection_name

    // data to decode
    const decodeFields = ['images', 'socials', 'creator_info' ]
    for ( const field of decodeFields ) {
        if ( typeof item.data[field] === 'string' || item.data[field] instanceof String ) {
            item.data[field] = JSON.parse(item.data[field])
        }
    }

    const imgSrc = (item.data && item.data.images && item.data.images['banner_1920x500']) ? item.data.images['banner_1920x500'] : item.img
  
    // const image =  (
    //   <CardImage
    //     src={item.img}
    //     alt={item.name}
    //     onClick={()=>{}} />
    // )

    const image =  (
      <CardImageFull
        src={imgSrc}
        alt={item.name}
        onClick={()=>{}} />
    )
  
    return (
      <Card radius="lg" shadow={options.shadow} p="lg">

        <Card.Section>
          { options.nolink ?
                image
            :   <Anchor component={Link} to={toPath}>
                  {image}
                </Anchor>
          }
        </Card.Section>
 
        <Group position="center" style={{ marginBottom: 0, marginTop: theme.spacing.sm }}>
          <Text weight={700} size='md'>
            {item.name}
          </Text>
        </Group>

        <Group position="center" style={{ marginBottom: theme.spacing.md, marginTop: 0 }}>
          <Text weight={500} size='sm'>
            by {item.author}
          </Text>
        </Group>
        
        <Group position="center" style={{ marginBottom: theme.spacing.md, marginTop: 0 }}>
          <Text weight={500} size='md' color={secondaryColor}>
              <LinesEllipsis
                text={removeMd(item.data.description)}
                maxLine='3'
                basedOn='words'
                />
          </Text>
        </Group>

        {
          buttons.map((b,i) => {
            return (
              <Button key={i} leftIcon={b.icon} variant="light" color="blue" fullWidth style={{ marginTop: 14 }} onClick={b.onClick}>
                {b.label}
              </Button>
            )
          })
        }
      </Card>    
  
    )
  }