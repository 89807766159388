import { Container, Grid, Title, Text, Group, Loader, Table, Image } from "@mantine/core";
import { IToken, tokens } from "../../config/tokens";
import _ from "lodash"
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { AlertTriangle, BuildingBank, ChartArrowsVertical, Check, Flag, QuestionMark } from "tabler-icons-react";
import { IAsset } from "atomicassets/build/API/Explorer/Objects";
import { AAMiddleware, INFTBackerConfig, IProtocolData, IProtocolStat } from "../../store/AAMiddleware";
import { Asset } from '@greymass/eosio';
import AssetCard from "../../components/NFT/AssetCard";
import { BackedAssetCard } from "../../components/NFT/cards/BackedAssetCard";
import { ITabItem, TabbedContent } from "../../components/TabbedContent";

const api = new AAMiddleware()

export function Icon({status, size=64, unknown=false}) {

    if ( unknown ) {
        return (
            <QuestionMark color='orange' size={size} />
        )    
    }
    if ( status === true ) {
        return (
            <Check color='green' size={size} />
        )    
    }
    else if ( status === false ) {
        return (
            <Flag color='red' size={size} />
        )
    }
    return ( <Loader size={size} /> )
}

interface IBackedAsset {
    asset_id: string
    owner: string
    template_id: string
    backed_tokens: string[]
}

interface IStatusCheckParams {
    title: string;
    description: string;
    error: string;
    status: boolean | undefined;
    detail?: string;
    support?: any;
    unknown?: boolean | undefined;
}
function StatusCheck({title,description,error,status,detail,support,unknown=false} : IStatusCheckParams) {
    return (
        <>
            <Grid mt='xl'>
                <Grid.Col xs={2}><Icon status={status} unknown={false} /></Grid.Col>
                <Grid.Col xs={10}>
                    <Title order={4}>{(status === true || status === undefined ) ? title : error }</Title>
                    <Text size='md'>
                        {description}
                    </Text>
                    {
                        detail
                        ? <Text size='xs' color='gray'>{detail}</Text>
                        : ''
                    }
                </Grid.Col>
            </Grid>
            {support}
        </>
    )
}

export function VerifyAsset({asset_id,protocolStatus}) {

    const [oncontract,setOnContract] = useState<boolean>()
    const [asset,setAsset] = useState<IAsset>()
    const [backedAsset,setBackedAsset] = useState<IBackedAsset>()

    useEffect(()=>{
        const loadAsset = async () => {
            const a = await api.getAsset(asset_id)
            if ( ! a ) { return }
            setAsset(a)
        }
        loadAsset()
    },[asset_id])

    useEffect(()=>{
        const loadBackedAsset = async () => {
            if ( ! asset ) { return }
            const newBackedAsset = await api.getBackedAsset(asset.collection.collection_name,asset.asset_id)
            if ( newBackedAsset ) {
                setBackedAsset(newBackedAsset)
                setOnContract(true)
            }
        }
        loadBackedAsset()
    },[asset])

    if ( ! asset_id || ! asset ) {
        return ( <></> )
    }

    const checks = [
        {
            title: 'NFT exists on NFT Backer contract',
            error: 'NFT does not exist on NFT Backer contract',
            description: 'The NFT must exist in the assets table on the NFT Backers contract (thenftbacker).',
            detail: `We checked that the NFT with id ${asset_id} is recorded in the assets table.`,
            note: false,
            status: !! backedAsset,
        },
        {
            title: 'NFT has backing tokens attached on NFT Backer contract',
            error: 'Does not have backing tokens attached on NFT Backer contract',
            description: 'The NFT\'s entry in the assets table on the NFT Backers contract (thenftbacker) must have tokens attached.',
            detail: `We checked that the NFT with id ${asset_id} has backing tokens recorded in the assets table.`,
            note: false,
            status: !! backedAsset?.backed_tokens.length,
        },
        {
            title: 'All protocol compliance requirements are met',
            error: 'One or more protocol compliance requirements have not been met',
            description: 'NFT Backers\' NFT 2.0 Protocol for asset-backed NFTs, and the LD2 Protocol for asset-backed tokens, together form the most complete set of assurances for on-chain tokenization of real world assets. ',
            detail: `We checked for each of the aspects of the NFT Backers NFT 2.0 and the LD2 Protocol`,
            note: false,
            status: protocolStatus,
        },
    ]

    return (
        <>
            <Grid mb='xl'>
            <Grid.Col md={4}>
                    <Group position='center'>
                    <BackedAssetCard item={asset} path={"/explorer/asset"} options={{hideButtons:true}} /> 
                    </Group>
                </Grid.Col>
                <Grid.Col md={8}>
                    {
                        checks.map((c,i) => <StatusCheck {...c} key={i} /> )
                    }
                </Grid.Col>
            </Grid>
        </>
    )
}


function VerifyProtocol({token,onVerify}) {

    const [protocolData,setProtocolData] = useState<IProtocolData>()
    const [stat,setStat] = useState<IProtocolStat>()

    const [deposited,setDeposited] = useState<boolean>()
    const [audited,setAudited] = useState<boolean>()
    const [roles,setRoles] = useState<boolean>()

    const [hasCorrectSupply,setHasCorrectSupply] = useState<boolean>()

    const [crossChain,setCrossChain] = useState<any>()
    const [crossChainReport,setCrossChainReport] = useState<any>()
    const [hasCrossChainReport,setHasCrossChainReport] = useState<boolean>()
    const [validCrossChain,setValidCrossChain] = useState<boolean>()
  
    useEffect(()=>{
        const loadCrossChain = async () => {
            if ( ! token || ! token.crossChainCompliance?.status ) { return }
            try {
                const response = await fetch(token.crossChainCompliance.status);
                if ( ! response.status ) { return }
                const data = await response.json()
                setCrossChain(data)                
            } catch (err) {
                // handle error
                console.error(err);
            }
        }
        loadCrossChain()
    },[])
  
    useEffect(()=>{
        const loadCrossChainReports = async () => {
            if ( ! token || ! token.crossChainCompliance?.status ) { return }
            try {
                const response = await fetch(token.crossChainCompliance.reports);
                if ( ! response.status ) { return }
                const data = await response.json()
                const report = _.find(data,{symbol: token.symbol})
                if ( report ) {
                    setCrossChainReport(report)
                    setHasCrossChainReport(true)
                }
            } catch (err) {
                // handle error
                console.error(err);
            }
        }
        loadCrossChainReports()
    },[])

    useEffect(()=> {
        const loadProtocolData = async () => {
            if ( ! token ) { return }
            const data = await api.getProtocolTokenData(token)
            if ( data ) {
                setProtocolData(data)
            }
        }
        loadProtocolData()
    },[])

    useEffect(()=> {
        const loadStats = async () => {
            if ( ! token ) { return }
            const data = await api.getProtocolTokenStats(token)
            if ( data ) {
                setStat(data)
            }
        }
        loadStats()
    },[])

    useEffect(() => {
        if ( ! protocolData || ! stat ) { return }

        if ( !! protocolData?.on_deposit ) {
            setDeposited(true)
        }
        if ( !! protocolData?.certified ) {
            setAudited(true)
        }
        if ( !! protocolData?.auditor && !! protocolData?.depository && !! stat?.issuer) {
            setRoles(true)
        }
        if ( stat.supply.split(' ')[0] <= stat.max_supply.split(' ')[0] ) {
            setHasCorrectSupply(true)
        }

    },[protocolData,stat])

    useEffect(()=> {
        if ( ! crossChain || ! protocolData ) { return }
        
        const parentBalance = crossChain.balances.depositoryDelegate * ( 10 ** -parseInt(crossChain.decimals) )

        if ( parentBalance >= parseFloat(protocolData.certified) ) {
            setValidCrossChain(true)
        }
        else {
            setValidCrossChain(false)
        }

    },[crossChain,protocolData])

    // Combine the results of all our checks...
    useEffect(() => {
        if ( deposited && audited && roles && validCrossChain && hasCrossChainReport ) {
            onVerify(true)
        } else {
            onVerify(false)
        }
    },[deposited,audited,roles,validCrossChain,hasCrossChainReport])



    if ( ! token ) { return ( <></> ) }

    const table = (
        <Table captionSide="bottom">
            <caption>The accounts filling the various protocol roles on this blockchain.</caption>
            <thead>
                <tr>
                    <th>Role</th>
                    <th>Account</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Issuer</td>
                    <td>{stat?.issuer}</td>
                </tr>
                <tr>
                    <td>Auditor</td>
                    <td>{protocolData?.auditor}</td>
                </tr>
                <tr>
                    <td>Depository</td>
                    <td>{protocolData?.depository}</td>
                </tr>
                <tr>
                    <td>Depository Delegate</td>
                    <td>{protocolData?.depository_delegate}</td>
                </tr>
            </tbody>
        </Table>
    )

    let checks = [
        {
            title: `${token.name} has confirmed assets on deposit.`,
            error: `${token.name} does not have confirmed assets on deposit.`,
            description: 'The LD2 protocol requires that all tokenized assets must be confirmed as deposited by the vault.',
            detail: `We checked for records of confirmed deposits on the ${token.contract} contract.`,
            note: false,
            status: deposited,
        },
        {
            title: `${token.name} has certified deposits.`,
            error: `${token.name} does not have certified deposits.`,
            description: 'The LD2 protocol requires that all tokenized assets must be certified by an independent auditor.',
            detail: `We checked for records of audited deposits on the ${token.contract} contract.`,
            note: false,
            status: audited,
        },
        {
            title: `${token.name} has only issued what is deposited and audited.`,
            error: `${token.name} issued more than what is deposited and audited.`,
            description: 'The LD2 protocol requires that tokens can only be issued up to the least of deposited and audited.',
            detail: `We checked the supply and max_supply stat records on the ${token.contract} contract, where max_supply is the lesser of deposited and certified.`,
            note: false,
            status: hasCorrectSupply,
        },
        {
            title: `${token.name} has assigned the required roles.`,
            error: `${token.name} has not assigned required roles.`,
            description: 'The LD2 protocol requires the assignment of various roles to fill specific tasks within the token issuance process.',
            detail: `We checked for records of the roles on the ${token.contract} contract. The following list documents these findings.`,
            note: false,
            status: roles,
            support: (
                <Grid pt='md'>
                    <Grid.Col xs={2}></Grid.Col>
                    <Grid.Col xs={10}>
                        {table}
                    </Grid.Col>
                </Grid>
            ),
        },
    ]

    const crossChainChecks = [ 
        {
            title: `${token.name} features cross-chain compliance on ${token.crossChainCompliance.chain}.`,
            error: `${token.name} features cross-chain compliance on ${token.crossChainCompliance.chain}, but records could not be found.`,
            description: 'The LD2 protocol allows assets to be managed cross-chain.',
            detail: `We check for published cross-chain compliance records.`,
            note: false,
            status: !! crossChain,
        },
        {
            title: `${token.name} publishes audit reports for ${token.crossChainCompliance.chain}-based issuance.`,
            error: `${token.name} does not publish audit reports for ${token.crossChainCompliance.chain} based issuance.`,
            description: 'The LD2 protocol requires that audit reports are published.',
            detail: `We check for published audit reports.`,
            note: false,
            status: hasCrossChainReport,
        },
        {
            title: `${token.name}'s parent blockchain shows required balance.`,
            error: `${token.name}'s parent blockchain does not show required balance.`,
            description: `The gross balance on the primary chain (${token.crossChainCompliance.chain}) must equal or exceed the issued, deposited, and audited assets on the secondary chain.`,
            detail: `We verify that the gross balance of the primary chain's secure storage wallet equals or exceeds the issued, deposited, and audited assets on the secondary chain.`,
            note: false,
            status: validCrossChain,
        },
    ]
   
    return (
        <>
            <Title mb='sm' mt='xl' pt='xl'  order={2} align='center'>{token.name} Protocol Compliance</Title>
            <Text align='center'>{token.description}</Text>
            <Group position='center'>
                <Text align='center'><strong>Contract:</strong> {token.contract}</Text>
                <Text align='center'><strong>Symbol:</strong> {token.symbol}</Text>
            </Group>
            {
                checks.map((c,i) => <StatusCheck {...c} key={i} /> )
            }

            {
                crossChain && protocolData
                ? (<>
                    <Title mt='xl' pt='xl' align='center' order={3}>Cross-Chain Compliance</Title>
                    <Text></Text>
                    { crossChainChecks.map((c,i) => <StatusCheck {...c} key={i} /> ) }
                </>) : ''
            }
        </>
    )
}

function VerifyBalances({deposits,backed,balances}) {
    if ( ! deposits || ! backed  || ! balances ) {
        return ( <></> )
    }

    const ths = (
        <tr>
            <th></th>
            <th>Deposits</th>
            <th>+</th>
            <th>Backed</th>
            <th>=</th>
            <th>Expected Balance</th>
            <th>Actual Balance</th>
        </tr>
    );

    let status = true
  
    const rows = tokens.map((t) => 
    {
        const sym = t.symbol
        const dep = Asset.from(deposits[sym])
        const bkd = Asset.from(backed[sym])
        const bal = Asset.from(balances[sym])

        const total = Asset.from(t.tokenTemplate)
        total.value += dep.value + bkd.value

        const balanced = ( total.value == bal.value )
        status = status && balanced

        return (
            <tr key={sym}>
                <td><Icon status={balanced} size={24} /></td>
                <td>{deposits[sym]}</td>
                <td></td>
                <td>{backed[sym]}</td>
                <td></td>
                <td>{total.toString()}</td>
                <td>{balances[sym]}</td>
            </tr>
        )
    });

    return (
        <>
            <Title mt='xl' pt='xl' order={2} align='center'>NFT Backers Accounting</Title>

            <StatusCheck 
                title={'NFT Backers passes the trial balance test'}
                description={'When looking at total balances across all of NFT Backers, unused deposits plus backing should always be greater than or equal to the current wallet balance of the nftbackers contract for all tokens.'}
                error={'NFT Backers does not pass the trial balance test'}
                detail={'We calculated the following numbers based off the current balances, deposits and backing on the NFT Backers contract.'}
                status={status}
                support={(
                    <Grid pt='md'>
                        <Grid.Col xs={2}></Grid.Col>
                        <Grid.Col xs={10}>
                            <Table captionSide="bottom">
                                <caption>Total of all current balances on NFT Backers</caption>
                                <thead>{ths}</thead>
                                <tbody>{rows}</tbody>
                            </Table>
                        </Grid.Col>
                    </Grid>
                )}
            />
        </>
    );
}

export default function VerifyBacking() {
    const { asset_id } = useParams()
    const [token,setToken] = useState<IToken>()

    const [config,setConfig] = useState<INFTBackerConfig>()
    const [backedCollections,setBackedCollections] = useState([] as any)
    const [balances,setBalances] = useState<any>()
    const [deposits,setDeposits] = useState<any>()
    const [backed,setBacked] = useState<any>()
    const [protocolStatus,setProtocolStatus] = useState<boolean>()

    const [deposited,setDeposited] = useState<Asset>()

    const [issued,setIssued] = useState()
    const [crossChain,setCrossChain] = useState()

    // useEffect(()=>{
    //     if ( ! symbol ) { return }
    //     const t = _.find(tokens,{symbol:symbol})
    //     if ( ! t ) { return }
    //     setToken(t)
    // },[symbol])

// TESTING

// web3.eth.getBalance("0x5A0b54D5dc17e0AadC383d2db43B0a0D3E029c4c", function(err, result) {
//     if (err) {
//       console.log(err)
//     } else {
//       console.log(web3.utils.fromWei(result, "ether") + " ETH")
//     }
//   })
  



    useEffect(()=>{
        const loadBalances = async () => {
            // Don't double load
            if ( balances ) { return }
            const newBalances = {}
            for ( const t of tokens ) {
                const b = await api.getTokenBalance('thenftbacker',t.symbol,t.contract)
                newBalances[t.symbol] = b
            }
            setBalances(newBalances)
        }
        loadBalances()
    },[])

    useEffect(()=>{
        const loadDeposits = async () => {
            // Don't double load
            if ( deposits ) { return }
            const newDeposits = {}
            for ( const t of tokens ) {
                newDeposits[t.symbol] = Asset.from(t.tokenTemplate)
            }
            const b = await api.getAllBalances()

            for ( const record of b ) {
                for ( const bal of record.quantities ) {
                    const a = Asset.from(bal)
                    // Update the balance
                    newDeposits[a.symbol.code.toString()].value += a.value
                }
            }
            for ( const t of Object.keys(newDeposits )) {
                newDeposits[t] = newDeposits[t].toString() 
            }
            setDeposits(newDeposits)
        }
        loadDeposits()
    },[])

    useEffect(()=>{
        const loadConfig = async () => {
            // Don't double load
            if ( config ) { return }

            const c = await api.getBackedConfig()
            if ( ! c ) { return }
            setConfig(c)
        }
        loadConfig()
    },[])

    useEffect(()=>{
        const loadBackedCollections = async () => {
            // Don't double load
            if ( backedCollections ) { return }

            const cols = await api.getBackedCollectionsFromContract(1000)
            if ( ! cols ) { return }
            setBackedCollections(cols)
        }
        loadBackedCollections()
    },[])

    useEffect(()=>{
        const loadBacked = async () => {
            // Don't double load
            if ( backed ) { return }

            const newBacked = await api.getAllBackedAssetBalances()
            for ( const t of Object.keys(newBacked)) {
                newBacked[t] = newBacked[t].toString()
            }
            setBacked(newBacked)
        }
        loadBacked()
    },[])

    // useEffect(()=>{
    //     const loadDeposits = async () => {
    //         if ( ! config || ! backedCollections || ! token ) { return }
    //         const newDeposited = Asset.from(token.tokenTemplate)
    //         const owners = backedCollections.map(bc=>bc.owner)
    //         for ( const owner of owners ) {
    //             const b = await api.getBalances(owner)
    //             for ( const bal of owner.quantities ) {
    //                 const balAsset = Asset.from(bal)
    //                 if ( balAsset.symbol.code.toString() != symbol ) {
    //                     continue
    //                 }
    //                 else { 
    //                     newDeposited.value += balAsset.value
    //                     break
    //                 }
    //             }
    //         }
    //         setDeposited(newDeposited)
    //     }
    //     loadDeposits()
    // },[config,backedCollections,token])

    // useEffect(()=>{
    //     const loadBacked = async () => {
    //         if ( ! config || ! backedCollections || ! token ) { return }
    //         const newBacked = Asset.from(token.tokenTemplate)
    //         const assets = await api.getBackedAssets({},1,1000)
    //         if ( ! assets ) { return }
    //         for ( const a of assets ) {
    //             for ( const bal of a.quantities ) {
    //                 const balAsset = Asset.from(bal)
    //                 if ( balAsset.symbol.code.toString() != symbol ) {
    //                     continue
    //                 }
    //                 else { 
    //                     newBacked.value += balAsset.value
    //                     break
    //                 }
    //             }
    //         }
    //         setDeposited(newBacked)
    //     }
    //     loadBacked()
    // },[config,backedCollections,token])


    const tabItems : ITabItem[] = [
        { 
            label: 'Protocol Verification',
            component: VerifyProtocolLayout,
            props: {
                onVerify:setProtocolStatus
            }, 
            icon: ChartArrowsVertical,
            visible: true,
        },
        { 
            label: 'Balance Verification',
            component: VerifyBalances,
            props: {
                deposits: deposits,
                backed: backed, 
                balances: balances,
            }, 
            icon: BuildingBank,
            visible: true,
        },
        
    ]    

    return (
        <Container px='xl' py='xl'>
            <Grid mb='xl'>
                <Grid.Col xs={12}>
                    <Title order={1}>Verify NFT Backing</Title>
                    <Text size='md'>
                        NFT Backers takes asset backing very seriously. We understand that NFT owners 
                        expect that their backed NFTs do in fact have the correct backing, and that
                        the NFTs remained backed until burned. This is why we developed the Backed NFT
                        2.0 Standard, and use <a href="https://ld2coin.io">the LD2 Protocol</a>. By 
                        relying on these important standards, we can verify and illustrate that the
                        backing is in place.
                    </Text>
                </Grid.Col>
            </Grid>
            { 
              asset_id
              ? <VerifyAsset asset_id={asset_id} protocolStatus={protocolStatus} />
              : ''
            }
            <TabbedContent items={tabItems} />
        </Container>
    )

}


export function VerifyProtocolLayout({onVerify}) {
    return (
        <>
            {
                tokens.filter(t=>t.ld2Compliant).map(t=><VerifyProtocol key={t.symbol} token={t} onVerify={onVerify} />)
            }
        </>
    )
}

/*
How do I know there's silver behind my NFT?

It helps to understand exactly HOW the silver is connected to your NFT.

The silver gets tokenized into fungible tokens:
- LD2 buys/manufactures silver and sends it to the vault/depository
 -The depository certifies the balance of silver on-chain.
- A third-party auditor inspects the vault and certifies the balance on-chain.
- LD2 can then issue tokens (e.g. ERC-20 and/or XLDZ on WAX)

You can verify this on our website:
https://ld2coin.io/vault-examinations/
https://ld2coin.io/cross-chain/

For the NFTs, LD2 utilizes a contract called NFT Backers.
- LD2 deposits XLDZ to NFT Backers
- NFT Backers attach the tokens directly to NFTs during minting

You can verify that NFT Backers has enough XLDZ on deposit to cover all minted assets:
https://wax.bloks.io/account/thenftbacker (Look at XLDZ balance)
https://bit.ly/3ab2EkD (To look up an individual NFT)

NFT Backers is soft-launching their UI very soon, simplifying the visualization of this backing. In the meantime, you can see exactly where the value comes from! 
*/