import { Space, Grid, Chip, Card, Image, Text, Badge, Button, Group, useMantineTheme, Anchor, Container } from '@mantine/core';
import { IAsset } from 'atomicassets/build/API/Explorer/Objects';
import { useCallback, useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { CardImage } from './CardImage';
import { IGenericCardProps } from './GenericCard';
import { Asset } from '@greymass/eosio';
import { tokens } from '../../../config/tokens';
import _ from 'lodash'
import BackedTokenAmount from '../BackedTokenAmount';
import { Shield, ShieldCheck } from 'tabler-icons-react';
import { UALContext } from 'ual-reactjs-renderer';
import { useModals } from '@mantine/modals';
import BurnAsset from '../../../lib/BurnAsset';

interface IAssetCardProps extends IGenericCardProps {
  item: IAsset;
}

export function BackedAssetCard({item, buttons, options, path} : IAssetCardProps) {

  const ual = useContext(UALContext) as any;
  const modals = useModals();

  const theme = useMantineTheme();
  const secondaryColor = theme.colorScheme === 'dark'
  ? theme.colors.dark[1]
  : theme.colors.gray[7];

  if ( ! buttons ) { buttons = [] }
  if ( ! options ) { options = {} }

  const toPath = path + item.asset_id

  const image =  (
    <CardImage
      src={item.data.img}
      alt={item.data.name}
      onClick={()=>{}} />
  )

  const confirmRedeemModal = () => modals.openConfirmModal({
      title: 'Burn this NFT?',
      centered: true,
      children: (
          <Container>
              <Text size="sm">
                  <p><strong>Are you sure you want to burn (destroy) this NFT?</strong></p>
                  <p>
                      Burning will permenently destroy your NFT, and unlock the 
                      backed assets in the process. These tokens will be transferred
                      to your wallet.
                  </p>
              </Text>
          </Container>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onCancel: () => console.log('Cancel'),
      onConfirm: onBurnAsset,
  });

  const onBurnAsset = async () => {
    await BurnAsset(item, ual)
  }

  return (
    <Card radius="lg" shadow={options.shadow} p="lg">

      <Card.Section>
          <Group position="center">
              { options.nolink ?
                  image
                  :   <Anchor component={Link} to={toPath}>
                        {image}
                      </Anchor>
              }
          </Group>
      </Card.Section>

      <Group position='apart'>
        <div>
          <Group position='left' style={{ marginBottom:0, marginTop: 0 }}>
            <Text align='left' pt='xl' pb={0} px='md' size='xs' weight={700} color={theme.colors.dark[3]}>{item.collection.name}</Text> 
          </Group>
          <Group position='left' style={{ marginBottom: theme.spacing.md, marginTop: 0 }}>
            <Text align='left' pt={0} px='md' size='xs' weight={700} color={theme.colors.dark[5]}>{item.name}</Text> 
          </Group>
        </div>
        <div>
          
          <Text size='xs' align='right'>
            Mint
          </Text>
          <Text size='sm' weight={700} align='right'>{item.template_mint}</Text>
        </div>

      </Group>

      { item.backed_tokens.length > 0 ?
        <Group position='center' mt='md'>
          <ShieldCheck color={secondaryColor} size={18} />
          <Text color={secondaryColor} size='sm' weight={700} align='center'>Backed by</Text>
        </Group>
        :
        <Group position='center' mt='md'>
          <ShieldCheck color={secondaryColor} size={18} />
          <Text color={secondaryColor} size='sm' weight={700} align='center'>Backed by NFT Backers</Text>
        </Group>
      }
      <Group mb='xl' mt='xs' position="center">
        {
          item.backed_tokens.map((t) => {
            return (
              <BackedTokenAmount backed_token={t} key={t.token_symbol} />
            )
          })
        }
      </Group>

      {
        ( ! options.hideButtons ) 
        ?
          <Group my="lg" position="center">
            {
              item.immutable_data.redeem_url ?
              <Button component='a' href={item.immutable_data.redeem_url}>Redeem</Button>
              :<></>
            }
            <Button component={NavLink} to={`/explorer/asset/${item.asset_id}`}>Details</Button>
            { 
              ( item.owner == ual.activeUser?.accountName )
              ?
              (
                item.data.redeem_url
                ? <Button component='a' target='_blank' href={item.data.redeem_url}>Redeem</Button>
                : <Button onClick={confirmRedeemModal}>Redeem</Button>
              ) : ''
            }
          </Group>
        : ''
      }

      {
        buttons.map((b,i) => {
          return (
            <Button key={i} leftIcon={b.icon} variant="light" color="blue" fullWidth style={{ marginTop: 14 }} onClick={b.onClick}>
              {b.label}
            </Button>
          )
        })
      }
    </Card>    

  )
}