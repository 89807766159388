import { useContext } from 'react';
import { UALContext } from 'ual-reactjs-renderer';

import { showNotification, updateNotification } from '@mantine/notifications';
import { Flame, AlertTriangle, Check, BuildingBank } from 'tabler-icons-react';

export default async function DepositTokens(asset, token, ual) {

    const auth = [{
        actor: ual.activeUser.accountName,
        permission: ual.activeUser.requestPermission,
    }]

    let actions : any = []

    actions.push({
        account: process.env.REACT_APP_NFTBACKER_CONTRACT,
        name: 'announcedepo',
        authorization: auth,
        data: {
            owner: ual.activeUser.accountName,
            symbol_to_announce: asset.symbol.toString(),
        }
    });

    actions.push({
        account: token.contract,
        name: 'transfer',
        authorization: auth,
        data: {
            from: ual.activeUser.accountName,
            to: process.env.REACT_APP_NFTBACKER_CONTRACT,
            quantity: asset.toString(),
            memo: 'deposit'
        }
    });

    try {
        showNotification({
            id: `deposit-tokens-${asset.symbol.code.toString()}`,
            loading: true,
            title: `Depositing ${asset.symbol.code.toString()}`,
            message: 'Preparing to deposited tokens for backing.',
            autoClose: false,
            disallowClose: true,
            icon: <BuildingBank />,
        });

        const res = await ual.activeUser.signTransaction(
            {
                actions: actions
            },
            {
                blocksBehind: 3,
                expireSeconds: 60
            }
        );
        updateNotification({
            id: `deposit-tokens-${asset.symbol.code.toString()}`,
            color: 'teal',
            title: `Success!`,
            message: `The deposit of ${asset.symbol.code.toString()} is complete.`,
            icon: <Check />,
            autoClose: 10000,
        });
    } catch (e : any ) {
        console.log(e);

        const msg = e.message.replace('assertion failure with message:','');

        updateNotification({
            id: `deposit-tokens-${asset.symbol.code.toString()}`,
            color: 'red',
            title: `Unable to Complete Deposit`,
            message: msg,
            icon: <AlertTriangle />,
            autoClose: false,
        });
    }

}