import { Card, Text, Button, Group, useMantineTheme, FloatingTooltip } from '@mantine/core';
import { ISchema, ISchemaStats } from 'atomicassets/build/API/Explorer/Objects';
import { useContext, useEffect, useState } from "react";
import { AAMiddleware } from '../../../store/AAMiddleware';
import { UALContext } from "ual-reactjs-renderer";
import _ from 'lodash'
import { ShieldCheck } from 'tabler-icons-react';

const api = new AAMiddleware()

export interface ISchemaCardProps {
  item: ISchema;
  buttons?: any;
  options?: any;
  path: string;
}

export function SchemaCard({item, buttons, options, path} : ISchemaCardProps) {
    const theme = useMantineTheme();
    const ual = useContext(UALContext) as any

    const [stats,setStats] = useState<ISchemaStats>()

    const secondaryColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[1]
      : theme.colors.gray[7];

    const accentColor = theme.colorScheme === 'dark'
      ? theme.colors.blue[0]
      : theme.colors.blue[5];

    const lightTextColor = theme.colorScheme === 'dark'
      ? theme.colors.gray[1]
      : theme.colors.gray[7];
    

    if ( ! buttons ) { buttons = [] }
    if ( ! options ) { options = {} }

    useEffect(() => {
      const loadSchemaStats = async () => {
        if ( ! item.schema_name || ! item.collection ) { return }
        setStats( await api.aaapi.getSchemaStats(item.collection.collection_name, item.schema_name))
      }
      loadSchemaStats()
    },[])

    const backedIcon = _.find(item.format,(f)=>f.name == 'backedby') ? 
      (
        <FloatingTooltip label='NFT Backers supported schema'>
          <ShieldCheck size={16} color={accentColor} />
        </FloatingTooltip>
      ) : ''
  
    return (
      <Card radius="lg" shadow={options.shadow} p="lg"> 
        <Group position='apart'>
          <div>
            <Group position='left' style={{ marginBottom:0, marginTop: 0 }}>
              <Text align='left' pt='xl' pb={0} px='md' size='md' weight={700} color={theme.colors.dark[5]}>{item.schema_name} {backedIcon}</Text> 
            </Group>
            <Group position='left' style={{ marginBottom: theme.spacing.md, marginTop: 0 }}>
              <Text
                style={{
                  overflow: 'hidden',
                  maxWidth: '25ch',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }} 
                align='left' pt={0} px='md' size='xs' weight={700} color={theme.colors.dark[3]}>{item.collection.name}</Text> 
            </Group>
          </div>
          <div>
            <Text size='xs' align='right'>
              Templates
            </Text>
            <Text size='sm' weight={700} align='right'>{stats?.templates ?? 0}</Text>
          </div>

        </Group>
  
        {
          buttons.map((b,i) => {
            return (
              <Button key={i} leftIcon={b.icon} variant="light" color="blue" fullWidth style={{ marginTop: 14 }} onClick={b.onClick}>
                {b.label}
              </Button>
            )
          })
        }
      </Card>    
  
    )
  }