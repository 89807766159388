import { Avatar, Button, Card, Checkbox, Container, Group, Select, Text, Title } from "@mantine/core"
import { forwardRef, useContext, useEffect, useState } from "react";
import { TabbedContent } from "../../../../../components/TabbedContent"

import { tokens, IToken } from "../../../../../config/tokens"
import Crowdsale from "../../../Crowdsale/Crowdsale";
import _ from 'lodash'
import { Api, Database, DatabaseImport } from "tabler-icons-react";
import { Balances } from "../Balances/Balances";
import { UALContext } from 'ual-reactjs-renderer';
import EnableCollectionNotifications from "../../../../../lib/EnableCollectionNotifications";

import { AAMiddleware } from '../../../../../store/AAMiddleware'
const api = new AAMiddleware()


interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    image: string;
    label: string;
    description: string;
}

export default function ConfigureCollection({collection,onConfigured}) {

    const [agreed,setAgreed] = useState(false)
    const [configured,setConfigured] = useState(false)
    const [ready,setReady] = useState(false)

    const ual = useContext(UALContext) as any;

    useEffect(()=>{
        const checkConfigured = async () => {
            const isConfigured = await api.isCollectionConfigured(collection)
            setConfigured( isConfigured )
            onConfigured(isConfigured)
            setReady(true)
        }
        checkConfigured()
    },[])

    const enrollCollection = async () => {
        const res = await EnableCollectionNotifications(collection, ual)
        console.log(res)
        if ( res ) { onConfigured(true) }
    }

    if ( ! ready ) {
        return ( <></> )
    }

    if ( configured ) {
        return (
            <Container className="pt-5">
                <Card my='xl' px='xl'>
                    <Title order={3}>Ready to Go!</Title>
                    <Text>Congratulations! This collection is configured and ready to issue value-backed NFTs.
                        The next step will walk you through creating an asset-backed template.
                    </Text>
                </Card>
            </Container>            
        )
    }

    return (
        <Container className="pt-5">
            <Card my='xl' px='xl'>
                <Title order={3}>Configure Your Collection</Title>
                <Text>By enrolling your collection as an NFT Backer, you are joining
                    other creators who communities trust to add real world value to
                    NFTs. That is a trust you are agree to maintain. <strong>It is very
                        important that you understand everything below before continuing.
                    </strong>
                </Text>
                <Text pt='xl'>In order to attach backing to assets you mint, and properly deliver
                    the backing when the asset is burned, <strong>we need to receive contract
                    notifications from your collection.</strong> The NFT Backer contract will 
                    listen for MINT and BURN events.</Text>
                <Title pt='xl' order={4}>Minting</Title>
                <Text>When new assets are minted, we pay attention only to templates which have 
                    the NFT Backer attribute(s) properly configured. For those qualifying templates,
                    we will automatically attach the backing, pulling from the tokens you have
                    deposited with us.                    
                </Text>
                <Text pt='xl'><em><strong>IMPORTANT!</strong> You must keep contract notifications in
                    place for the entire time your backed templates are available to mint. If 
                    you don't, you may be minting unbacked assets, something that can seriously undermine
                    the faith your community places in you as an NFT Backer.</em>
                </Text>

                <Title pt='xl' order={4}>Burning</Title>
                <Text>
                    When backed assets are burned, the backing is delivered to the account
                    that burned the asset. With contract notifications enabled, this happens
                    automatically. If contract notifications are somehow disabled, NFT Backer
                    will still deliver the backing, but added steps may be required which may 
                    delay delivery.
                </Text>
                <Text pt='xl'>We strongly encourage using NFT Backers to burn the assets, we 
                can make certain the backed value is properly transferred to the owner. <strong>Do
                not use backed assets in blending,</strong> as the blending contract would 
                typically end up with the backed value, not the owner. There are ways to work
                around this. Contact us for more information.</Text>

                <Title pt='xl' order={4}>Deposits</Title>
                <Text>Because NFT Backers automatically attaches backing to minted assets,
                    we require you to deposit the backing in order to mint. Our contract will
                    block minting if your deposited balance isn't enough to cover the specified
                    backing. This is to ensure that all NFT Backer assets are fully backed,
                    and protect the asset minter. <em>Maintain the trust of your commmunity by
                    making sure you always have enough backing on deposit as you will need for
                    minting.
                    </em>
                </Text>
                <Text pt='xl'><strong>Any unused backing can be withdrawn from the contract whenever
                    you wish.</strong>
                </Text>

                <Title pt='xl' order={4}>Get Started</Title>
                <Text>By checking the box below, you signify that you have read and understand
                    everything above. Continuing will add NFT Backers to your collection's 
                    notifications.</Text>
                    <Group position='center'>
                    <Checkbox
                        size='lg'
                        my='xl'
                        checked={agreed}
                        onChange={(e) => setAgreed(e.currentTarget.checked)} 
                        label='I understand. Add NFT Backers to my collection notifications.'
                    />
                </Group>
                
                <Group position='center' my='xl'>
                    <Button onClick={enrollCollection} size='lg' disabled={!agreed}>Enroll Collection</Button>
                </Group>
                
            </Card>
        </Container>        
    )
}