import React, { useCallback, useContext } from 'react';
import { UALContext } from 'ual-reactjs-renderer';

import {useNavigate} from 'react-router-dom';

import { logoMap } from '../Blockchains/Blockchains'

import { ChevronRight, ChevronLeft, UserCircle } from 'tabler-icons-react';
import { UnstyledButton, Group, Avatar, Text, Box, useMantineTheme } from '@mantine/core';

export function User({onClose}) {

  const theme = useMantineTheme();
  const navigate = useNavigate();
  const ual = useContext(UALContext) as any;

  const handleOnClick = useCallback(() => {
    navigate('/account', {replace: false})
    onClose();
  }, [navigate]);

  if ( ! ual.activeUser || ! ual.activeAuthenticator ) {
    return ( <></> )
  }

  return (
    <Box
      sx={{
        paddingTop: theme.spacing.sm,
        borderTop: `1px solid ${
          theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }`,
      }}
    >
      <UnstyledButton
        onClick={handleOnClick}
        sx={{
          display: 'block',
          width: '100%',
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

          '&:hover': {
            backgroundColor:
              theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
          },
        }}
      >
        <Group>
          <Avatar
            // src={logoMap[ual.activeAuthenticator.chainId]}
            src={ual.activeAuthenticator.getStyle().icon}
            radius="xl"
          />
          <Box sx={{ flex: 1 }}>
            <Text size="sm" weight={500}>
              {ual.activeUser.accountName} ({ual.activeAuthenticator.getName()})
              {/* Amy Horsefighter */}
            </Text>
            {/* <Text color="dimmed" size="xs">
              ahorsefighter@gmail.com
            </Text> */}
          </Box>

          {theme.dir === 'ltr' ? <ChevronRight size={18} /> : <ChevronLeft size={18} />}
        </Group>
      </UnstyledButton>
    </Box>
  );
}