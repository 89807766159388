import { Anchor, Group, ScrollArea, Table, Text } from "@mantine/core"

import { ILog } from 'atomicassets/build/API/Explorer/Objects';
import Moment from "react-moment";
import { ArrowRight, ArrowsLeftRight, BuildingBank, CirclePlus, Flame, Receipt, Replace } from "tabler-icons-react";


interface ILogTableProps {
    logs: Array<ILog> | undefined;
}

const iconSize = 14

const logIcons = {
    logmint: <CirclePlus size={iconSize} />,
    logburnasset: <Flame size={iconSize} />,
    logtransfer: <ArrowsLeftRight size={iconSize} />,
    lognewoffer: <Receipt size={iconSize} />,
    logsetdata: <Replace size={iconSize} />,
    logbackasset: <BuildingBank size={iconSize} />,
}

const formatData = (log:ILog) => {
    let val;
    switch (log.name) {
        case 'logmint':
            val = (
                <Group>
                    {log.data.authorized_minter}
                    <ArrowRight size={iconSize} />
                    {log.data.new_asset_owner}
                </Group>
            )
            break;
        case 'logburnasset':
            val = (
                <Group>
                    {log.data.asset_owner}
                </Group>
            )
            break;
        case 'logtransfer':
            val = (
                <Group>
                    {log.data.from}
                    <ArrowRight size={iconSize} />
                    {log.data.to}
                </Group>
            )
            break;
        case 'lognewoffer':
            val = (
                <Group>
                    {log.data.from}
                    <ArrowRight size={iconSize} />
                    {log.data.to}
                </Group>
            )
            break;
        case 'logsetdata':
            val = (
                <Group>
                    {'Attribute(s) changed'}
                </Group>
            )
            break;
        case 'logbackasset':
            val = (
                <Group>
                    {log.data.asset_owner + ' added ' + log.data.backed_token}
                </Group>
            )
            break;            
        default:
            val = (
                <Group>
                    {log.data[Object.keys(log.data)[0]]}
                </Group>
            )
        break;
    }
    return val;

}

export default function LogTable(props:ILogTableProps) {
    const { logs } = props
    
    if ( ! logs ) { return <></> }

    return (
        <ScrollArea>
            <Table  verticalSpacing="xs" horizontalSpacing="xs" fontSize='xs'>
            <thead>
                <tr>
                    <th>Event</th>
                    <th>Description</th>
                    <th>Date</th>
                </tr>
            </thead>
            <tbody>
            {
                logs.map((row) => {
                    return (
                        <tr key={row.log_id}>
                            <td>
                                <Group >
                                    {logIcons[row.name]}
                                    {row.name}
                                </Group>
                            </td>
                            <td>{formatData(row)}</td>
                            <td>
                                <Anchor<'a'> size="xs" href={"https://wax.bloks.io/transaction/" + row.txid}>
                                    <Moment fromNow>{new Date(parseInt(row.created_at_time))}</Moment>                                                    
                                </Anchor>
                            </td>
                        </tr>
                    )
                })
            }
            </tbody>
            </Table>
        </ScrollArea>
    )

}