const ipfsBase = process.env.REACT_APP_IMAGE_PROXY

export function resolveUrl(url:string, params?:string, original?:boolean) : string {
    let newUrl = '';

    if ( ! url ) { return '' }

    if ( url.startsWith('http') ) { newUrl = url }
    else if ( url.startsWith('Qm') || url.length >= 46 ) { newUrl = ipfsBase + url }

    if ( params ) { newUrl += '?' + params + '&f=webp&q=90' }

    return newUrl
}

export function hashOnly(path:string) {
    if ( ! path || path.length < 46 ) { return }
    const idx = path.indexOf('Qm')
    if ( idx == -1 ) { return }
    return path.substring( idx, idx+46)
}
