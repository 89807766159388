import { forwardRef, useContext, useEffect, useState } from 'react';
import './Crowdsale.css'

import { UALContext } from "ual-reactjs-renderer";
import { Asset } from '@greymass/eosio';

import { Container, Progress, Button, Alert, Text, SimpleGrid, Grid, Input, Slider, Select, Group, Avatar, Tabs, Card, Title } from '@mantine/core'
import { AlertTriangle, InfoCircle } from 'tabler-icons-react';
import { useModals } from '@mantine/modals';
import _ from 'lodash'

import { rpc } from '../../../index'
import { tokens, IToken } from '../../../config/tokens';

import { AAMiddleware } from '../../../store/AAMiddleware';
import { NavLink } from 'react-router-dom';
import { TabbedContent } from '../../../components/TabbedContent';
import { Balances } from '../User/Main/Balances/Balances';
import { BalanceCard } from '../../../components/NFT/cards/BalanceCard';
const api = new AAMiddleware()

interface RpcRequest {
    json: boolean;
    code: string;
    scope: string;
    table: string;
    limit: any;
    reverse?: boolean;
    show_payer?: boolean;
    lower_bound?: any;
    upper_bound?: any;
}

const get_table_rows = async (code, scope, table, lower?, upper?, limit?) => {
    // If no limit is set, assume 50
    if (!limit) {
        limit = 50;
    }

    let request : RpcRequest = {
        json: true,               // Get the response as json
        code: code,               // Contract that we target
        scope: scope,             // Account that owns the data
        table: table,             // Table name
        limit: limit,             // Maximum number of rows that we want to get PER REQUEST PAGE
        reverse: false,           // Optional: Get reversed data
        show_payer: false         // Optional: Show ram payer
    };

    if (lower) {
        request.lower_bound = lower;
    }
    if (upper) {
        request.upper_bound = upper;
    }

    let rows = [] as any;

    while (true) {
        let res = await rpc.get_table_rows(request);

        rows = rows.concat(res.rows);

        if (res.more) {
            request.lower_bound = res.next_key;
        }
        else {
            break;
        }
    }
    return rows;
}

const getCrowdsaleInfo = async (token : IToken) => {

    const code = token.contract
    const scope = token.contract
    const table = token.dataTable
    const id = token.symbol

    // Query the data table for the single id (both lower and upper bound)

    let rows = await get_table_rows(code, scope, table, id, id);

    if (rows && rows.length) {
        return rows[0];
    }

    else {
        // TODO: This is technically an error as the drop doesn't exist
        // Consider throwing an exception if this is important
        return {};
    }
}

const getPriceOracle = async () => {

    const code = 'delphioracle';
    const scope = 'waxpusd';
    const table = 'datapoints';

    // Query the data table for the single id (both lower and upper bound)

    let rows = await get_table_rows(code, scope, table);

    if (rows && rows.length) {
        return rows[0];
    }

    else {
        console.log('Failed to get rate from delphioracle')
        // TODO: This is technically an error as the drop doesn't exist
        // Consider throwing an exception if this is important
        return {};
    }
}


interface ICrowdsaleState {
    fee: number;
    rate: number;
    maxIssuance: number;
    remaining: number;
    progress: number;
    currency: number;
    tokens: number;
    minSpend: number;
    maxSpend: number;
}

const Crowdsale = ({symbol}) => {

    const ual = useContext(UALContext) as any;
    const modals = useModals()

    const [state, setState] = useState<ICrowdsaleState>({fee:0,rate:0,maxIssuance:0,remaining:0,progress:0, currency:0, tokens:0, minSpend:1, maxSpend:100});
    const [alertState, setAlertState] = useState({type:'danger',msg:'',show:false});
    const [lastTxn, setLastTxn] = useState();
    const [token, setToken] = useState<IToken>(_.find(tokens, t=>t.symbol==symbol))
    const [updateCount,setUpdateCount] = useState(0)
    const [balance,setBalance] = useState('')

    const bloksUrl = 'https://wax.bloks.io';

    let user = (ual?.activeUser && ual?.activeUser.accountName) ? ual?.activeUser.accountName : '';

    let unitLabel = token.unitLabel;

    if ( ! unitLabel ) { unitLabel = 'tokens'; }

    useEffect(()=> {
        const loadBalance = async () => {
            if ( ! token ) { return }
            if ( ! ual.activeUser || ! ual.activeUser.accountName ) { return }
            const newBalances = await api.getAvailableBalances([token], ual.activeUser.accountName)
            setBalance(newBalances[0].total.toString())
        }
        loadBalance()
    },[ual.activeUser,token,lastTxn,updateCount])

    useEffect(()=>{
        // Only show the modal if there is an alert
        if ( ! alertState.show ) { return }

        let newAlertState = Object.assign({},alertState);

        const handleClose = () => { newAlertState.show = false; setAlertState(newAlertState);};
        const msg = alertState.msg.replace('Error: assertion failure with message: ','');
        const content = <Text>{msg}</Text>

        showModal(content, handleClose)
    },[alertState])

    // Set our price oracle update interval
    // EVERY 30 Seconds
    useEffect(() => {
        const interval = setInterval(() => {
          setUpdateCount(updateCount => updateCount + 1);
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    const showModal = (content, onClose) => {
        const title = (alertState.type == 'danger') ? 'Oops... There was a problem.' : 'For your information...'
        const color = (alertState.type == 'danger') ? 'red' : 'blue'
        const icon = (alertState.type == 'danger') ? <AlertTriangle size={64} /> : <InfoCircle size={64} />

        const id = modals.openModal({
          title: <Text weight={700}>{title}</Text>,
          children: (
            <>
              <Group position='center'>
                  {icon}
                  {content}
              </Group>
              <Button color={color} fullWidth onClick={() => { modals.closeModal(id); onClose(); }} mt="md">
                OK
              </Button>
            </>
          ),
        });
        
    }

    let calcLocked = false;
    
    const calcCurrency = async (e) => {
        if ( calcLocked ) { return; }

        let tokens = parseFloat(e);
        if ( ! tokens || tokens <= 0 ) { e.preventDefault(); return; }

        calcLocked = true;

        let amt = currencyFromTokens(tokens);

        let newState = Object.assign({},state);
        newState['currency'] = amt;
        newState['tokens'] = tokens;
        setState(newState);
        calcLocked = false;
    }
    
    const calcTokens = (e) => {
        if ( calcLocked ) { return; }

        let currency = parseFloat(e.target.value);
        if ( ! currency || currency <= 0 ) { e.preventDefault(); return; }

        calcLocked = true;
    
        let amt = tokensFromCurrency(currency);

        let newState = Object.assign({},state);
        newState['currency'] = currency;
        newState['tokens'] = amt;
        setState(newState);
        calcLocked = false;
    }

    const tokensFromCurrency = (currency, rate?, fee?) => {
        if ( ! rate ) { rate = state.rate; }
        if ( ! fee ) { fee = state.fee; }
        return Math.floor( Math.pow(10,4) * currency * state.rate / state.fee ) / Math.pow(10,4);
    }

    const currencyFromTokens = (tokens, rate?, fee?) => {
        if ( ! rate ) { rate = state.rate; }
        if ( ! fee ) { fee = state.fee; }
        return Math.ceil( Math.pow(10,4) * tokens * state.fee / state.rate ) / Math.pow(10,4);
    }

    const clickBuy = async () => {
        // Get the currency amount to pay
        let asset = Asset.from(token.currencyTemplate);
        asset.value = state.currency;

        let actions = [{
                account: 'eosio.token',
                name: 'transfer',
                authorization: [{
                actor: ual.activeUser.accountName,
                permission: ual.activeUser.requestPermission,
            }],
            data: {
                from: ual.activeUser.accountName,
                to: token.contract,
                quantity: asset.toString(),
                memo: token.symbol
            }
        }];

        try {
            const res = await ual.activeUser.signTransaction(
                {
                    actions: actions
                },
                {
                    blocksBehind: 3,
                    expireSeconds: 30
                }
            );
            setLastTxn(res);
        } catch (e : any) {
            console.log(e);
            setAlertState({type:'danger',msg: e.toString(),show:true});
            // If you want display the message to the user, remove the 'assertion failure' part of the error message:
            // message.textContent = e.message.replace('assertion failure with message:','');
            // Then display the message where you like
        }

    }

    // const scrollToBuy = () => {
    //     document.getElementById("buy-now").scrollIntoView();
    // }

    // const scrollToAdd = () => {
    //     document.getElementById("add-token").scrollIntoView();
    // }

    const capitalize = (s:string) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    useEffect(() => {

        getCrowdsaleInfo(token).then(res => {
            const asset = Asset.from(res.certified);
            let certifiedBalance = Math.floor(asset.value);
            let certified = Math.floor(asset.value).toString() + ' ' + asset.symbol.code;

            let fee = 0;

            if ( res.eos_per_token ) {
                let feeAsset = Asset.from(res.eos_per_token);
                fee = Number(feeAsset.value);
            }
            else {
                console.log('Fee is not set on contract!')
            }

            rpc.get_currency_balance(token.contract, token.contract, token.symbol).then((balance) => {
                if ( balance.length ) {
                    const asset = Asset.from(balance[0]);
                    let remaining = asset.value;
                    let progress = Math.floor(100 * remaining / certifiedBalance);
                    getPriceOracle().then(res => {
                        let rateAmt = (res.median / Math.pow(10,4))
                        let rate = (res.median / Math.pow(10,4)).toFixed(4);


                        let newState : ICrowdsaleState = {
                            maxIssuance: parseInt(certified),
                            fee: fee,
                            remaining: remaining,
                            progress: progress,
                            rate: parseFloat(rate),
                            tokens: 1,
                            minSpend: 1,
                            maxSpend: 100,
                            currency: 0 // we will calculate this below
                        };

                        newState.currency = Math.floor( Math.pow(10,4) * newState.tokens * newState.fee / rateAmt ) / Math.pow(10,4);

                        // Set the state now, as the next step is optional (e.g. they may not be logged in)
                        setState(newState);

                        if ( user ) {
                            rpc.get_currency_balance(token.contract, user, token.symbol).then((balance) => {
                                if ( balance.length ) {
                                    const asset = Asset.from(balance[0]);
                                    let maxSpend = asset.value;
                                    newState.maxSpend = maxSpend;
                                    setState(newState);
                                }
                            });
                        }

                    }).catch(error => console.log(error));
                }
            });
        }).catch(error => console.log(error));            
    }, [rpc, user, lastTxn, updateCount]);

    let loggedInContent;
    let click = () => ual.showModal();

    // Only allow sale to be displayed if all of these are true
    let allowSale = ( ual.activeUser && ual.activeUser.accountName && state.fee && state.rate && state.remaining >= 0 );

    if ( allowSale ) {

        loggedInContent = (
            <Container mt='xl' fluid className="px-0">
                <Container fluid >
                            <Container className="pt-5">
                                <Grid gutter='xl'>
                                    <Grid.Col sm={12} md={6}>
                                        <p className="text-center">{capitalize(unitLabel)} to Buy</p>
                                        <Input size='xl' className="form-control form-control-lg calc-field text-center" type="number" value={state.tokens} id="tokens" name="tokens" onChange={(e) => calcCurrency(e)} />
                                        <div className="text-center accent strong"><label htmlFor="tokens">{token.symbol}</label></div>
                                    </Grid.Col>
                                    <Grid.Col sm={12} md={6}>
                                        <p className="text-center">{token.currencySymbol} to Spend</p>
                                        <Input size='xl' className="form-control form-control-lg calc-field text-center" type="number" id="currency" name="currency" value={state.currency} onChange={(e) => calcTokens(e)} />
                                        <div className="text-center accent strong"><label htmlFor="currency">{token.currencySymbol}</label></div>
                                    </Grid.Col>
                                </Grid>
                                <Grid justify='center'>
                                    <Grid.Col md={8}>
                                        <Slider mb='xl' mt='sm' size="xl" id="to-spend-range" value={state.tokens} min={state.minSpend} max={state.maxSpend} step={1} onChange={(e) => calcCurrency(e)} />
                                    </Grid.Col>
                                    <Grid.Col md={12}>
                                        <Group position='center'>
                                            <Button mt='xl' size="lg" onClick={clickBuy}>Purchase Now <i className="fa fa-check"></i></Button>
                                        </Group>
                                    </Grid.Col>
                                </Grid>
                            </Container>
                </Container>
            </Container>
        );
    }

    return (
        <Container py='md'>
                <Title order={3} align='center' my='xl' pb='xl'>{token.description}</Title>
                <Grid>
                    <Grid.Col sm={12} md={6}>
                        <Title order={4}>Limited Supply!</Title>
                        <p>Only {state.maxIssuance} {unitLabel} total are available in this issuance, and {Math.round(state.remaining)} remain.</p>
                        <Progress size='xl' value={state.progress} />
                        <p className="text-right accent strong">{state.maxIssuance} Hard Cap</p>
                    </Grid.Col>
                    <Grid.Col sm={12} md={6}>
                        <p>The exchange rate for the crowdsale is calculated using an exchange rate maintained 
                        by price oracles (<a href={bloksUrl + '/account/delphioracle'}>delphioracle</a>). 
                        The current rates used for the exchange rate are:</p>
                    
                        <Card>
                            <Title order={3} align='center' className="accent">{token.priceSymbol}{state.fee}&nbsp;{token.priceUnit} / {token.symbol}</Title>
                            <Title order={3} align='center'  className="accent">{token.priceSymbol}{state.rate}&nbsp;{token.priceUnit} / {token.currencySymbol}</Title>
                        </Card>
                    </Grid.Col>
                </Grid>
            {loggedInContent}
        </Container>
    )
}

export default Crowdsale