import { useEffect, useState } from 'react';
import { Stepper, Button, Group, Container, Title, Text, Card, InputWrapper, TextInput, Grid, Image, Skeleton, Avatar, useMantineTheme, Stack } from '@mantine/core';
import { NavLink, useParams } from 'react-router-dom';
import { AAMiddleware } from '../../../store/AAMiddleware';
import { ICollection, ISchema, ITemplate } from 'atomicassets/build/API/Explorer/Objects';
import InfiniteDataProvider, { configs, IGridConfig } from '../../../providers/InfiniteItemProvider';
import { InfiniteItemGrid } from '../../../components/NFT/InfiniteItemGrid';
import ImageUpload from '../../../components/ImageUpload';

import { resolveUrl } from '../../../lib/ImageHelper';

import _ from 'lodash'
import CreateTemplateForm from './Creator/CreateTemplateForm';
import InlineEdit from '../../../components/controls/InlineEdit';

const api = new AAMiddleware()

const imageConfig = { fullHeight: 300, thumbHeight: 40 }

export function CreateTemplate() {

    const theme = useMantineTheme();

    const { name } = useParams()

    // Data management
    const [collection,setCollection] = useState<ICollection>()
    const [schemas,setSchemas] = useState<Array<ISchema>>()
    const [selectedSchema,setSelectedSchema] = useState<ISchema>()
    const [filters,setFilters] = useState<Array<any>>([])

    // // Form management
    // const [uploadedImages,setUploadedImages] = useState({} as any)
    // const [mainImage,setMainImage] = useState('')
    // const [selectedImage,setSelectedImage] = useState(0)
    // const [values,setValues] = useState({} as any)

    // const [title,setTitle] = useState('Default Title')

    // Tab management
    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    const onNextStep = () => {
        window.scrollTo(0,0)
        nextStep()        
    }

    const secondaryColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[1]
      : theme.colors.gray[7];

    const accentColor = theme.colorScheme === 'dark'
      ? theme.colors.blue[0]
      : theme.colors.blue[5];

      const lightTextColor = theme.colorScheme === 'dark'
      ? theme.colors.gray[1]
      : theme.colors.gray[7];

      const lighterTextColor = theme.colorScheme === 'dark'
      ? theme.colors.gray[3]
      : theme.colors.gray[5];    

    useEffect(()=>{
        const loadCollection = async () => {
            if ( ! name ) { return }
            const col = await api.getCollection(name)
            setCollection(col)
        }
        loadCollection()
    },[name])

    useEffect(()=>{
        const loadSchemas = async () => {
            if ( ! name ) { return }
            const newSchemas = await api.getBackedSchemas({collection_name:name})
            setSchemas(newSchemas)
        }
        loadSchemas()
    },[name])

    useEffect( () => {
        async function fetchColFilters() {
            if ( ! name ) { return }
            const newFilters = await api.getColFilters(name)
            setFilters(newFilters)
        }
        fetchColFilters();
    }, [name]);

    const handleSchemaSelect = (e, schema_name ) => {
        const newSchema = _.find(schemas,{schema_name:schema_name})
        if ( newSchema ) {
            setSelectedSchema(newSchema)
            nextStep()
        }
    }

    const backedSchema = _.cloneDeep(configs.backedSchema) as IGridConfig
    backedSchema.grid.buttons = [
        { icon: <></>, label: 'Use this Schema', onClick: handleSchemaSelect }
    ]

    const schemaGridProps = {
        withSearch: false,
        component: InfiniteItemGrid,
        title: "",
        pageSize: 20,
        config: backedSchema,
        additionalQueryParams: {collection_name:name},
        whenEmpty: <>
            <Text py='xl' align='center' size='lg' weight={700}>You haven't yet upgraded any schemas for use with NFT Backers.</Text>
            <Group py='xl' position='center'>
                <Button size='lg' component={NavLink} to={`/creator/collection/${name}/enroll`}>Configure Collection</Button>
            </Group>
        </>,
    }

    return (
        <Container px='xl' py='xl'>
            <Title pt='xl' order={2}>Create a Template</Title>
            <Stepper my='xl' active={active} onStepClick={setActive} breakpoint="sm">
                <Stepper.Step label="Choose Your Schema" allowStepSelect={active > 0}>
                    <Title my='xl' order={2} align='center'>Select the schema you wish to use.</Title>
                    <InfiniteDataProvider {...schemaGridProps} />
                </Stepper.Step>
                <Stepper.Step label="Build Your Template" allowStepSelect={active > 1}>
                    <Title my='xl' order={2} align='center'>You are creating a {selectedSchema?.schema_name} template.</Title>
                    <CreateTemplateForm schema={selectedSchema} filters={filters} onComplete={()=>{setActive(2)}} />
                </Stepper.Step>
                <Stepper.Completed>
                    <Title pt='xl' align='center' order={2}>Great Job!</Title>
                    <Text pb='xl' align='center'>Your template has been created.</Text>
                    <Group position='center'><Button component={NavLink} to={`/creator/collection/${collection?.collection_name}}/backed`}>View Backed Templates</Button></Group>
                </Stepper.Completed>
            </Stepper>
        </Container>
    );
}