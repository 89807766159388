import { GlobalStateInterface } from "./GlobalState";

export type ActionType = {
    type: string;
    payload?: any;
}

export default (state: GlobalStateInterface, action : ActionType ) => {
    switch(action.type) {
        case 'SET_LOADING':
            return {
                ...state,
                isLoading: action.payload
            } as GlobalStateInterface
        default:
            return state;
    }
}