import { Textarea, Box, Skeleton, Group } from '@mantine/core';
import { format } from 'path';
import { useState, useRef, useEffect } from 'react';
import { Edit } from 'tabler-icons-react';
import './InlineMultilineEdit.css';

export default function InlineMultilineEdit({ value, setValue, styles, options, activeColor, inactiveColor, showSkeleton=true }) {
  const [editingValue, setEditingValue] = useState(value);
  const [color, setColor] = useState(inactiveColor)
  const [editing,setEditing] = useState(false)

  useEffect(() => {
    setColor(editingValue=='' ? inactiveColor : activeColor)
  },[editingValue])

  const onChange = (event) => {
    setEditingValue(event.target.value)
    setValue(event.target.value)
  }

  const onKeyDown = (event) => {
    if (/*event.key === "Enter" || */event.key === "Escape") {
      event.target.blur();
    }
  };

  const onBlur = (event) => {
    const trimmed = event.target.value.trim()
    setEditingValue(trimmed)
    setValue(trimmed)
    setEditing(false)
  };

  const onInput = (target) => {
    if ( ! target ) { return }
    if (target.scrollHeight > 33) {
      target.style.height = "5px";
      target.style.height = target.scrollHeight - 16 + "px";
    }
  };

  // const textareaRef = useRef();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    onInput(textareaRef.current);
  }, [onInput, textareaRef]);

  const rightSection = <Edit color={inactiveColor}/>

  if ( showSkeleton && ! editing && editingValue == '' ) {
    return (
        <Box onClick={()=>{ setEditing(true) }} >
            <Group position='apart'>
              <Skeleton animate={false} height={6} mt={12} radius="xl" width='85%' />{rightSection}
            </Group>
            <Skeleton animate={false} height={6} mt={12} radius="xl" />
            <Skeleton animate={false} height={6} mt={12} radius="xl" />
            <Skeleton animate={false} height={6} mt={12} radius="xl" />
            <Skeleton animate={false} height={6} mt={12} width="70%" radius="xl" />
        </Box>
    )
  }

  return (
    <Textarea
      my={0}
      autoFocus={true}
      rows={5}
      value={editingValue}
      onBlur={onBlur}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onInput={(event) => onInput(event.target)}
      ref={textareaRef}
      autoComplete='off'
      styles={{ input: { border: 'none', paddingLeft: '0', ...styles, color: color } }}
      rightSection={rightSection}
      placeholder={'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Earum laborum nobis quibusdam fugit molestias odio modi ipsa fuga, praesentium dicta! Distinctio id cupiditate incidunt libero ipsum iste voluptates laborum temporibus!'}
      {...options}
    />
    // <textarea
    //   rows={3}
    //   aria-label="Field name"
    //   value={editingValue}
    //   onBlur={onBlur}
    //   onChange={onChange}
    //   onKeyDown={onKeyDown}
    //   onInput={(event) => onInput(event.target)}
    //   // ref={textareaRef}
    //   autoComplete='off'
    // />
  );
};