import React, { ElementType, useCallback, useContext } from 'react';
import { Title, Divider, ThemeIcon, UnstyledButton, Group, Text, useMantineTheme, createStyles } from '@mantine/core';
import { Award, Badge, Icon, Login, Logout, ShieldCheck, Star } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import { RouteList } from '../../Routes';
import { UALContext } from 'ual-reactjs-renderer';
import { NavbarLinksGroup } from './NavbarLinksGroup';

export interface MainLinkProps {
  icon: Icon;
  label?: string;
  path: string;
  component?: React.ReactNode;
  authRequired: boolean;
  onClick?(): any;
  linkStyle?: any;
  accent?: any;
  onClose?(): any;
}

const useStyles = createStyles((theme) => ({

  mainLinkText: {
    fontSize: theme.fontSizes.md,
    fontWeight: 700,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],  
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  mainLinkTextAccent: {
    fontSize: theme.fontSizes.md,
    fontWeight: 700,
    color: theme.colors[theme.primaryColor][6],  
    // '&:hover': {
    //   backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    //   color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    // },
  },

  mainLinkInner: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },

  mainLinkIcon: {
    marginRight: theme.spacing.sm,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
  },

  mainLinkBadge: {
    padding: 0,
    width: 20,
    height: 20,
    pointerEvents: 'none',
  },

  collections: {
    paddingLeft: theme.spacing.md - 6,
    paddingRight: theme.spacing.md - 6,
    paddingBottom: theme.spacing.md,
  },

  collectionsHeader: {
    paddingLeft: theme.spacing.md + 2,
    paddingRight: theme.spacing.md,
    marginBottom: 5,
  },

  collectionLink: {
    display: 'block',
    padding: `8px ${theme.spacing.xs}px`,
    textDecoration: 'none',
    borderRadius: theme.radius.sm,
    fontSize: theme.fontSizes.xs,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    lineHeight: 1,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },
}));


const data = RouteList();

export function MainLink({ icon: Icon, label, component, path, authRequired, onClick, onClose, linkStyle, accent }: MainLinkProps) {

  const theme = useMantineTheme();
  const navigate = useNavigate();

  const onNavCallback = useCallback(() => { 
    if ( onClose ) {
      onClose()
    }
    return navigate(path, {replace: false});
  }, [navigate, path]);

  const ual = useContext(UALContext) as any

  const { classes } = useStyles();

  if (! linkStyle ) {
    linkStyle = {}
  }

  // Filter out authRequired menu items
  if ( authRequired && ! ual.activeUser ) { return <></>; }

  if ( ! onClick ) {
    onClick = onNavCallback
  }
  
  return (
    <UnstyledButton
      onClick={onClick}
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
        '&:hover': {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
      })}
    >
      <Group>
        <Icon size={18} className={accent ? classes.mainLinkTextAccent : classes.mainLinkText} />
        {/* <ThemeIcon color={color} size='lg' radius='lg' variant="light">
          {icon}
        </ThemeIcon> */}
        <Text sx={(theme) => (linkStyle)} className={accent ? classes.mainLinkTextAccent : classes.mainLinkText} size="sm" underline={false}>{label}</Text>
      </Group>  
    </UnstyledButton>
  );
}  

export function MainLinks({onClose}) {
  const ual = useContext(UALContext) as any

  let authLink

  if ( ! ual.activeUser ) {
      authLink = { icon: Login, label: 'Login', path: '#', onClick: () => { ual.showModal(); onClose(); }, component: <></>, authRequired: false }
  } else {
      authLink = { icon: Logout, label: 'Logout', path: '#', onClick: () => { ual.logout(); onClose(); }, component: <></>, authRequired: true }
  }

  const links = data.map((link) => {
    if ( link.links ) {
      return ( <NavbarLinksGroup key={link.label} {...link} onClose={onClose} /> )
    }
    else {
      return ( 
        <MainLink {...link} key={link.label} onClose={onClose} />
      )
    }
  });

  return (
    <div>
      {links}
      {/* <Divider my="xs" mx="sm" labelPosition="center" /> */}
      <MainLink {...authLink} />
    </div>
  )
}