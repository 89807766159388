import { Group, Text } from "@mantine/core"
import BackedTokenAmount from "./BackedTokenAmount"
import _ from 'lodash'

export default function BackingDescriptionTokens({backed_tokens, redeem_url}) {

    if ( ! backed_tokens || backed_tokens.length == 0 ) { return <></> }

    return (
        <>
            <Group>
                <Text size='sm'>This NFT is securely backed by NFT Backers with:</Text>
                { 
                    backed_tokens.map(t=>
                        <BackedTokenAmount key={t.token_symbol} backed_token={t} />
                    )
                }
            </Group>
            {
                redeem_url ?
                <Text mt='md' size='sm'>
                    The asset(s) backing this token can be extracted by burning the NFT.
                    However, as the specific backing may require one or more additional steps before
                    taking physical delivery, we recommend redeeming this NFT with the author
                    using the following link: <a href={redeem_url}>{redeem_url}</a>
                </Text>
                : <Text mt='md' size='sm'>
                    The asset(s) backing this token can be extracted by burning the NFT.
                    However, as the specific backing may require one or more additional steps before
                    taking physical delivery, we recommend checking with the collection author for
                    specific redemption instructions.
                </Text>
            }
        </>
    )
}


