

import BackedTokenDropdown from "./BackedTokenDropdown";
import { Group, Button, Space, TextInput } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { UALContext } from 'ual-reactjs-renderer';
import { Asset } from '@greymass/eosio';
import BackAsset from "../../lib/BackAsset";
import { NONAME } from "dns";


const BackNftForm = (props) => {
    const { asset, balances, groupProps, cancelProps, confirmProps, labels, handleCancel, handleConfirm } = props;

    const [selectedToken, setSelectedToken] = useState({symbol: '', contract: '', code: '', img: ''})
    const [amount, setAmount] = useState("")
    const [error, setError] = useState(<></>)
    const [hasError, setHasError] = useState(false)

    const ual = useContext(UALContext) as any;

    const formatNumberForSymbol = (value) => {
        let num = parseFloat(value)
        if ( ! num ) { num = 0 }
        const precision = parseInt(selectedToken.symbol.split(',')[0])
        return num.toFixed(precision) 
    }

    const onChangeToken = (value) => {          
        const t = balances.find(el => el.info.code == value )
        setSelectedToken(t.info)
    }

    const onChangeAmount = (e) => {
        if ( e.target.value < 0 ) { return }

        const value = formatNumberForSymbol(e.target.value)

        const avail = Asset.from( balances.find(el => el.info.code == selectedToken.code ).available )
        const input = Asset.from( `${value} ${selectedToken.code}` )


        if ( input > avail ) {
            setError(<span>Amount would overdraw balance.</span>)
            setHasError(true)
        } 
        else {
            setError(<></>)
            setHasError(false)
        }
        
        setAmount(value)
    }

    useEffect( () => {
        setAmount('')

    }, [selectedToken])

    const onCancel = handleCancel
    const onConfirm = async () => {
        const balance = Asset.from( balances.find(el => el.info.code == selectedToken.code ).contract )
        const tokens = Asset.from( `${amount} ${selectedToken.code}` )

        await BackAsset(asset, tokens, balance, selectedToken.contract, ual)
        handleConfirm()
    }

    return (
        <>
            <BackedTokenDropdown balances={balances} onChange={onChangeToken} value={selectedToken} />
            { selectedToken.symbol ? 
                <TextInput
                    required
                    label="Amount to Back"
                    type="number"
                    placeholder={selectedToken.symbol ? "1." + "0".repeat(parseInt(selectedToken.symbol.split(',')[0])) : ''}
                    rightSection={selectedToken.symbol ? selectedToken.symbol.split(',')[1].toUpperCase() : '' }
                    onChange={onChangeAmount}
                    value={amount}
                    styles={{
                        rightSection: { whiteSpace: 'nowrap', marginRight: '20px' },
                    }}
                    error={hasError ? error : false}
                />
                : <></>
            }
            <Space h="xl" />
            <Group position="right" {...groupProps}>
                <Button variant="default" {...cancelProps} onClick={onCancel}>
                {cancelProps?.children || labels.cancel}
                </Button>
                {/* Only show the confirm button if all conditions are met to succeed... */}
                { ( amount != '' && parseFloat(amount) != 0 && ! hasError ) ?
                    <Button {...confirmProps} onClick={onConfirm}>
                    {confirmProps?.children || labels.confirm}
                    </Button>
                  : <></>
                }
            </Group>
        </>
    )
}

export default BackNftForm