
export interface IToken {
    unitLabel: string;
    symbol: string;
    priceSymbol: string;
    priceAmount: number;
    priceUnit: string;
    currencySymbol: string;
    currencyTemplate: string;
    tokenTemplate: string;
    contract: string;
    sourceType: string,
    actionParams: any;
    name: string;
    image: string;
    dataTable: string;
    description: string;
    backingDesc: string;
    backingOptions: string[];
    symbolCode: string;
    sourceLink?: string;
    ld2Compliant: boolean;
    crossChainCompliance?: ICrossChainCompliance;
}

export interface ICrossChainCompliance {
    chain: string;
    reports: string;
    status: string;
}

export const tokens = [
    {
        unitLabel: 'ounces',
        symbol: 'XLDZ',
        symbolCode: '4,XLDZ',
        priceSymbol: '$',
        currencySymbol: 'WAX',
        currencyTemplate: '0.00000000 WAX',
        priceAmount: 50,
        priceUnit: 'USD',        
        tokenTemplate: '0.0000 XLDZ',
        contract: 'theld2coinio',
        sourceType: 'crowdsale',
        actionParams: { memo: 'XLDZ' },
        name: 'LD2.zero',
        image: 'https://bit.ly/ld2zeroico',
        dataTable: 'data',
        description: 'Each token represents one ounce of silver.',
        backingDesc: 'You will be able to set the backing for each template. Choose amounts from 1/10th of an ounce, one-half ounce, or a full ounce of silver. The number of assets minted will be limited by your deposited balance of XLDZ.',
        backingOptions: [ '0.1000 XLDZ', '0.5000 XLDZ', '1.0000 XLDZ' ],
        ld2Compliant: true,
        crossChainCompliance: {
            chain: 'Ethereum',
            reports: 'https://compliance.ld2coin.io/reports/reports.json',
            status: 'https://compliance.ld2coin.io/reports/xldz-crosschain-authority.json',        
        }
    },
    {
        unitLabel: 'tokens',
        symbol: 'KROWN',
        symbolCode: '4,KROWN',
        priceSymbol: '$',
        currencySymbol: 'WAX',
        currencyTemplate: '0.00000000 WAX',
        priceAmount: 50,
        priceUnit: 'USD',        
        tokenTemplate: '0.0000 KROWN',
        contract: 'krowndactokn',
        sourceType: 'exchange',
        actionParams: { memo: 'KROWN' },
        name: 'KROWN',
        image: '/tokens/krown_krowndactokn.png',
        dataTable: 'data',
        description: 'A member-owned DAC',
        backingDesc: 'You will be able to set the backing for each template.',
        backingOptions: [ '10000.0000 KROWN', '20000.0000 KROWN', '30000.0000 KROWN', '50000.0000 KROWN', '80000.0000 KROWN', '130000.0000 KROWN', '210000.0000 KROWN', ],
        sourceLink: 'https://wax.alcor.exchange/trade/krown-krowndactokn_wax-eosio.token',
        ld2Compliant: false,
    },
    {
        unitLabel: 'Trilium',
        symbol: 'TLM',
        symbolCode: '4,TLM',
        priceSymbol: '$',
        currencySymbol: 'WAX',
        currencyTemplate: '0.00000000 WAX',
        priceAmount: 50,
        priceUnit: 'USD',        
        tokenTemplate: '0.0000 TLM',
        contract: 'alien.worlds',
        sourceType: 'exchange',
        actionParams: { memo: 'TLM' },
        name: 'Alien Worlds Trilium',
        image: '/tokens/tlm_alien.worlds.png',
        dataTable: 'data',
        description: 'The in-game token for Alien Worlds',
        backingDesc: 'You will be able to set the backing for each template.',
        backingOptions: [ '10.0000 TLM', '20.0000 TLM', '30.0000 TLM', '50.0000 TLM', '80.0000 TLM', '130.0000 TLM', '210.0000 TLM' ],
        sourceLink: 'https://wax.alcor.exchange/trade/tlm-alien.worlds_wax-eosio.token',
        ld2Compliant: false,
    },

]