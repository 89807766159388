import { Container, Title, useMantineTheme } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { UALContext } from 'ual-reactjs-renderer';
import { CollectionManageCard } from "../../../../../components/NFT/cards/CollectionManageCard";
import { FixedItemGrid } from "../../../../../components/NFT/FixedItemGrid";
import InfiniteDataProvider from "../../../../../providers/InfiniteItemProvider";
import {configs as infiniteItemConfigs} from "../../../../../providers/InfiniteItemProvider";

import { AAMiddleware } from "../../../../../store/AAMiddleware";

const api = new AAMiddleware()

export function UserCollections() {
    const theme = useMantineTheme();
    const ual = useContext(UALContext) as any;

    if ( ! ual.activeUser || ! ual.activeUser.accountName  ) { return <></> }
    // const dataSort = 'issued_supply:desc'
    // const dataFilters = [
    //     {
    //         range: { issued_supply: {gt: 0} }
    //     }
    // ]

    const dataSort = ''
    const dataFilters = [
        // {
        //     range: { issued_supply: {gt: 0} }
        // }
    ]

    const whenEmpty = (
        <Container py="xl">
            <Title order={2}>No Elligible Collections</Title>
            <ul>
                <li>Whitelist on AtomicAssets</li>
                <li>OR Whitelist on NeftyBlocks</li>
                <li><strong>OR Contact us on Discord</strong></li>
            </ul>
        </Container>
    )

    const config = Object.assign({},infiniteItemConfigs.collectionSearch)
    config.grid.gridCard = CollectionManageCard
    config.grid.itemBaseUri = "/creator/collection/"
    
    return (
        <Container py="xl">
            <InfiniteDataProvider
                withSearch={true}
                whenEmpty={whenEmpty}
                title="Your Collections" 
                pageSize={30}
                config={infiniteItemConfigs.collectionSearch} 
                additionalQueryParams={{author: ual.activeUser.accountName, filters: dataFilters, sort: dataSort}}
                component={FixedItemGrid}
                />
        </Container>
    )
    

}