import { Avatar, Card, createStyles, Group, Image, Skeleton, useMantineTheme } from "@mantine/core"
import { useModals } from "@mantine/modals"
import { useState } from "react"
import { TemplateCard } from "./TemplateCard";

const useStyles = createStyles((theme) => ({
    card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },
  
    imageSection: {
      padding: theme.spacing.md,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottom: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,
    },
  
    label: {
      marginBottom: theme.spacing.xs,
      lineHeight: 1,
      fontWeight: 700,
      fontSize: theme.fontSizes.xs,
      letterSpacing: -0.25,
      textTransform: 'uppercase',
    },
  
    section: {
      padding: theme.spacing.md,
      borderTop: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,
    },
  
    icon: {
      marginRight: 5,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
    },

    title: {
    color: theme.colors.gray[6],
    },

    description: {
    color: theme.black,
    },

}));

export function ThumbTemplateGallery({items}) {
    const [selectedItem, setSelectedItem] = useState(0)

    const modals = useModals()
    const theme = useMantineTheme();
    const { classes } = useStyles();

    const imageConfig = { fullHeight: 300, thumbHeight: 40 }

    if ( items.length ==  0 ) {
      return (<></>)
    }

    const thumbs = items.map((item,i)=>{
        const click = () => {
            setSelectedItem(i)
        }
        return (
            <Avatar 
                key={`thumb-${i}`}
                size={imageConfig.thumbHeight} 
                src={process.env.REACT_APP_IMAGE_PROXY+item.immutable_data.img+'?h=' + imageConfig.thumbHeight} 
                radius={imageConfig.thumbHeight} 
                style={{border: ( selectedItem == i ? '1px' : '0px' ) + ' solid ' + theme.colors.gray[5]}}
                onClick={click}
                alt={item.immutable_data.name}
            />
        )
    })

    return (
      <>
          <Card radius="lg">
              <Card.Section>
                <TemplateCard item={items[selectedItem]} path={"/explorer/template/"} />
              </Card.Section>
          </Card>
          <Group position="center" style={{ marginBottom: theme.spacing.lg, marginTop: theme.spacing.lg }}>
              {thumbs}
          </Group>
      </>

    )

}