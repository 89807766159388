import { useEffect, useState } from 'react';
import { Stepper, Button, Group, Container, Title, Text, Card } from '@mantine/core';
import { Link, useParams } from 'react-router-dom';
import { AAMiddleware } from '../../../store/AAMiddleware';
import { ICollection } from 'atomicassets/build/API/Explorer/Objects';
import ChooseBacking from './Main/ChooseBacking/ChooseBacking';
import ConfigureCollection from './Main/ConfigureCollection/ConfigureCollection';
import UpgradeSchema from './Main/UpgradeSchema/UpgradeSchema';

const api = new AAMiddleware()

export function Enroll() {

    const { name } = useParams()

    const [collection,setCollection] = useState<ICollection>()
    const [active, setActive] = useState(0);
    const [configured, setConfigured] = useState(false)
    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    const onNextStep = () => {
        window.scrollTo(0,0)
        nextStep()        
    }

    useEffect(()=>{
        const checkConfigured = async () => {
          if ( ! process.env.REACT_APP_NFTBACKER_CONTRACT ) { return }
          let isConfigured = false
          const notify_accounts = await api.getNotifyAccounts(name??'')
          isConfigured == isConfigured || notify_accounts.includes(process.env.REACT_APP_NFTBACKER_CONTRACT)
          setConfigured( isConfigured )
        }
        checkConfigured()
    },[name])
  
    useEffect(()=>{
        const loadCollection = async () => {
            if ( ! name ) { return }
            const col = await api.getCollection(name)
            setCollection(col)
        }
        loadCollection()
    },[name])

    const buttonBlock = (
        <Group position="center" mt="xl">
            <Button variant="default" onClick={prevStep}>Back</Button>
            <Button onClick={onNextStep} disabled={((active == 1 || active == 2) && !configured)}>Next step</Button>
        </Group>
    )

    return (
        <Container px='xl' py='xl'>
            <Title pb='xl' order={1}>Enroll Your Collection</Title>
            <Card px='xl' py='xl'>
                <Text size='md'>We are excited to welcome <strong>{collection?.data.name}</strong> to NFT Backers. 
                Soon you will be a certified <em>SilverBacked</em> issuer, and NFT 2.0 creator. As an NFT Backer, you bring
                real-world value to your collection, and build trust and confidence with your community. 
                </Text>
            </Card>
            <Title pt='xl' order={2}>Get Started</Title>
            <Stepper my='xl' active={active} onStepClick={setActive} breakpoint="sm">
                <Stepper.Step label="Choose the Backing" description="How will you back your NFTs?" allowStepSelect={active > 0}>
                    <ChooseBacking />
                </Stepper.Step>
                <Stepper.Step label="Configure Collection" description="Enable collection notifications" allowStepSelect={active > 1}>
                    <ConfigureCollection collection={name} onConfigured={setConfigured}/>
                </Stepper.Step>
                <Stepper.Step label="Upgrade a Schema" description="Prepare a schema for NFT Backers" allowStepSelect={active > 2}>
                    <UpgradeSchema collection={name} onUpgraded={setConfigured} />
                </Stepper.Step>
                <Stepper.Completed>
                    <Title pt='xl' align='center' order={2}>Your collection is now enrolled.</Title>
                    <Text pb='xl' align='center'>It's time to build your first template as an NFT Backer.</Text>
                    <Group pb='xl' position='center'>
                        <Button component={Link} to={`/creator/collection/${collection?.collection_name}/template`} size='lg'>Create a Template</Button>
                    </Group>
                </Stepper.Completed>
            </Stepper>

            { active != 3 ? buttonBlock : ''}

        </Container>
    );
}