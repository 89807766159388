import { useRef, useState, useEffect, useContext } from 'react';
import { UALContext } from 'ual-reactjs-renderer';
import { Asset } from '@greymass/eosio';

import InfiniteScroll from 'react-infinite-scroll-component';
import AssetGrid from '../../components/NFT/AssetGrid';
import { BackedAssetCard } from '../../components/NFT/AssetGrid';
import GridLoader from '../../components/GridLoader';

import { Space, Container, Text, useMantineTheme, Grid, Title, } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { Flame, Plus } from 'tabler-icons-react';

import BurnAsset from '../../lib/BurnAsset'

import { rpc } from '../..';
import internal from 'stream';
import BackedTokenDropdown from '../../components/NFT/BackedTokenDropdown';
import { config } from 'process';
import BackNftForm from '../../components/NFT/BackNftForm';

import { AAMiddleware } from '../../store/AAMiddleware';
import InfiniteDataProvider from '../../providers/InfiniteItemProvider';
import {configs as infiniteItemConfigs } from '../../providers/InfiniteItemProvider';


interface ContractConfig {
    key: number;
    supported_tokens: any;
}

interface AssetItemProperties {
  asset_id: string;
  owner: string;
  name: string;
  img: string;
  data: any;
  collection: any;
  backed_tokens: any;
  burned_at_time: any;
  template_mint: string;
}

const api = new AAMiddleware();

export function Backed() {

  const theme = useMantineTheme();
  const ual = useContext(UALContext) as any;

  const [collections, setCollections] = useState<Array<any>>([])
  const [supportedTokens, setSupportedTokens] = useState<Array<any>>([])
  const [balances, setBalances] = useState({})
  const [items, setItems] = useState<Array<AssetItemProperties>>([]);
  const [accountAssetMap, setAccountAssetMap] = useState({});
  const [collectionFilter, setCollectionFilter] = useState('');
  const [page, setPage] = useState(0);
  const [more, setMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const pageSize = 10

  const [selectedToken,setSelectedToken] = useState('')

  const modals = useModals();

  const burnAsset = async (asset_id) => {

    const asset = items.find((a) => a.asset_id == asset_id )

    if ( ! asset ) {
        console.log(`Unable to find asset with id ${asset_id}`)
        return
    }

    const onConfirm = () => { BurnAsset(asset, ual) }

    modals.openConfirmModal({
        title: 'Burn this NFT?',
        centered: true,
        children: (
            <Container>
                <Text size="sm">
                    <p><strong>Are you sure you want to burn (destroy) this NFT?</strong></p>
                    <p>
                        Burning will permenently destroy your NFT, and unlock the 
                        backed assets in the process. These tokens will be transferred
                        to your wallet.
                    </p>
                </Text>
                <BackedAssetCard asset={asset} options={{minimal:true, shadow: 'none', nolink:true}} onDetails={()=>{}} />
            </Container>
        ),
        labels: { confirm: 'Confirm', cancel: 'Cancel' },
        onCancel: () => {},
        onConfirm: onConfirm,
    })

  }  

  const backAsset = async (asset_id) => {

    const asset = items.find((a) => a.asset_id == asset_id )

    if ( ! asset ) {
        console.log(`Unable to find asset with id ${asset_id}`)
        return
    }

    const onConfirm = () => { BurnAsset(asset, ual) }

    const currentBalances = Object.keys(balances).map((t,i) => {
        return balances[t]
    })

    const labels1 = { confirm: 'Continue', cancel: 'Cancel' }
    const labels2 = { confirm: 'Confirm', cancel: 'Cancel' }

    modals.openConfirmModal({
        title: 'Back this NFT?',
        centered: true,
        closeOnConfirm: false,
        labels: labels1,
        children: (
            <>
                <Text size="sm">
                    Backing this asset will attach tokens from your wallet to this NFT,
                    all of which remain locked up until the asset is burned. Transferring
                    the NFT also transfers the ownership of the backed tokens. Whoever 
                    burns the NFT will receive all attached tokens.
                    <strong> Backing an NFT is irreversible.</strong>
                </Text>
            </>
        ),
        onConfirm: () => {
            return modals.openConfirmModal({
                title: `Back NFT ${asset.asset_id}`,
                centered: true,
                // These are required, but we're hiding them
                labels: labels2,
                closeOnConfirm: false,
                children: (
                    <>
                        <BackedAssetCard asset={asset} options={{minimal:true, shadow: 'none', nolink: true}} onDetails={()=>{}} />
                        <BackNftForm
                            asset={asset}
                            balances={currentBalances}
                            labels={labels2}
                            handleCancel={modals.closeAll}
                            handleConfirm={modals.closeAll}
                        />
                    </>
                ),
                // Hide the buttons
                groupProps: { style: { display: 'none' } },
                // onConfirm: () => modals.closeAll(),
                // onCancel: () => modals.closeAll(),
            })
        }
    });    

  }  

  // Load the contract configuration
  useEffect( () => {

    async function fetchCollections() {

        let request = {
            json: true,               // Get the response as json
            code: process.env.REACT_APP_NFTBACKER_CONTRACT,     // Contract that we target
            scope: process.env.REACT_APP_NFTBACKER_CONTRACT,    // Account that owns the data
            table: 'collections',     // Table name
            limit: 100,               // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
        };
    
        const res = await rpc.get_table_rows(request)
        const newCollections = res.rows
    
        setCollections(newCollections)
    }

    async function fetchSupportedTokens() {

        let request = {
            json: true,               // Get the response as json
            code: process.env.REACT_APP_NFTBACKER_CONTRACT,     // Contract that we target
            scope: process.env.REACT_APP_NFTBACKER_CONTRACT,    // Account that owns the data
            table: 'config',     // Table name
            limit: 1,               // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
        };
    
        const res = await rpc.get_table_rows(request)
        setSupportedTokens(res.rows[0].supported_tokens)
    }

    fetchCollections()
    fetchSupportedTokens()

  }, [ual.activeUser]);

  // Load the balances
  useEffect( () => {

    async function fetchBalances() {

        if ( ! ual.activeUser || ual.activeUser.accountName == '' ) { return; }

        // Initialize our new balance map
        const balanceMap = {}

        for ( const t of supportedTokens ) {
            const code = t.symbol.split(',')[1]
            const zeroAsset = Asset.from(0,t.symbol)
            // Get account balance from token contract
            const res = await rpc.get_currency_balance(t.contract, ual.activeUser.accountName, code)
            balanceMap[code] = { info: { contract: t.contract, code: code, symbol: t.symbol }, contract: zeroAsset.toString(), deposited: zeroAsset.toString(), available: zeroAsset.toString()}
        }

        // Get deposited balance from nftbacker contract
        let request = {
            json: true,               // Get the response as json
            code: process.env.REACT_APP_NFTBACKER_CONTRACT,     // Contract that we target
            scope: process.env.REACT_APP_NFTBACKER_CONTRACT,    // Account that owns the data
            table: 'balances',     // Table name
            limit: 1,               // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
            lower_bound: ual.activeUser.accountName,
            upper_bound: ual.activeUser.accountName,
        };

        // Get deposited balances ONLY for supported tokens
        const res = await rpc.get_table_rows(request)
        if ( res.rows.length ) {
            for ( let b = 0; b < res.rows[0].quantities.length; b++ ) {
                const row = res.rows[0].quantities[b]
                const bal = Asset.from(row)
                if ( ! supportedTokens[bal.symbol.code.toString()] ) { continue; }
                if ( ! balanceMap[bal.symbol.code.toString()] ) {
                    const conBal = Asset.from(row)
                    conBal.value = 0;
                    balanceMap[bal.symbol.code.toString()] = { contract: conBal.toString(), deposited: bal.toString(), available: '' }
                }
                else {
                    balanceMap[bal.symbol.code.toString()].deposited = bal.toString();
                }
            }   
        }
        else {
            // No depositied balances to load
        }

        // Calculate available balances and set info
        const bKeys = Object.keys(balanceMap)
        for ( let b = 0; b < bKeys.length; b++ ) {
            const bal = balanceMap[bKeys[b]]
            const depVal = Asset.from(bal.deposited).value
            const conVal = Asset.from(bal.contract).value
            const avail = Asset.from(bal.contract)
            avail.value = depVal + conVal;
            const contract = balanceMap[bKeys[b]].info ? balanceMap[bKeys[b]].info.contract : ''
            if ( balanceMap[bKeys[b]].info ) {
                balanceMap[bKeys[b]].info.img = `${bKeys[b].toLowerCase()}_${contract.toLowerCase()}.png`
            }
            else {
                balanceMap[bKeys[b]].info = { img: `${bKeys[b].toLowerCase()}_${contract.toLowerCase()}.png`}
            }
            balanceMap[bKeys[b]].available = avail.toString()
        }

        setBalances(balanceMap)
    }

    fetchBalances()

}, [collections, supportedTokens, ual.activeUser]);

// Load the assets
useEffect( () => {

    async function fetchAccountAssets() {

        let account = (ual.activeUser && ual.activeUser.accountName) ? ual.activeUser.accountName : '';
        if ( ! account ) { return; }

        let request = {
            json: true,               // Get the response as json
            code: process.env.REACT_APP_NFTBACKER_CONTRACT,     // Contract that we target
            scope: '',                // Account that owns the data (will provide below)
            table: 'assets',          // Table name
            limit: 100,               // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
            index_position: 2,
            key_type: 'i64',
            lower_bound: account,
            upper_bound: account,
        };

        let newAccountAssetMap = Object.assign({},accountAssetMap)

        for ( let c = 0; c < collections.length; c++ ) {
            const collection = collections[c]
            request.scope = collection.collection_name
            const res = await rpc.get_table_rows(request)
            for (let i = 0; i < res.rows.length; i++ ) {
                let asset = res.rows[i]
                newAccountAssetMap[asset.asset_id] = asset.backed_tokens;
            }
        }

        setAccountAssetMap(newAccountAssetMap)
    }

    fetchAccountAssets()

  }, [ual.activeUser, collections]);

  async function loadAssets() {
    
    let account = (ual.activeUser && ual.activeUser.accountName) ? ual.activeUser.accountName : '';
    if ( ! account ) { return; }

    // If no account assets, there's no reason to load the atomic assets
    if (Object.keys(accountAssetMap).length == 0) { return; }

    setLoading(true)

    const collectionMap = {}
    collections.forEach((c) => { collectionMap[c.collection_name] = c; })

    const collectionWhitelist = Object.keys(collectionMap).join(',')
    const idList = Object.keys(accountAssetMap).join(',')

    const query = {
      owner:account,
      collection_whitelist: collectionWhitelist,
      ids: idList,
      limit: pageSize,
      page: page+1
    };

    if ( collectionFilter ) {
      query['collection_name'] = collectionFilter
    }

    const aaAssets = await api.getAssets(query)

    if ( aaAssets.length == 0 ) {
      setMore(false)
      setLoading(false)
      return;
    }

    // If it was a full page, there could be a next, so increment our nextPage counter
    setPage(page+1)

    const newItems: AssetItemProperties[] = [];
    for ( let i = 0; i < aaAssets.length; i++ ) {
        const item = aaAssets[i]
        const asset: AssetItemProperties = {
            asset_id: item.asset_id,
            owner: item.owner ? item.owner : '',
            name: item.data.name,
            img: item.data.img,
            data: item.data,
            collection: Object.assign(item.collection, collectionMap[item.collection.collection_name]),
            backed_tokens: item.backed_tokens,
            burned_at_time: item.burned_at_time,
            template_mint: item.template_mint,
        }
        // Add our backing
        asset.backed_tokens = asset.backed_tokens.concat(accountAssetMap[asset.asset_id])
        newItems.push(asset)
    }

    setItems(items.concat(newItems))
    setLoading(false)

  }

  const onBurnAsset = (asset_id) => {

  }

  // Load the assets
  useEffect( () => {
    loadAssets();
  }, [ual.activeUser, collectionFilter, accountAssetMap]);

  const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];

  const buttons = [
    { label: 'Burn & Withdraw', onClick: burnAsset, icon: <Flame size={16} /> },
    // { label: 'Back with Tokens', onClick: backAsset, icon: <Plus size={16} /> },      
  ]

  if ( ! ual.activeUser || ! ual.activeUser.accountName || ! collections.length ) { return <></> }

  const collectionWhitelist = collections.map(c=>c.collection_name).join(',')

  const whenEmpty = (
      <Container px='xl' py='xl'>
          <Title order={1}>Welcome to NFT 2.0</Title>
        <Text my='lg'>
            It looks like you don't have any backed assets... YET. <strong>Now is a great time to get some! </strong>
            Check out some of the NFT Backers below. Each one may offers backing in their own way
            but all compatible with the NFT Backers standard and backed by real-world value.
        </Text>
        <Grid>
            <Grid.Col xs={12}>
                <InfiniteDataProvider withSearch={false} title="NFT Backers" pageSize={20} config={infiniteItemConfigs.backer} additionalQueryParams={{}} ></InfiniteDataProvider>
            </Grid.Col>
        </Grid>
      </Container>
  )

  return (
    <Container px='xl' py='xl'>
        <Grid>
            <Grid.Col xs={12}>
                <InfiniteDataProvider whenEmpty={whenEmpty} withSearch={true} title="My Backed Assets" pageSize={20} config={infiniteItemConfigs.backedAsset} additionalQueryParams={{owner: ual.activeUser.accountName, collection_whitelist: collectionWhitelist, sort: 'asset_id', order: 'desc'}} ></InfiniteDataProvider>
            </Grid.Col>
        </Grid>
    </Container>
  );

    return (
        <Container px='xl' py='xl'>
        <InfiniteScroll
            style={{overflow: "hidden"}}
            dataLength={items.length} //This is important field to render the next data
            next={loadAssets}
            hasMore={more}
            loader={<GridLoader />}
            endMessage={<></>}
        >
            <AssetGrid GridCard={BackedAssetCard} assets={items} buttons={buttons} onCollectionFilter={setCollectionFilter} />
            <Space h="md" />
        </InfiniteScroll>
        </Container>
    );
}