import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module'
import {gtag} from 'react-gtm-module'

import {UALProvider, withUAL} from 'ual-reactjs-renderer';
import { JsonRpc } from 'eosjs';
import {Wax} from '@eosdacio/ual-wax';
import {Anchor} from 'ual-anchor';

import chains from './components/Blockchains/assets/blockchains.json'
import { find } from 'lodash'

const chain = find(chains, {key: process.env.REACT_APP_CHAIN })

const endpoint = `${chain.rpcEndpoints[0].protocol}://${chain.rpcEndpoints[0].host}:${chain.rpcEndpoints[0].port}`;

export const rpc = new JsonRpc(endpoint);

const authenticators = [] as any;

// const wax = new Wax([chain], {appName: process.env.REACT_APP_UAL_APP_NAME})
// const anchor = new Anchor([chain], {appName: process.env.REACT_APP_UAL_APP_NAME})    
const wax = new Wax([chain])
const anchor = new Anchor([chain], {appName: `${process.env.REACT_APP_CHAIN}-${ process.env.REACT_APP_UAL_APP_NAME ?? 'nftbacker-io'}`})

if ( process.env.REACT_APP_CHAIN === 'wax' ) {
    authenticators.push(wax)
}
  
authenticators.push(anchor)

const MyUALConsumer = withUAL(App)

// Google Tag Manager
const tagManagerArgs = {
  gtmId: 'G-8NWQWTXHSR'
};
TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
    <UALProvider chains={[chain]} authenticators={authenticators} appName={process.env.REACT_APP_UAL_APP_NAME}>
        <MyUALConsumer rpc={rpc} chain={chain} />
    </UALProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
