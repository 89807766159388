import { useContext } from 'react';
import { UALContext } from 'ual-reactjs-renderer';

import { showNotification, updateNotification } from '@mantine/notifications';
import { Flame, AlertTriangle, Check } from 'tabler-icons-react';

export default async function Withdraw(balances, ual) {

    const auth = [{
        actor: ual.activeUser.accountName,
        permission: ual.activeUser.requestPermission,
    }]

    let actions = [] as any

    balances.map((b) => {
        actions.push({
            account: process.env.REACT_APP_NFTBACKER_CONTRACT,
            name: 'withdraw',
            authorization: auth,
            data: {
                owner: ual.activeUser.accountName,
                token_to_withdraw: b.toString(),
            }
        })
    })

    try {
        showNotification({
            id: `withdraw-balances`,
            loading: true,
            title: `Withdrawing Balances`,
            message: 'Preparing to withdraw balances from the NFT Backer contract.',
            autoClose: false,
            disallowClose: true,
            icon: <Flame />,
        });

        const res = await ual.activeUser.signTransaction(
            {
                actions: actions
            },
            {
                blocksBehind: 3,
                expireSeconds: 60
            }
        );
        updateNotification({
            id: `withdraw-balances`,
            color: 'teal',
            title: `Success!`,
            message: `Withdrawn balances were transferred to your wallet.`,
            icon: <Check />,
            autoClose: 10000,
        });
    } catch (e : any ) {
        console.log(e);

        const msg = e.message.replace('assertion failure with message:','');

        updateNotification({
            id: `withdraw-balances`,
            color: 'red',
            title: `Unable to withdraw balances`,
            message: msg,
            icon: <AlertTriangle />,
            autoClose: false,
        });
    }

}