import { Accordion, Box, Button, Card, Checkbox, Collapse, Container, Grid, Group, InputWrapper, NumberInput, Skeleton, Text, TextInput, Title, useMantineTheme } from "@mantine/core";
import { useForm } from "@mantine/form";
import { CreatableSelect } from "../../../../components/controls/CreatableSelect";
import BackedByDropdown from "../../../../components/NFT/BackedByDropdown";
import { UALContext } from 'ual-reactjs-renderer';

import BackAssetFromBalance from '../../../../lib/BackAssetFromBalance'

import JSONPretty from 'react-json-pretty';

import { tokens, IToken } from "../../../../config/tokens";
import _ from 'lodash'
import { Api, Check, CloudUpload, FileUpload, Photo, SquareCheck } from "tabler-icons-react";
import InlineEdit from "../../../../components/controls/InlineEdit";
import InlineMultilineEdit from "../../../../components/controls/InlineMultilineEdit";
import { useContext, useEffect, useState } from "react";
import { ImageUploadManager } from "../../../../components/ImageUploadManager";
import { AAMiddleware } from "../../../../store/AAMiddleware";
import { useParams } from "react-router-dom";
import { useModals } from "@mantine/modals";
import { ITemplateExtended, TemplateCard } from "../../../../components/NFT/cards/TemplateCard";
import { SimpleTemplateCard } from "../../../../components/NFT/cards/SimpleTemplateCard";
import { PreviewTemplateCard } from "../../../../components/NFT/cards/PreviewTemplateCard";
import { IAsset } from 'atomicassets/build/API/Explorer/Objects';
import { AssetCard } from '../../../../components/NFT/cards/AssetCard'

const api = new AAMiddleware()

const ucfirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function BackAssetForm() {

    const theme = useMantineTheme();
    const modals = useModals();

    const { name } = useParams()
    
    const ual = useContext(UALContext) as any
    const [enabledTokens,setEnabledTokens] = useState<Array<IToken>>([])
    const [asset,setAsset] = useState<IAsset>()

    useEffect(()=>{
        if ( ! name ) { return }
        const loadEnabledTokens = async () => {
            const conf = await api.getBackedCollection(name)
            const newEnabledTokens = tokens.filter((t)=>!! _.find(conf.supported_tokens, (s)=> s.symbol == t.symbolCode)) as IToken[]
            setEnabledTokens(newEnabledTokens)
        }
        loadEnabledTokens()
 
    },[name])

    const secondaryColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[1]
      : theme.colors.gray[7];

    const accentColor = theme.colorScheme === 'dark'
      ? theme.colors.blue[0]
      : theme.colors.blue[5];

    const lightTextColor = theme.colorScheme === 'dark'
      ? theme.colors.gray[1]
      : theme.colors.gray[7];

    const lighterTextColor = theme.colorScheme === 'dark'
      ? theme.colors.gray[3]
      : theme.colors.gray[5];    


    const form = useForm({
        initialValues: {
            payer: '',
            owner: '',
            collection: name,
            asset_id: '',
            backedby: '',                
        },
        validate: {
            payer: (v) => v != '' ? null : 'Payer is required',
            owner: (v) => v != '' ? null : 'Owner is required',
            collection: (v) => v != '' ? null : 'Collection is required',
            asset_id: (v) => {
                if ( v == '' ) { return 'Asset ID is required' }
                if ( ! asset ) { return 'An asset with this ID does not exist.' }
                if ( asset.collection.collection_name != name ) { return `The asset is not in the ${name} collection.`}
                if ( asset.burned_at_block ) { return 'The asset has already been burned.' }
            },
            backedby: (v) => v != '' ? null : 'Backing is required',
        },
    });

    useEffect(()=>{
        if ( ! ual.activeUser?.accountName ) { return }
        form.setFieldValue('payer',ual.activeUser.accountName)
        form.setFieldValue('owner',ual.activeUser.accountName)

        // updateValue('payer',ual.activeUser.accountName)
        // updateValue('owner',ual.activeUser.accountName)

    },[ual.activeUser])

    useEffect(()=>{
        if ( ! form.values.asset_id ) { setAsset(undefined); return }
        const loadAsset = async () => {
            const newAsset = await api.getAsset(form.values.asset_id)
            if ( newAsset ) {
                setAsset(newAsset)
                form.setFieldValue('owner',newAsset.owner ?? '')
                form.setFieldValue('collection',newAsset.collection.collection_name ?? '')
            }
        }
        loadAsset()
    },[form.values.asset_id])


    const backAsset = async () => {
        await BackAssetFromBalance(asset, form.values.backedby, ual)
    }

    const confirmTemplateModal = () => modals.openConfirmModal({
        title: 'Back this NFT?',
        children: (
            <>
                {/* <PreviewTemplateCard item={{ ...form.values, immutable_data: {...form.values}, schema: schema, collection: schema.collection_name } } /> */}
                <Text size="sm">
                    You are about to back an NFT. This action is not reversible. 
                    The balance you have selected for the backing will be withdrawn from your deposited balances and 
                    attached to the NFT. It can only be freed by burning this NFT. <strong>Are you sure?</strong> 
                </Text>
            </>
        ),
        labels: { confirm: 'Confirm', cancel: 'Cancel' },
        onCancel: () => console.log('Cancel'),
        onConfirm: backAsset,
    });

    return (
        <Container px='xl' py='xl'>
            <Title align='center' order={2}>Back Existing NFT</Title>
            <form onSubmit={form.onSubmit((values) => console.log(values))}>
            <Grid justify='center' gutter="xl">
                <Grid.Col xs={12} sm={6} lg={8}>

                    <InputWrapper my='lg' label='Payer'>
                            <TextInput
                                {...form.getInputProps('payer')}
                                size='md'
                                onChange={(e)=>{form.setFieldValue('payer',e.target.value)}}
                            />
                        </InputWrapper>
                        <InputWrapper my='lg' label='Asset ID'>
                            <TextInput
                                {...form.getInputProps('asset_id')}
                                size='md'
                                onChange={(e)=>{form.setFieldValue('asset_id',e.target.value)}}
                            />
                        </InputWrapper>
                        <InputWrapper my='lg' label='Collection'>
                            <TextInput
                                {...form.getInputProps('collection')}
                                size='md'
                                onChange={(e)=>{form.setFieldValue('collection',e.target.value)}}
                            />
                        </InputWrapper>
                        <InputWrapper my='lg' label='Owner'>
                            <TextInput
                                {...form.getInputProps('owner')}
                                size='md'
                                onChange={(e)=>{form.setFieldValue('owner',e.target.value)}}
                            />
                        </InputWrapper>
                        <BackedByDropdown my='lg' tokens={enabledTokens} size='md' onChange={(v)=>{ form.setFieldValue('backedby',v) }} />

                </Grid.Col>
                { asset ?
                    <Grid.Col xs={12} sm={6} lg={4}>
                        <AssetCard item={asset} buttons={[]} path='/explorer/asset' options={{}} />
                    </Grid.Col>
                 : <></> }
                <Grid.Col xs={12}>
                    <Group my='xl' position='center'>
                        <Button size='lg' onClick={()=>{ if (form.validate().hasErrors) { return }  confirmTemplateModal() }}>Back NFT</Button>
                    </Group>
                </Grid.Col>
            </Grid>
            </form>
        </Container>
    );

}

