import { Grid, useMantineTheme } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { UALContext } from 'ual-reactjs-renderer';

import { AAMiddleware } from "../../../../../store/AAMiddleware";
import { StatsRing } from "./StatsRing";

import { IToken, tokens } from '../../../../../config/tokens'
import { BalanceCard } from "../../../../../components/NFT/cards/BalanceCard";
import { BalanceManageCard } from "../../../../../components/NFT/cards/BalanceManageCard";
import _ from 'lodash'

const api = new AAMiddleware()

export function Balances({manage=false, balanceAsset=undefined, showtokens = [] as any, message = '' as any, itemParams = {}, buttons=[]}) {
    const theme = useMantineTheme();
    const ual = useContext(UALContext) as any;
    const [selected,setSelected] = useState('')
    const [balances,setBalances] = useState([] as any)

    useEffect(()=> {
        const loadBalances = async () => {
            if ( ! ual.activeUser || ! ual.activeUser.accountName ) { return }
            const filteredTokens = tokens.filter(t=>showtokens.includes(t.symbol)) as IToken[]
            const newBalances = await api.getAvailableBalances(filteredTokens, ual.activeUser.accountName)
            if ( newBalances.length ) { setSelected( newBalances[0].deposited.symbol.code ) }
            setBalances(newBalances)
        }
        loadBalances()
    },[ual.activeUser])

    const messageCard = message ? (
        <Grid.Col xs={12} sm={6}>
            {message}
        </Grid.Col>
    ) : (<></>)

    const Card = manage ? BalanceManageCard : BalanceCard

    return (
        <Grid align='stretch' py="md">
            {
                tokens.filter(t=>showtokens.includes(t.symbol)).map((t) => {
                    const balanceAsset = _.find(balances,b=>b.total.symbol.code == t.symbol)
                    const balance = balanceAsset?.total.toString() ?? t.tokenTemplate
                    return ( 
                        <Grid.Col xs={12} sm={6} {...itemParams} key={t.symbol}>
                            <Card token={t} balance={balance} balanceAsset={balanceAsset} buttons={buttons} selected={selected == t.symbol} onClick={()=>{setSelected(t.symbol)}} />                    
                        </Grid.Col>
                    )
                })
            }
            {messageCard}
        </Grid>
    )
}