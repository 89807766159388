import { Container, Group, Button, ActionIcon, useMantineTheme } from "@mantine/core"
import { Link, MapPin, Id, BuildingBank, BrandTwitter, BrandFacebook, BrandTelegram, BrandMedium, BrandYoutube, BrandDiscord, BrandGithub } from "tabler-icons-react"

export function CollectionSocials({collection}) {

    const theme = useMantineTheme();

    const secondaryColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[1]
      : theme.colors.gray[7];

    const baseTextColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[7]
      : theme.colors.gray[9];

    const lightTextColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[3]
      : theme.colors.gray[5];

    const iconMap = {
        url: { label: 'Website', icon: Link },
        website: { label: 'Website', icon:Link },
        twitter: { label: 'Twitter', icon:BrandTwitter },        
        facebook: { label: 'Facebook', icon:BrandFacebook },
        medium: { label: 'Medium', icon:BrandMedium },
        github: { label: 'Github', icon:BrandGithub },
        telegram: { label: 'Telegram', icon:BrandTelegram },
        youtube: { label: 'YouTube', icon:BrandYoutube },
        discord: { label: 'Discord', icon:BrandDiscord },
        // address: { label: 'Address', icon:MapPin },
        // registration_number: { label: 'Registration', icon:Id },
        // company: { label: 'Company', icon:BuildingBank },
    }

    const urlMap = {
        url: 'https://',
        website: 'https://',
        twitter: 'https://twitter.com/',
        facebook: 'https://facebook.com/',
        medium: 'https://medium.com/',
        github: 'https://github.com/',
        telegram: 'https://t.me/',
        youtube: 'https://youtube.com/',
        discord: 'https:/discord.com/',
    }
    const altUrlMap = {
        discord: 'discord.gg',
    }



    const socials = collection?.data.socials ? JSON.parse(collection.data.socials) : {}
    socials.website = collection?.data.url

    Object.keys(socials).map((s) => {
        if ( socials[s] && urlMap[s] ) {
            const val = socials[s]
            const prefix = s.substring(0,4).toLowerCase()
            if ( ! val.startsWith('http') && ! val.startsWith('https') ) {
                if (altUrlMap[s] && val.startsWith(altUrlMap[s])) {
                    socials[s] = 'https://' + val
                }
                else {
                    socials[s] = urlMap[s] + val
                }
            }
        }
    })

    if ( ! collection ) {
        return <></>
    }

    return (
        <Container>
            <Group my='xl' position='center'>
                { Object.keys(iconMap).map((key) => {
                    if ( ! socials[key] ) { return }
                    const Icon = iconMap[key].icon
                    const actionIcon = (
                        <ActionIcon color={theme.primaryColor} size="xl" radius="xl" variant="filled">
                            <Icon />
                        </ActionIcon>
                    )
                    return (
                        <Button
                        key={'social-button-'+key}
                        component="a"
                        href={socials[key]}
                        variant="outline"
                        color="gray"
                        rightIcon={actionIcon}
                        radius="xl"
                        size="md"
                        styles={{
                        root: { paddingRight: 0 },
                        rightIcon: { marginLeft: 22 },
                        }}
                    >
                        {iconMap[key].label}
                    </Button>
                    )
                })}
            </Group>

        </Container>
        
    )    
    
}