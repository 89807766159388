import { Avatar, Button, Card, Checkbox, Container, Group, Select, Space, Text, Title } from "@mantine/core"
import { forwardRef, useContext, useEffect, useState } from "react";
import { TabbedContent } from "../../../../../components/TabbedContent"

import { tokens, IToken } from "../../../../../config/tokens"
import Crowdsale from "../../../Crowdsale/Crowdsale";
import _ from 'lodash'
import { Api, Database, DatabaseImport, DatabaseOff } from "tabler-icons-react";
import { Balances } from "../Balances/Balances";
import { UALContext } from 'ual-reactjs-renderer';
import EnableCollectionNotifications from "../../../../../lib/EnableCollectionNotifications";
import InfiniteDataProvider, { configs, IGridConfig, IList } from '../../../../../providers/InfiniteItemProvider'

import { AAMiddleware } from '../../../../../store/AAMiddleware'
import { BackedSchemaCard } from "../../../../../components/NFT/cards/BackedSchemaCard";
import { FixedItemGrid } from "../../../../../components/NFT/FixedItemGrid";
const api = new AAMiddleware()


interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    image: string;
    label: string;
    description: string;
}

export default function UpgradeSchema({collection,onUpgraded}) {

    const ual = useContext(UALContext) as any;
    const [initialTab,setInitialTab] = useState(0)
    const [upgraded,setUpgraded] = useState(false)
    const [recheck,setRecheck] = useState(true)

    // Get the schema config
    const schemaConfig = _.cloneDeep(configs.schema) as IGridConfig
    schemaConfig.grid.gridCard = BackedSchemaCard
    schemaConfig.filters = [
        (s) => ! _.find(s.format, {name: 'backedby'})
    ]
    const backedConfig = configs.backedSchema

    const createSchemaUrl = `https://wax.atomichub.io/creator/collection/${collection}/create-schema`

    useEffect(()=>{
        const loadBackedSchemas = async () => {
            if ( ! collection ) { return }
            const backed = await api.getBackedSchemas({collection_name: collection})
            const unbacked = await api.getSchemas({collection_name: collection})
            if ( backed.length > 0 ) {
                setUpgraded(true)
                onUpgraded(true)
            }
            else {
                setUpgraded(false)
            }

            // No backed schemas, and no unbacked, show the unbacked tab (For the message)
            if ( backed.length == 0 && unbacked.length == 0 ) {
                setInitialTab(1)
            }
            // Backed schemas, show backed tab, as they can continue
            else if ( backed.length > 0 ) {
                setInitialTab(0)
            }
        }
        if ( recheck ) {
            loadBackedSchemas()
            setRecheck(false)
        }
    },[recheck])

    const tabItems = [
        { 
            label: 'Supported Schemas',
            component: InfiniteDataProvider,
            props: {
                withSearch: false,
                component: FixedItemGrid,
                title: "",
                pageSize: 20,
                config: backedConfig,
                additionalQueryParams: {collection_name:collection},
                whenEmpty: <Text align='center' size='lg' weight={700}>You haven't yet upgraded any schemas for use with NFT Backers.<br />Check 'Upgradable Schemas' above, and choose one to upgrade.</Text>
            },
            icon: Database,
            visible: true,
        },
        { 
            label: 'Upgradable Schemas',
            component: InfiniteDataProvider,
            props: {
                withSearch: false,
                component: FixedItemGrid,
                title: "",
                pageSize: 20,
                config: schemaConfig,
                additionalQueryParams: {collection_name:collection},
                whenEmpty: <Text align='center' size='lg' weight={700}>No schemas found to upgrade.<br />(You either upgraded them all, or you need to create a new schema.)</Text>
            },
            icon: DatabaseOff,
            visible: true,
        },        
    ]

    return (
        <Container px='xl' py='xl'>
            <Card my='xl' px='xl'>
                <Title order={3}>Upgrade a Schema</Title>
                <Text>
                    Your template will need to use a supported schema. You can upgrade any existing schema with the NFT Backers attribute,
                     and once upgraded, you can use it to create your template.
                </Text>
                <Text pt='xl'>If you plan to use a new schema for your backed assets, first <a href={createSchemaUrl}>create it on Atomic Assets,</a> then return here and upgrade it for NFT Backers.</Text>
                <Text pt='xl'>Upgrading a schema adds a single text attribute (<em>backedby</em>). NFT Backer uses this to determine how much backing to attach on mint.</Text>

                <Text pt='xl' weight={700}>NOTE: You will need at least one supported schema to continue.</Text>
            </Card>

            <Space py='lg' />
            <TabbedContent initialTab={initialTab} items={tabItems} onChange={() => { window.scrollTo(0,0); setRecheck(true) }} />
        </Container>        
    )
}
