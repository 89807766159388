import { useContext } from 'react';
import { UALContext } from 'ual-reactjs-renderer';

import { showNotification, updateNotification } from '@mantine/notifications';
import { Flame, AlertTriangle, Check } from 'tabler-icons-react';
import { Asset } from '@greymass/eosio';

/*
 * BackAsset - back a given asset with tokens
 * asset_id - asset ID of the asset to back
 * tokens - the tokens to back
 * tokenContract - the contract for the tokens being backed
 * ual - the UAL object to use for the RPC
 */

export default async function BackAssetFromBalance(asset, tokens, ual) {

    const auth = [{
        actor: ual.activeUser.accountName,
        permission: ual.activeUser.requestPermission,
    }]

    let actions : any = []

    // IF deposited balance <= tokens
    //      announcedepo (owner, symbol_to_announce)
    //      transfer(from, to, quantity, memo)
    // announceback (payer, owner, collection, asset_id, symbol_to_announce)
    // backasset (payer, owner, collection, asset_id, symbol_to_announce)

    const t = Asset.from(tokens)

    // announceback (payer, owner, collection, asset_id, symbol_to_announce)
    actions.push({
        account: process.env.REACT_APP_NFTBACKER_CONTRACT,
        name: 'announceback',
        authorization: auth,
        data: {
            payer: ual.activeUser.accountName,
            owner: asset.owner,
            collection: asset.collection.collection_name,
            asset_id: asset.asset_id,
            symbol_to_announce: t.symbol.toString(),
        }
    })

    // backasset (payer, owner, collection, asset_id, symbol_to_announce)
    actions.push({
        account: process.env.REACT_APP_NFTBACKER_CONTRACT,
        name: 'backasset',
        authorization: auth,
        data: {
            payer: ual.activeUser.accountName,
            owner: asset.owner,
            collection: asset.collection.collection_name,
            asset_id: asset.asset_id,
            token_to_back: tokens.toString(),
        }
    })

    try {
        showNotification({
            id: `back-asset-from-balance-${asset.asset_id}`,
            loading: true,
            title: `Backing NFT ${asset.asset_id}`,
            message: 'Preparing to back this asset.',
            autoClose: false,
            disallowClose: true,
            icon: <Flame />,
        });

        const res = await ual.activeUser.signTransaction(
            {
                actions: actions
            },
            {
                blocksBehind: 3,
                expireSeconds: 60
            }
        );
        updateNotification({
            id: `back-asset-from-balance-${asset.asset_id}`,
            color: 'teal',
            title: `Success!`,
            message: `Asset ${asset.asset_id} was successfully backed.`,
            icon: <Check />,
            autoClose: 10000,
        });
    } catch (e : any ) {
        console.log(e);

        const msg = e.message.replace('assertion failure with message:','');

        updateNotification({
            id: `back-asset-from-balance-${asset.asset_id}`,
            color: 'red',
            title: `Unable to back asset ${asset.asset_id}`,
            message: msg,
            icon: <AlertTriangle />,
            autoClose: false,
        });
    }

}