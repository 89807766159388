import { Route, Routes } from 'react-router-dom'

// icons
import { LayoutCards, GitPullRequest, AlertCircle, Messages, Database, UserSearch, Icon, Search as SearchIcon, File, LayoutGrid, Stack, Home as HomeIcon, Help, Stack2, ShieldCheck } from 'tabler-icons-react';

// pages
import {Inventory} from './pages/nftbacker/Inventory'
import { Backed } from './pages/nftbacker/Backed'
import { Backers } from './pages/nftbacker/Backers'
import { Details } from './pages/nftbacker/Details';
import { Collection } from './pages/nftbacker/Collection';
import { Collections } from './pages/nftbacker/Collections'
import { Browser } from './pages/nftbacker/Browser';
import { Templates } from './pages/nftbacker/Templates';
import { About } from './pages/nftbacker/About';
import { Home } from './pages/nftbacker/Home/Home'

import { Enroll } from './pages/nftbacker/User/Enroll'

// User management
import { Main as UserMain } from './pages/nftbacker/User/Main/Main';

import {NotFound} from './pages/NotFound'

import { MainLinkProps } from './components/AppShell/_mainLinks';

// Atomic Assets API
import { Template } from './pages/nftbacker/Template';
import { Drop } from './pages/nftbacker/Drop';
import { CreateTemplate } from './pages/nftbacker/User/CreateTemplate';
import { CollectionManage } from './pages/nftbacker/User/CollectionManage';
import { BackAssetForm } from './pages/nftbacker/User/Creator/BackAssetForm';
import { RoutableCrowdsale } from './pages/nftbacker/Crowdsale/RoutableCrowdsale';
import VerifyBacking from './pages/nftbacker/VerifyBacking';


interface IRouteItem {
    icon: Icon;
    label: string;
    initiallyOpened: boolean;
    links: Array<MainLinkProps>;
}

// export interface MainLinkProps {
//     icon: React.ReactNode;
//     label: string;
//     path: string;
//     component: React.ReactNode;
//     authRequired: boolean;
//     onClick?(): any;
//   }


export const RouteList = () => {
    return [
        { icon: HomeIcon, label: 'Home', path: '/', component: <Home />, authRequired: false },
        { icon: ShieldCheck, label: 'NFT Backers', path: '/explorer/backers', component: <Backers />, authRequired: false, accent: true },
        { icon: UserSearch, label: 'Explore', initiallyOpened: true,
            links: [
                { icon: Stack2, label: 'Collections', path: '/explorer/collections', component: <Collections />, authRequired: false },
                { icon: File, label: 'Templates', path: '/explorer/templates', component: <Templates />, authRequired: false },
            ]
        },
        { icon: LayoutCards, label: 'My Backed Assets', path: '/backed', component: <Backed />, authRequired: true },
        { icon: LayoutGrid, label: 'My Inventory', path: '/inventory', component: <Inventory />, authRequired: true },
        { icon: Help, label: 'About', path: '/about', component: <About />, authRequired: false },
    ];
}

export const SupportingRoutes = () => {
    return [
        // Explorer
        { path: '/explorer/asset/:id', color: 'blue', component: <Details /> },
        { path: '/explorer/template/:id', color: 'blue', component: <Template /> },
        { path: '/explorer/drop/:type/:id', color: 'blue', component: <Drop /> },
        { path: '/explorer/collection/:name', color: 'blue', component: <Collection /> },

        { path: '/creator/collection/:name/enroll', color: 'blue', component: <Enroll /> },
        { path: '/creator/collection/:name/backasset', color: 'blue', component: <BackAssetForm />  },
        { path: '/creator/collection/:name/template', color: 'blue', component: <CreateTemplate /> },
        { path: '/creator/collection/:name', color: 'blue', component: <CollectionManage /> },
        { path: '/creator/collection/:name/:mode', color: 'blue', component: <CollectionManage /> },
        { path: '/creator/buy/:symbol', color: 'blue', component: <RoutableCrowdsale /> },

        { path: '/verify', color: 'blue', component: <VerifyBacking /> },
        { path: '/verify/:asset_id', color: 'blue', component: <VerifyBacking /> },

        // User
        { path: '/account', color: 'blue', component: <UserMain /> },
    ]
}

export const AppRoutes = () => {    
    return (
        <Routes>
            {
                RouteList().map((link) => {
                    if ( link.links ) {
                        return link.links.map((item) => {
                            return (
                                <Route path={item.path} element={item.component} key={item.path} />
                            )
                        })
                    }
                    else {
                        return (
                            <Route path={link.path} element={link.component} key={link.path} />
                        )
                    }
                })
            }
            {
                SupportingRoutes().map((link) => <Route path={link.path} element={link.component} key={link.path} />)
            }
            <Route path='*' element={<NotFound />} />
        </Routes>
    )
}

