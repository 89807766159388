import { Space, Grid, Chip, Card, Image, Text, Badge, Button, Group, useMantineTheme, Anchor, Container } from '@mantine/core';

import LinesEllipsis from 'react-lines-ellipsis'

import { IToken } from '../../../config/tokens';
import { Check } from 'tabler-icons-react';

interface IBalanceCardProps {
  token: IToken;
  balance: string;
  balanceAsset: any;
  buttons: any;
  selected?: boolean;
  onClick?: any;
}

export function BalanceCard({token, balance, balanceAsset, buttons, selected, onClick} : IBalanceCardProps) {
    const theme = useMantineTheme();
    const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];
  
    const imgSrc = token.image
  
    const image =  (
      <Image
        src={imgSrc}
        alt={token.name}
        style={{ maxWidth:100 }} />
    )

    const selectedIcon = selected ? ( <Check size={50} color='green' /> ) : (<></>)
 
    return (
      <Card radius="lg" p="lg" onClick={onClick}>

        <Group position="apart" grow>
          {image}
          <Container>
            <Text weight={700} size='md'>{token.name}</Text>
            <Text weight={500} size='sm'>{balance}</Text>
            <Text weight={500} size='xs' color={secondaryColor}>
            <LinesEllipsis
              text={token.description}
              maxLine='3'
              basedOn='words'
              />
          </Text>
          </Container>
          {selectedIcon}
        </Group>

        <Group position="center" style={{ marginBottom: theme.spacing.md, marginTop: 0 }}>
        </Group>

        {
          buttons.map((b,i) => {
            return (
              <Button key={i} leftIcon={b.icon} variant="light" color="blue" fullWidth style={{ marginTop: 14 }} onClick={b.onClick}>
                {b.label}
              </Button>
            )
          })
        }
      </Card>    
  
    )
  }