import { useState } from 'react';
import { Group, Text, Card, Image, Skeleton, Avatar, useMantineTheme, Stack } from '@mantine/core';
import ImageUpload from './ImageUpload';
import { hashOnly, resolveUrl } from '../lib/ImageHelper';

import _ from 'lodash'


const imageConfig = { fullHeight: 300, thumbHeight: 40 }

export function ImageUploadManager({values,onUpdateImage}) {

    const theme = useMantineTheme();
    const fields = Object.keys(values)

    // Form management
    const [uploadedImages,setUploadedImages] = useState({} as any)
    const [selectedImage,setSelectedImage] = useState(0)

    const secondaryColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[1]
      : theme.colors.gray[7];

    const accentColor = theme.colorScheme === 'dark'
      ? theme.colors.blue[0]
      : theme.colors.blue[5];

    const lightTextColor = theme.colorScheme === 'dark'
      ? theme.colors.gray[1]
      : theme.colors.gray[7];

    const lighterTextColor = theme.colorScheme === 'dark'
      ? theme.colors.gray[3]
      : theme.colors.gray[5];    


    const openImageModal = () => {
        console.log('openImageModal')
        // const id = modals.openModal({
        //   withCloseButton: false,
        //   children: (
        //     <Image 
        //         withPlaceholder 
        //         src={process.env.REACT_APP_IMAGE_PROXY + getImages()[selectedImage]} 
        //         alt={asset?.name}
        //         onClick={modals.closeAll}
        //     />
        //   ),
        // });
    };

    const thumbs = fields.map((img,i)=>{
        const click = () => {
            setSelectedImage(i)
        }

        return (
            <Stack align='center' key={`thumb-${i}`} >
                <Avatar 
                    size={imageConfig.thumbHeight}
                    src={resolveUrl( uploadedImages[fields[i]], 'h='+imageConfig.thumbHeight)}
                    radius={imageConfig.thumbHeight} 
                    style={{border: ( selectedImage == i ? '1px' : '0px' ) + ' solid ' + theme.colors.gray[5]}}
                    onClick={click}
                />
                <Text size='xs'>{ fields[i] }</Text>
            </Stack>
        )
    })

    const updateImage = (idx,url) => {
        const newUploadedImages = Object.assign({},uploadedImages)
        newUploadedImages[fields[idx]] = url
        setUploadedImages(newUploadedImages)

        // Notify parent component
        if ( onUpdateImage && typeof onUpdateImage === 'function' ) {
            const hash = hashOnly(url)
            onUpdateImage(fields[idx],hash)
        }
    }

    const getSelectedImageField = () => {
        return fields[selectedImage]
    }

    const getSelectedImageUrl = () => {
        return uploadedImages[getSelectedImageField()]
    }

    return (
        uploadedImages ? 
            <>
                <Card>
                    {/* <Card.Section> */}
                        {
                            uploadedImages[selectedImage] ?
                            <Image 
                            fit="contain"
                            height={imageConfig.fullHeight}
                            withPlaceholder
                            placeholder={<Text>Upload Image</Text>}
                            radius="lg" 
                            src={resolveUrl(values[fields[selectedImage]],'h='+imageConfig.fullHeight)}
                            // src={process.env.REACT_APP_IMAGE_PROXY + uploadedImages[selectedImage] + '?h='+imageConfig.fullHeight} 
                            onClick={openImageModal}
                        />
                            :
                            <ImageUpload details={false} key={selectedImage} defaultUrl={getSelectedImageUrl()} setUrl={(url)=> {updateImage(selectedImage,url)}} />                            
                        }
                    {/* </Card.Section> */}
                </Card>
                <Group position="center" style={{ marginBottom: theme.spacing.lg, marginTop: theme.spacing.lg }}>
                    {thumbs}
                </Group>
            </>
        : <>
            <Skeleton height={140} radius="md" animate={false}></Skeleton>
            <Text>Click to Upload</Text>
        </>
    )


}