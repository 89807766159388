

export function Price({price,settlement,qty=1}) {
    if ( ! price ) { return <></> }
    const [ amount, symbol ] = price.split(' ')

    const decimals = ( symbol == 'USD' ) ? 2 : 8

    const total = (amount*qty).toFixed(decimals)

    const quoted = (symbol == 'USD') ? '$' + total : total + ' ' + symbol
    const converted = ''

    return (
        <>{quoted} {converted}</>
    )
}