
import { Image } from '@mantine/core';
import { resolveUrl } from '../../../lib/ImageHelper';

export function CardImageFull(props) {

    const {src,alt} = props

    let imgSrc = '';
    let hash = '';

    const height = 300
    const params = '?tr=h-' + height

    if ( ! src ) {
        return (
            <Image 
            height={height}
            withPlaceholder 
            radius={0}
            src={''} 
            alt={alt}
            fit='cover'
            onClick={props.onClick} />
        )
    }

    imgSrc = resolveUrl( src, params )

    return (
    <Image 
        height={height}
        withPlaceholder 
        radius={0}
        src={imgSrc} 
        alt={alt}
        fit='cover'
        onClick={props.onClick} />

    )
}