import { Space, Grid, Chip, Card, Image, Text, Badge, Button, Group, useMantineTheme, Anchor } from '@mantine/core';
import { IAsset } from 'atomicassets/build/API/Explorer/Objects';
import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Id, User } from 'tabler-icons-react';
import { CardImage } from './CardImage';
import { CardImageFull } from './CardImageFull';
import { IGenericCardProps } from './GenericCard';

interface IAssetCardProps extends IGenericCardProps {
  item: IAsset;
}

export function AssetCard({item, buttons, options, path} : IAssetCardProps) {
  const theme = useMantineTheme();
  const secondaryColor = theme.colorScheme === 'dark'
  ? theme.colors.dark[1]
  : theme.colors.gray[7];

  if ( ! buttons ) { buttons = [] }
  if ( ! options ) { options = {} }

  const toPath = path + item.asset_id
  const image =  (
    <CardImage
      src={item.data.img}
      alt={item.data.name}
      onClick={()=>{}}
      />
  )

  return (
    <Card radius="lg" shadow={options.shadow} p="lg">

      <Card.Section>
          <Group position="center">
              { options.nolink ?
                  image
                  :   <Anchor component={Link} to={toPath}>
                        {image}
                      </Anchor>
              }
          </Group>
      </Card.Section>

      <Group position='apart'>
        <div>
          <Group position='left' style={{ marginBottom:0, marginTop: 0 }}>
            <Text align='left' pt='xl' pb={0} px='md' size='xs' weight={700} color={theme.colors.dark[3]}>{item.collection.name}</Text> 
          </Group>
          <Group position='left' style={{ marginBottom: theme.spacing.md, marginTop: 0 }}>
            <Text
                style={{
                  overflow: 'hidden',
                  maxWidth: '30ch',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }} 
                align='left' pt={0} px='md' size='xs' weight={700} color={theme.colors.dark[5]}>{item.name}</Text> 
          </Group>
        </div>
        <div>
          
          <Text size='xs' align='right'>
            Mint
          </Text>
          <Text size='sm' weight={700} align='right'>{item.template_mint}</Text>
        </div>

      </Group>

      <Group position="center" >
        <Id size={16} />
        <Anchor component={Link} to={toPath}><Text weight={500} size='sm'>{item.asset_id}</Text></Anchor>
        
      </Group>

      {/* <Group position="center">
        <User size={16} />
        <Text weight={500} size='sm'>
          {item.owner}
        </Text>
      </Group> */}

      {/* <Group position="center" style={{ marginBottom: theme.spacing.md, marginTop: theme.spacing.sm }}>
        <Text weight='{500}'>
          {item.immutable_data.name} <em> - #{item.template_mint}</em>
        </Text>
      </Group> */}

      {
        buttons.map((b,i) => {
          return (
            <Button key={i} leftIcon={b.icon} variant="light" color="blue" fullWidth style={{ marginTop: 14 }} onClick={b.onClick}>
              {b.label}
            </Button>
          )
        })
      }
    </Card>    

  )
}
