import { nameToUint64 } from 'eosjs-account-name'

export const getLowerBound256 = (name : string) => {
    let enc = nameToUint64(name);
    return dec2hex(enc).padStart(32,'0')
        + '00000000000000000000000000000000';
}

export const getUpperBound256 = (name : string) => {
    let enc = nameToUint64(name);
    return dec2hex(enc).padStart(32,'0')
        + 'ffffffffffffffffffffffffffffffff';
}

export const dec2hex = (str) => { 
    // .toString(16) only works up to 2^53
    var dec = str.toString().split('')
 
    const sum = [] as any
    const hex = [] as any
    let i
    let s

    while(dec.length){
        s = 1 * dec.shift()
        for(i = 0; s || i < sum.length; i++){
            s += (sum[i] || 0) * 10
            sum[i] = s % 16
            s = (s - sum[i]) / 16
        }
    }
    while(sum.length){
        hex.push(sum.pop().toString(16))
    }
    return hex.join('')
}
