import React from 'react';
import { createStyles, Title, SimpleGrid, Text, Button, ThemeIcon, Grid, Col, Container } from '@mantine/core';
import { ReceiptOff, Flame, CircleDotted, FileCode, Bulb, Businessplan, Bolt, BuildingBank } from 'tabler-icons-react';
import { Link } from 'react-router-dom';


const useHeroStyles = createStyles((theme) => ({
    root: {
      backgroundColor: '#11284b',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundImage:
        'linear-gradient(250deg, rgba(0, 0, 0, 0) 0%, #000 90%), url(https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1080&q=80)',
      paddingTop: theme.spacing.xl * 3,
      paddingBottom: theme.spacing.xl * 3,
    },
  
    inner: {
      display: 'flex',
      justifyContent: 'space-between',
  
      [theme.fn.smallerThan('md')]: {
        flexDirection: 'column',
      },
    },
  
    image: {
      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },
    },
  
    content: {
      paddingTop: theme.spacing.xl * 2,
      paddingBottom: theme.spacing.xl * 2,
      marginRight: theme.spacing.xl * 3,
  
      [theme.fn.smallerThan('md')]: {
        marginRight: 0,
      },
    },
  
    title: {
      color: theme.white,
      fontFamily: `Poppins, Greycliff CF, ${theme.fontFamily}`,
      fontWeight: 900,
      lineHeight: 1.05,
      maxWidth: 500,
      fontSize: 48,
      paddingLeft: theme.spacing.xl * 2,
  
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
        fontSize: 34,
        lineHeight: 1.15,
      },
    },
  
    description: {
      color: theme.white,
      opacity: 0.75,
      maxWidth: 500,
  
      [theme.fn.smallerThan('md')]: {
        maxWidth: '100%',
      },
    },
  
    control: {
      paddingLeft: 50,
      paddingRight: 50,
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontSize: 22,
  
      [theme.fn.smallerThan('md')]: {
        width: '100%',
      },
    },
  }));


const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: `${theme.spacing.xl * 2}px ${theme.spacing.xl}px`,
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: 36,
    fontWeight: 900,
    lineHeight: 1.1,
    marginBottom: theme.spacing.md,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
  },
}));

const features = [
  {
    icon: BuildingBank,
    title: 'Value Stays with the NFT',
    description: 'All backed tokens stay locked up in the NFT until it is burned.',
  },
  {
    icon: Bolt,
    title: 'Distinguish your collection!',
    description: 'As a creator, set your collection apart with real-world NFT backing.',
  },
  {
    icon: Businessplan,
    title: 'Value on-chain and off',
    description: 'Backing an NFT with real-world assets instantly increases its value.',
  },
  {
    icon: Bulb,
    title: 'Get Creative',
    description: 'Value-backed in-game assets, create a price floor, rewards, and more!',
  },
];

export function About() {
  const { classes } = useStyles();
  const heroClasses = useHeroStyles().classes;

  const items = features.map((feature) => (
    <div key={feature.title}>
      <ThemeIcon
        size={44}
        radius="md"
        variant="gradient"
        gradient={{ deg: 133, from: 'blue', to: 'cyan' }}
      >
        <feature.icon size={26} />
      </ThemeIcon>
      <Text size="lg" mt="sm" weight={500}>
        {feature.title}
      </Text>
      <Text color="dimmed" size="sm">
        {feature.description}
      </Text>
    </div>
  ));

  return (


<>
    <div className={heroClasses.root}>
      <Container size="lg">
        <div className={heroClasses.inner}>
          <div className={heroClasses.content}>
            <Title className={heroClasses.title}>
              Bringing {' '}
              <Text
                component="span"
                inherit
                variant="gradient"
                gradient={{ from: 'blue', to: 'white' }}
              >
                real-world value
              </Text>{' '}
              to the WAX blockchain
            </Title>

            {/* <Text className={heroClasses.description} mt={30}>
              Build fully functional accessible web applications with ease – Mantine includes more
              than 100 customizable components and hooks to cover you in any situation
            </Text>

            <Button
              variant="gradient"
              gradient={{ from: 'pink', to: 'yellow' }}
              size="xl"
              className={heroClasses.control}
              mt={40}
            >
              Get started
            </Button> */}
          </div>
        </div>
      </Container>

    </div>

    <Container px='xl' py='xl'>
        <div className={classes.wrapper}>
        <Grid gutter={80}>
            <Col span={12} md={5}>
            <Title className={classes.title} order={2}>
                A new standard for WAX
            </Title>
            <Text color="dimmed">
                We take ordinary Atomic Assets NFTs and back them with real value – attach
                just about any fungible token to any Atomic Asset. The value stays with the
                NFT until it is burned, at which point the tokens are unlocked and transferred
                to the burner's account. Simple, but powerful. 
            </Text>

            <Button
                variant="filled"
                size="lg"
                radius="md"
                mt="xl"
                component={Link}
                to='/explorer/backers'
            >
                Get started
            </Button>
            </Col>
            <Col span={12} md={7}>
            <SimpleGrid cols={2} spacing={30} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
                {items}
            </SimpleGrid>
            </Col>
        </Grid>
        </div>
    </Container>





</>

  );
}