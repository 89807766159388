import { useParams } from "react-router-dom";
import Crowdsale from "./Crowdsale";

import { tokens } from "../../../config/tokens";
import _ from 'lodash'
import { Image, Title, Container } from "@mantine/core";

export function RoutableCrowdsale() {
    const { symbol } = useParams()

    const token = _.find(tokens, (t) => t.symbol == symbol?.toUpperCase())

    if ( ! symbol || ! token ) {
        return ( <></> )
    }
    return (
        <Container px='xl' py='xl'>
            <Title pt='xl' align='center' order={1} style={{fontSize:'3.5em'}}>Buy {token.name}</Title>
            <Crowdsale symbol={token.symbol} />
        </Container>
    )
}