import { Space, Grid, Chip, Card, Image, Text, Badge, Button, Group, useMantineTheme, Anchor } from '@mantine/core';
import { ITemplate } from 'atomicassets/build/API/Explorer/Objects';

import { useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IElasticTemplate } from 'nftbackers-elastic-interfaces'
import { resolveUrl } from '../../../lib/ImageHelper';
import { CardImage } from './CardImage';
import { IGenericCardProps } from './GenericCard';
import { tokens } from '../../../config/tokens';
import _ from 'lodash'

export function PreviewTemplateCard({item}) {
    const theme = useMantineTheme();
    const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];

    const maxSupply = parseInt(item.max_supply) == 0 ? (<>&infin;</>) : item.max_supply

    return (
      <Card radius="lg" p="lg">
        <Card.Section>
            <Group position="center">
              <CardImage src={resolveUrl(item.img??'')} alt={item.name} onClick={()=>{}} />
            </Group>
        </Card.Section>
                 
        <Group position='apart'>
          <div>
            <Group position='left' style={{ marginBottom:0, marginTop: 0 }}>
              <Text align='left' pt='xl' pb={0} px='md' size='xs' weight={700} color={theme.colors.dark[3]}>{item.collection}</Text> 
            </Group>
            <Group position='left' style={{ marginBottom: theme.spacing.md, marginTop: 0 }}>
              <Text
                style={{
                  overflow: 'hidden',
                  maxWidth: '25ch',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }} 
                align='left' pt={0} px='md' size='xs' weight={700} color={theme.colors.dark[5]}>{item.name}</Text> 
            </Group>
          </div>
          <div>
            <Badge color='blue' variant="light">
              {item.backedby}
            </Badge>
          </div>

        </Group>
      </Card>    
  
    )
  }