import React, { useContext } from 'react';
import {useState} from 'react'

import { ModalsProvider } from '@mantine/modals';
import { AppShell, MantineProvider, ColorSchemeProvider, ColorScheme, useMantineTheme, LoadingOverlay } from '@mantine/core';
import { Navbar, Text, Header, MediaQuery, Burger} from '@mantine/core';
import { Brand } from './components/AppShell/_brand'
import { MainLinks } from './components/AppShell/_mainLinks'
import { User } from './components/AppShell/_user'

import { GlobalStateProvider, useGlobalState } from './context/GlobalState';

import {AppRoutes} from './Routes'
import {BrowserRouter as Router} from 'react-router-dom'

import './App.css';
import { NotificationsProvider } from '@mantine/notifications';
import ScrollToTop from './components/ScrollToTop';

function App(props) {

  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [colorScheme, setColorScheme] = useState<ColorScheme>('light');

  const { state, dispatch } = useGlobalState()
  const { isLoading } = state

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  const closeMenu = () => {
    setOpened(false)
  }

  return (
      <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
        <MantineProvider theme={{ colorScheme }}>
          <ModalsProvider>
            <NotificationsProvider>
              <Router>
                <ScrollToTop />
                <GlobalStateProvider>
                  <LoadingOverlay
                      loaderProps={{ size: 'xl', variant: 'bars' }}
                      overlayOpacity={1.0}
                      overlayColor="#c5c5c5"
                      visible={isLoading ?? false}
                      transitionDuration={500}
                  />
                  <AppShell
                    navbarOffsetBreakpoint="xl"
                    fixed
                    padding={0}
                    navbar={
                      <Navbar
                        p="md"
                        // Breakpoint at which navbar will be hidden if hidden prop is true
                        hiddenBreakpoint="xl"
                        // Hides navbar when viewport size is less than value specified in hiddenBreakpoint
                        hidden={!opened}
                        // when viewport size is less than theme.breakpoints.sm navbar width is 100%
                        // viewport size > theme.breakpoints.sm – width is 300px
                        // viewport size > theme.breakpoints.lg – width is 400px
                        width={{ sm: 300, lg: 300 }}
                      >
                        <Navbar.Section grow mt="md">
                          <MainLinks onClose={closeMenu} />
                        </Navbar.Section>
                        <Navbar.Section>
                          <User onClose={closeMenu} />
                        </Navbar.Section>
                      </Navbar>
                    }
                    header={
                      <Header height={70} p="md">
                        {/* Handle other responsive styles with MediaQuery component or createStyles function */}
                        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                          <MediaQuery largerThan="xl" styles={{ display: 'none' }}>
                              <Burger
                              opened={opened}
                              onClick={() => setOpened((o) => !o)}
                              size="sm"
                              color={theme.colors.gray[6]}
                              mr="xl"
                              />
                          </MediaQuery>
                          <Brand />
                        </div>
                      </Header>
                    }
                    styles={(theme) => ({
                      main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
                    })}
                  >
                    <AppRoutes />
                  </AppShell>
                </GlobalStateProvider>
              </Router>
            </NotificationsProvider>
          </ModalsProvider>
        </MantineProvider>
      </ColorSchemeProvider>
  );
}

export default App;
