// import { IInfiniteItemGridProps } from '../components/NFT/InfiniteItemGrid'

import { AssetCard } from "../components/NFT/cards/AssetCard"
import { CollectionCard } from "../components/NFT/cards/CollectionCard"
import { TemplateCard } from "../components/NFT/cards/TemplateCard"
import { SimpleTemplateCard } from "../components/NFT/cards/SimpleTemplateCard"
import { AAMiddleware } from "../store/AAMiddleware"

import { rpc } from '../';

import { IInfiniteItemGridProps, IInifiteDataProvider, InfiniteItemGrid } from "../components/NFT/InfiniteItemGrid"
import { FunctionComponent, useContext, useEffect, useState } from "react"

import chains from '../components/Blockchains/assets/blockchains.json'
import { find } from 'lodash'
import { ICollection, ITemplate } from "atomicassets/build/API/Explorer/Objects"
import { IStatsTemplate } from "../store/AAMarketPost"
import { IElasticCollection, IElasticTemplate } from 'nftbackers-elastic-interfaces'

import { Button, Group, Input, Select, Title } from "@mantine/core";
import { Search } from "tabler-icons-react";
import { useForm } from "@mantine/hooks";
import { BackedAssetCard } from "../components/NFT/cards/BackedAssetCard"
import { DropCard } from "../components/NFT/cards/DropCard"
import { SchemaCard } from "../components/NFT/cards/SchemaCard"
import { BackedSchemaCard } from "../components/NFT/cards/BackedSchemaCard"
import { ISchema } from "atomicassets/build/Schema"
const chain = find(chains, {key: process.env.REACT_APP_CHAIN })

const api = new AAMiddleware()

export interface IQueryCallback {
    (q : any, page : number, limit : number) : any
}

export interface IQuerySelector {
    (query:any,sort:string) : IQueryCallback;
}

export interface IQueryParamsCallback {
    (lists:IList[]) : any
}

export interface IColSort {
    value: string;
    label: string;
}

export interface IFilterOption {
    field: string;
    value: string;
}

export interface IGridConfig {
    grid: Omit<IInfiniteItemGridProps, 'provider'>;
    sortColumns: IColSort[];
    query?: IQueryCallback;
    querySelector?: IQuerySelector;
    getQueryParams: IQueryParamsCallback;
    filters: IFilter[];
}

export interface IGridConfigs {
    backer: IGridConfig;
    collection: IGridConfig;
    collectionSearch: IGridConfig;
    template: IGridConfig;
    backedTemplate: IGridConfig;
    templateSearch: IGridConfig;
    backedTemplateSearch: IGridConfig;
    asset: IGridConfig;
    backedAsset: IGridConfig;
    account: IGridConfig;
    neftyDrops: IGridConfig;
    schema: IGridConfig;
    backedSchema: IGridConfig;
}

export interface IList {
    list_name: string;
    list: string[];
}
  
export interface IFilter {
    (o : any) : boolean;
}

export interface IInifiteDataProviderProps {
    pageSize: number;
    config: IGridConfig;
    additionalQueryParams?: any;
    filterOptions?: IFilterOption[];
    component?: any;
    title?: any;
    withSearch?: boolean;
    whenEmpty?: any;
}


// TODO:  Add these white/blacklist builders to the queryParams below!
        // // Add collection lists
        // if ( ['collection', 'template', 'account'].includes(type) ) {
        //     let nsfw = lists.find( l => l.list_name == 'col.nsfw')?.list ?? []
        //     let wl = lists.find(l => l.list_name == 'col.wlist')?.list ?? []
        //     let bl = lists.find(l => l.list_name == 'col.blist')?.list ?? []
        //     bl = [...bl, ...nsfw]
        //     query['collection_whitelist'] = wl
        //     query['collection_blacklist'] = bl
        //   }
        //   // Add template lists
        //   if ( type == 'asset' ) {
        //     let wl = lists.find(l => l.list_name == 'tmpl.wlist')?.list ?? []
        //     let bl = lists.find(l => l.list_name == 'tmpl.wlist')?.list ?? []
        //     query['template_whitelist'] = wl
        //     query['template_blacklist'] = bl
        //   }

export const configs : IGridConfigs = {
    backer: {
        sortColumns: [
            // {value: 'popularity:', label:'Popularity'},
            // {value: 'collection_name:asc', label: 'Name (A-Z)'},
            // {value: 'collection_name:desc', label: 'Name (Z-A)'},
            // {value: 'created:asc', label:'Created'},      
        ],
        grid: {
            gridCard: CollectionCard,
            buttons: [],
            itemBaseUri: '/explorer/collection/',                    
        },
        query: api.getBackedCollections,
        // querySelector: (q:any, sort:string) => sort.startsWith('popularity') ? api.getCollectionMarketStats: api.getCollections,
        filters: [
            // Must have an image
            (c : ICollection) => !! c.img,
        ],
        getQueryParams: (lists) => {
            // let nsfw = lists.find( l => l.list_name == 'col.nsfw')?.list ?? []
            // let wl = lists.find(l => l.list_name == 'col.wlist')?.list ?? []
            // let bl = lists.find(l => l.list_name == 'col.blist')?.list ?? []
            // bl = [...bl, ...nsfw]
            // return {
            //   collection_whitelist: wl.join(','),
            //   collection_blacklist: bl.join(','),
            // }
            return { collection_whitelist: ['ubersteve123','earlyibmfans'].join(',') }
        }
    },
    collection: {
        sortColumns: [
            {value: 'popularity:', label:'Popularity'},
            {value: 'collection_name:asc', label: 'Name (A-Z)'},
            {value: 'collection_name:desc', label: 'Name (Z-A)'},
            {value: 'created:asc', label:'Created'},      
        ],
        grid: {
            gridCard: CollectionCard,
            buttons: [],
            itemBaseUri: '/explorer/collection/',    
        },
        querySelector: (q:any, sort:string) => sort.startsWith('popularity') ? api.getCollectionMarketStats: api.getCollections,
        filters: [
            // Must have an image
            (c : ICollection) => !! c.img,
        ],
        getQueryParams: (lists) => {
            let nsfw = lists.find( l => l.list_name == 'col.nsfw')?.list ?? []
            let wl = lists.find(l => l.list_name == 'col.wlist')?.list ?? []
            let bl = lists.find(l => l.list_name == 'col.blist')?.list ?? []
            bl = [...bl, ...nsfw]
            return {
              collection_whitelist: wl.join(','),
              collection_blacklist: bl.join(','),
            }
        }
    },
    collectionSearch: {
        sortColumns: [
        ],
        grid: {
            gridCard: CollectionCard,
            buttons: [],
            itemBaseUri: '/explorer/collection/',    
        },
        query: api.collectionSearch,
        filters: [
            // Must have an image
            (c : IElasticCollection) => !! c.img,
        ],
        getQueryParams: (lists) => { return {} }
    },
    template: {
        sortColumns: [
            {value: 'popularity:', label:'Popularity'},
            {value: 'name:asc', label: 'Name (A-Z)'},
            {value: 'name:desc', label: 'Name (Z-A)'},
            {value: 'created:asc', label:'Created (Oldest)'},
            {value: 'created:desc', label:'Created (Newest)'},
        ],
        grid: {
            gridCard: TemplateCard,
            buttons: [],
            itemBaseUri: '/explorer/template/',
        },
        querySelector: (q:any, sort:string) => sort.startsWith('popularity') ? api.getTemplateMarketStats : api.getTemplates,
        filters: [
            // Must have an image
            (t : IStatsTemplate) => !! t.template.immutable_data.img,
        ],
        getQueryParams: (lists) => {
            let nsfw = lists.find( l => l.list_name == 'col.nsfw')?.list ?? []
            let wl = lists.find(l => l.list_name == 'col.wlist')?.list ?? []
            let bl = lists.find(l => l.list_name == 'col.blist')?.list ?? []
            bl = [...bl, ...nsfw]
            return {
              collection_whitelist: wl.join(','),
              collection_blacklist: bl.join(','),
            }
        }
    },
    backedTemplate: {
        sortColumns: [
            {value: 'name:asc', label: 'Name (A-Z)'},
            {value: 'name:desc', label: 'Name (Z-A)'},
            {value: 'created:asc', label:'Created (Oldest)'},
            {value: 'created:desc', label:'Created (Newest)'},
        ],
        grid: {
            gridCard: TemplateCard,
            buttons: [],
            itemBaseUri: '/explorer/template/',
        },
        query: api.getBackedTemplatesAA,
        filters: [
            // Must have an image
            (t : ITemplate) => !! t.immutable_data.img,
        ],
        getQueryParams: (lists) => {
            let nsfw = lists.find( l => l.list_name == 'col.nsfw')?.list ?? []
            let wl = lists.find(l => l.list_name == 'col.wlist')?.list ?? []
            let bl = lists.find(l => l.list_name == 'col.blist')?.list ?? []
            bl = [...bl, ...nsfw]
            return {
              collection_whitelist: wl.join(','),
              collection_blacklist: bl.join(','),
            }
        }
    },
    templateSearch: {
        sortColumns: [
        ],
        grid: {
            gridCard: TemplateCard,
            buttons: [],
            itemBaseUri: '/explorer/template/',    
        },
        query: api.templateSearch,
        filters: [
            // Must have an image
            (c : IElasticTemplate) => !! c.img,
        ],
        getQueryParams: (lists) => { return {} },
    },
    backedTemplateSearch: {
        sortColumns: [
        ],
        grid: {
            gridCard: TemplateCard,
            buttons: [],
            itemBaseUri: '/explorer/template/',    
        },
        query: api.templateSearch,
        filters: [
            // Must have an image
            (c : IElasticTemplate) => !! c.img,
        ],
        getQueryParams: (lists) => { return {} },
    },
    asset: {
        sortColumns: [
            // {value: 'popularity:', label:'Popularity'},
            {value: 'name:asc', label: 'Name (A-Z)'},
            {value: 'name:desc', label: 'Name (Z-A)'},
            {value: 'created:asc', label:'Created (Oldest)'},
            {value: 'created:desc', label:'Created (Newest)'},
        ],
        grid: {
            gridCard: AssetCard,
            buttons: [],
            itemBaseUri: '/explorer/asset/',
        },
        query: api.getAssets,
        filters: [],
        getQueryParams: (lists) => {
            let wl = lists.find(l => l.list_name == 'tmpl.wlist')?.list ?? []
            let bl = lists.find(l => l.list_name == 'tmpl.wlist')?.list ?? []
            return {
                template_whitelist: wl.join(','),
                template_blacklist: bl.join(','),
            }
        }
    },
    backedAsset: {
        sortColumns: [
            // {value: 'popularity:', label:'Popularity'},
            {value: 'name:asc', label: 'Name (A-Z)'},
            {value: 'name:desc', label: 'Name (Z-A)'},
            {value: 'created:asc', label:'Created (Oldest)'},
            {value: 'created:desc', label:'Created (Newest)'},
        ],
        grid: {
            gridCard: BackedAssetCard,
            buttons: [],
            itemBaseUri: '/explorer/asset/',
        },
        query: api.getBackedAssets,
        filters: [],
        getQueryParams: (lists) => {
            let wl = lists.find(l => l.list_name == 'tmpl.wlist')?.list ?? []
            let bl = lists.find(l => l.list_name == 'tmpl.wlist')?.list ?? []
            return {
                template_whitelist: wl.join(','),
                template_blacklist: bl.join(','),
            }
        }
    },
    account: {
        sortColumns: [
        ],
        grid: {
            gridCard: AssetCard,
            buttons: [],
            itemBaseUri: '/profile/',
        },
        query: api.getAssets,
        filters: [],
        getQueryParams: (lists) => {
            let nsfw = lists.find( l => l.list_name == 'col.nsfw')?.list ?? []
            let wl = lists.find(l => l.list_name == 'col.wlist')?.list ?? []
            let bl = lists.find(l => l.list_name == 'col.blist')?.list ?? []
            bl = [...bl, ...nsfw]
            return {
              collection_whitelist: wl.join(','),
              collection_blacklist: bl.join(','),
            }
        }
    },
    neftyDrops: {
        sortColumns: [
        ],
        grid: {
            gridCard: DropCard,
            buttons: [],
            itemBaseUri: '/explorer/drop/nefty/',
        },
        query: api.getNeftyDrops,
        filters: [],
        getQueryParams: (lists) => {
            return {}
        }
    },
    schema: {
        sortColumns: [
            {value: 'schema_name:asc', label: 'Name (A-Z)'},
            {value: 'schema_name:desc', label: 'Name (Z-A)'},
            {value: 'created:asc', label:'Created (Oldest)'},
            {value: 'created:desc', label:'Created (Newest)'},
        ],
        grid: {
            gridCard: SchemaCard,
            buttons: [],
            itemBaseUri: '/explorer/schema/',
        },
        query: api.getSchemas,
        filters: [],
        getQueryParams: (lists) => {
            let wl = lists.find(l => l.list_name == 'tmpl.wlist')?.list ?? []
            let bl = lists.find(l => l.list_name == 'tmpl.wlist')?.list ?? []
            return {
                template_whitelist: wl.join(','),
                template_blacklist: bl.join(','),
            }
        }
    },
    backedSchema: {
        sortColumns: [
            {value: 'schema_name:asc', label: 'Name (A-Z)'},
            {value: 'schema_name:desc', label: 'Name (Z-A)'},
            {value: 'created:asc', label:'Created (Oldest)'},
            {value: 'created:desc', label:'Created (Newest)'},
        ],
        grid: {
            gridCard: BackedSchemaCard,
            buttons: [],
            itemBaseUri: '/explorer/schema/',
        },
        query: api.getBackedSchemas,
        filters: [],
        getQueryParams: (lists) => {
            let wl = lists.find(l => l.list_name == 'tmpl.wlist')?.list ?? []
            let bl = lists.find(l => l.list_name == 'tmpl.wlist')?.list ?? []
            return {
                template_whitelist: wl.join(','),
                template_blacklist: bl.join(','),
            }
        }
    },

}

export default function InfiniteDataProvider( props : IInifiteDataProviderProps ) {

    const { pageSize, config, filterOptions, component, title, withSearch, whenEmpty } = props

    const additionalQueryParams = props.additionalQueryParams ?? {}

    const GridComponent = component ?? InfiniteItemGrid

    const popularityFilterDays = 7

    const [loaded, setLoaded] = useState(false)
    const [lists, setLists] = useState<Array<IList>>([])
    const [items, setItems] = useState<Array<any>>([])
    const [more, setMore] = useState(true)
    const [page, setPage] = useState(0)
    const [prevPage, setPrevPage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [queryParams, setQueryParams] = useState({})

    const [search, setSearch] = useState('')
    const [sort, setSort] = useState('')


    useEffect( () => {

        // TODO, these should be pulled from the NFT Backer contract in another useEffect firing before this one
        const listFilters = {
            'col.blist': [ 'earlyibmfans','ubersteve123' ],
        }
        
        const listAdd = {
            'col.wlist': [ 'earlyibmfans','ubersteve123'],
        }

        async function fetchLists() {
            let request = {
                json: true,               // Get the response as json
                code: 'atomhubtools',     // Contract that we target
                scope: 'atomhubtools',    // Account that owns the data
                table: 'acclists',        // Table name
                limit: 100,               // Maximum number of rows that we want to get PER REQUEST PAGE
                reverse: false,           // Optional: Get reversed data
                show_payer: false,        // Optional: Show ram payer
            };
    
            const res = await rpc.get_table_rows(request) as any
            const newLists = res.rows
    
            let formattedLists : any = [];
    
            newLists.forEach((list)=>{
              if ( listFilters[list.list_name] ) {
                // Remove anything in the filter list
                list.list = list.list.filter( l => ! listFilters[list.list_name].includes(l) )
              }
              if ( listAdd[list.list_name] ) {
                // Add anything in the add list
                list.list = [...list.list, ...listAdd[list.list_name] ]
              }
              formattedLists.push(list)
            })
    
            setLists(formattedLists)    
        }
    
        fetchLists();
    
    }, [rpc]);

    useEffect( () => {
        // when mounting, clear out items
        setItems([])    
    }, [])
    
    useEffect( () => {
        const params = config.getQueryParams(lists)
        setQueryParams(params)
    }, [lists])

    useEffect(() => {
        if ( lists.length == 0 ) { return }
        loadItems()
    },[queryParams])

    // Invalidate the current result set, then search
    useEffect(() => {
        if ( lists.length == 0 ) { return }
        setItems([])
        setPrevPage(page+0)
        setPage(0)
    },[search,sort])

    // Whenever page is set back to zero, we begin a fresh load
    useEffect(() => {
        if ( page == 0 && page != prevPage ) {
            loadItems()
        }
    },[page])

    const loadItems = async () => {
        setLoading(true)
    
        const query = {
          limit: pageSize,
          page: page+1,
        };
    
        if ( search ) { query['match'] = search }
    
        const results = await fetchData(query);
    
        // If we legitamately don't have any more assets, we're done
        if ( results.length == 0 ) {
          setMore(false)
          setLoading(false)
          setLoaded(true)
          return;
        }
    
        // Set our page counter to wherever we left off on the query
        setPage(query.page)

        // Add the results to our items
        setItems([...items, ...results])
        setLoading(false)
        setLoaded(true)
    }

    const fetchData = async (query : { limit: number, page: number }) => {
    
        let results : any[] = []
    
        // const queryMap = {
        //   // TODO: Change Backer back to getCollections
        //   backer: async (q,page,limit) => api.getCollectionMarketStats(q,page,limit),
        //   collection: async (q,page,limit) => api.getCollections(q,page,limit),
        //   template: async (q,page,limit) => api.getTemplates(q,page,limit),
        //   asset: async (q,page,limit) => api.getAssets(q,page,limit),
        //   account: async (q,page,limit) => api.getAccounts(q,page,limit),
        // }
    
        // const queryPopularityMap = {
        //   // TODO: Change Backer back to getCollections
        //   backer: async (q,page,limit) => api.getCollectionMarketStats(q,page,limit),
        //   collection: async (q,page,limit) => api.getCollectionMarketStats(q,page,limit),
        //   template: async (q,page,limit) => api.getTemplateMarketStats(q,page,limit),
        // }
    
        // let endpoint = queryMap[type]

        // Get the selected sort, or default to the first selected

        let currentSort = sort != '' ? sort : ( config.sortColumns.length? config.sortColumns[0].value : '' )

        if ( currentSort.startsWith('popularity') ) {
          let popularityStartDate = new Date(Date.now() - popularityFilterDays * 24 * 60 * 60 * 1000)
          popularityStartDate.setUTCHours(0,0,0,0) // Set to midnight UTC
          query['after'] = popularityStartDate.getTime()
          query['symbol'] = chain.name
        }
        else {
          const [sortField,sortOrder] = currentSort.split(':')
          if ( sortField ) { query['sort'] = sortField }
          if ( sortOrder ) { query['order'] = sortOrder }
        }  

        // Build our filter check reducer
        const otherFilterCheck = v => {
            if ( config.filters.length == 0 ) { return true }
            return config.filters.reduce( (a,b) => a && b(v), true )
        }

        // Apply any additional query parameters
        query = {...query, ...queryParams, ...additionalQueryParams}

        // Create a localized version of the query
        let q = {}

        // Copy everything that isn't 'page' or 'type' to our local query object (they get passed separately on the endpoint)
        Object.keys(query).forEach(v => {
          if ( v != 'page' && v != 'limit' ) {
            q[v] = query[v]
          }
        })
        
        // Use the querySelector to find the correct query, or use the query itself
        const endpoint = config.querySelector ? config.querySelector(query, currentSort) : config.query

        // If there's no endpoint, we can't try to load
        // (this check is only here to make TypeScript happy)
        if ( ! endpoint ) { return results }
    
        // Keep going until we have at least the page size of results after filtering
        while ( results.length < query.limit ) {
          let pageResults : any[] = []
          pageResults = await endpoint(q,query.page,query.limit)

          if ( pageResults.length == 0 ) { break; }
          pageResults = pageResults.filter((i) => otherFilterCheck(i))

          results = [...results, ...pageResults]
          break;
    
          // Increment to the next page
          query.page++
        }
    
        return results
    }

    const provider : IInifiteDataProvider = {
        next: loadItems,
        items: items,
        dataLength: items.length,
        hasMore: more
    }
  
    const searchForm = useForm({
      initialValues: {
        search: search,
        sort: config.sortColumns[0]?.value ?? '',
      },
    });  
  
    const resetSearch = () => {
      setSearch(searchForm.values.search)
      setSort(searchForm.values.sort)
    }

    const gridTitle = title ? ( <Title order={2} my='xl'>{title}</Title>) : ''

    if ( ! loaded ) {
        return (
            <></>
        )
    }

    if ( items.length == 0 && search == '' ) {
        return (
            <>
                {whenEmpty}
            </>
        )
    }

    const searchFormBody = withSearch ? (
        <form onSubmit={searchForm.onSubmit((values) => { resetSearch() })}>
        <Group position="center" py="xl">
            <Input size='md'
                placeholder="Search for anything"
                {...searchForm.getInputProps('search')}
            />
            {
                config.sortColumns.length ? (
                    <Select
                        data={config.sortColumns}
                        {...searchForm.getInputProps('sort')}
                    />
                ) : ''
            }
            <Button size='md' type="submit"><Search /></Button>
        </Group>
        </form>
    ) : ''
  
    return (
        <>
            {gridTitle}
            {searchFormBody}
            <GridComponent {...config.grid} provider={provider} />
        </>
    )


}