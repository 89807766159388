import { Container, Center, Space, Loader } from "@mantine/core"

export default function GridLoader() {
    return (
    <Container>
        <Container><Space h={50} /></Container>
        <Center>
          <Loader variant="dots" />
        </Center>
        <Container><Space h={100} /></Container>
      </Container>
    )
}