import React, { useContext, useEffect, useState } from 'react';
import { Grid, Skeleton, Container, useMantineTheme, Title, Center, createStyles, Avatar, ThemeIcon, SimpleGrid, Divider, Accordion, CardSection, ScrollArea, Anchor, LoadingOverlay, ActionIcon } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { UALContext } from 'ual-reactjs-renderer';

import { Table, Card, Image, Group, Text, Badge, Button } from '@mantine/core';

import * as ih from '../../lib/ImageHelper'
import _ from 'lodash'

import { Link, Stack2, Book, Template, ArrowsRightLeft, LayoutGridAdd, Flame, User, Barcode, AlignJustified, ListDetails, Briefcase, InfoCircle, CirclePlus, ArrowsLeftRight, Receipt, Replace, BuildingBank, Clock, BrandFacebook, BrandTwitter, BrandMedium, BrandGithub, BrandTelegram, BrandYoutube, BrandDiscord, MapPin, Id, LayoutGrid, Database, File } from 'tabler-icons-react';

import Moment from 'react-moment'
import { IAsset, IAssetStats, ITemplate, ICollection, ILog, ISchema } from 'atomicassets/build/API/Explorer/Objects';
import { useModals } from '@mantine/modals';
import { rpc } from '../..';
import LogTable from '../../components/NFT/LogTable';
import { CollectionStats } from '../../components/NFT/CollectionStats';
import { AssetsSort } from 'atomicassets/build/API/Explorer/Enums';
import ReactMarkdown from 'react-markdown';

import * as aa from '../../lib/AtomicRPC'

import { AAMiddleware } from '../../store/AAMiddleware'

import { useGlobalState } from '../../context/GlobalState';
import InfiniteDataProvider from '../../providers/InfiniteItemProvider';
import { configs as infiniteItemConfigs } from '../../providers/InfiniteItemProvider';
import { TemplateCard } from '../../components/NFT/cards/TemplateCard';
import { IElasticSchema, IElasticTemplate } from 'nftbackers-elastic-interfaces'

import { FixedItemGrid } from '../../components/NFT/FixedItemGrid';
import { CollectionSocials } from '../../components/NFT/CollectionSocials';
import { TabbedContent, ITabItem } from '../../components/TabbedContent';

const api = new AAMiddleware();

interface ContactIconProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
    icon: React.FC<any>;
    title: React.ReactNode;
    description: React.ReactNode;
}

interface ICollectionStat {
    assets: number;
    burned: number;
}

const useStyles = createStyles((theme) => ({
    card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },
  
    imageSection: {
      padding: theme.spacing.md,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottom: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,
    },
  
    label: {
      marginBottom: theme.spacing.xs,
      lineHeight: 1,
      fontWeight: 700,
      fontSize: theme.fontSizes.xs,
      letterSpacing: -0.25,
      textTransform: 'uppercase',
    },
  
    section: {
      padding: theme.spacing.md,
      borderTop: `1px solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
      }`,
    },
  
    icon: {
      marginRight: 5,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
    },

    title: {
    color: theme.colors.gray[6],
    },

    description: {
    color: theme.black,
    },

}));

export function Collection() {
    const [backers,setBackers] = useState([])
    const [collection,setCollection] = useState<ICollection>()
    const [stats,setStats] = useState<ICollectionStat>()
    const [templates,setTemplates] = useState<Array<IElasticTemplate>>()
    // const [templatesBySchema,setTemplatesBySchema] = useState({})
    const [schemas,setSchemas] = useState<Array<IElasticSchema>>()
    const [filters,setFilters] = useState<Array<any>>()
    const [accounts,setAccounts] = useState<Array<any>>()
    const [sales,setSales] = useState(0)
    const [templateCount,setTemplateCount] = useState(0)
    const [schemaCount,setSchemaCount] = useState(0)
    const [hasBackedAssets,setHasBackedAssets] = useState(false)
    const [hasDrops,setHasDrops] = useState(false)
    const [colTheme,setColTheme] = useState<any>()
    const [themeLoading,setThemeLoading] = useState(true)
    const [statsLoading,setStatsLoading] = useState(true)

    const { name } = useParams()

    // Get global state
    const { state, dispatch } = useGlobalState()
    const { isLoading } = state

    const theme = useMantineTheme();
    const { classes } = useStyles();

    const modals = useModals()

    const secondaryColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[1]
      : theme.colors.gray[7];

    const baseTextColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[7]
      : theme.colors.gray[9];

    const lightTextColor = theme.colorScheme === 'dark'
      ? theme.colors.dark[3]
      : theme.colors.gray[5];

   
    const child = <Skeleton height={140} radius="md" animate={false}    />;

    const descFields = ['desc','description']
    const legalFields = ['legal']
    const websiteFields = ['website']
    const unpackFields = ['unpack','unpackurl','unpack_url']

    const imageConfig = { fullHeight: 300, thumbHeight: 40 }


    // Load the backers
    useEffect( () => {
        const loadBackers = async () => {       
            const b = await api.getBackedCollectionsFromContract()
            setBackers(b)
        }
        loadBackers()
    }, [] )

    // Load the collection
    useEffect( () => {
        const loadCollection = async () => {       
            if ( ! name ) { return } 
            const c = await api.getCollection(name)
            setCollection(c)
        }
        loadCollection()
    }, [backers] )

    // Load the collection stats
    useEffect( () => {
        // Set loading to true so our spinner will cover the page
        dispatch({type:'SET_LOADING', payload:true})
        const loadStats = async () => {        

            const templates = await api.getTemplates({collection_name: name, limit: 1000})
            const s = { assets: 0, burned: 0 }
            for ( const template of templates ) {
                s.assets += parseInt(template.issued_supply); 
                // We don't know how many have burned using this method
                // s.burned += parseInt(stat.burned)
            }
            
            setStats(s)
            setStatsLoading(false)
        }
        loadStats()
    }, [] )

    useEffect( () => {
        if ( ! statsLoading && ! themeLoading ) {
            dispatch({type:'SET_LOADING', payload:false})
        }
    }, [statsLoading, themeLoading])

    // Load the total current sales
    useEffect( () => {
        const loadSalesCount = async () => {
            const query = {
                collection_name: name,
                state: 1,
            }   

            let newSales = await api.countSales(query)

            setSales(newSales)
        }
        loadSalesCount()
    }, [name] )

    // Load the total current templates
    useEffect( () => {
        const loadTemplateCount = async () => {
            if ( ! name ) { return }
            let t = await api.countTemplates(name)

            setTemplateCount(t)
        }
        loadTemplateCount()
    }, [name] )

    // Load the total current templates
    useEffect( () => {
        const loadSchemaCount = async () => {
            if ( ! name ) { return }
            let s = await api.countSchemas(name)

            setSchemaCount(s)
        }
        loadSchemaCount()
    }, [name] )

    // Check if the collection has backed assets
    useEffect( () => {
        const checkHasBackedAssets = async () => {
            if ( ! name ) { return }
            let b = await api.hasBackedAssets(name)

            setHasBackedAssets(b)
        }
        checkHasBackedAssets()
    }, [name] )


    // Check if the collection has drops
    useEffect( () => {
        const checkHasDrops = async () => {
            if ( ! name ) { return }
            let b = await api.hasDrops(name)

            setHasDrops(b)
        }
        checkHasDrops()
    }, [name] )

    

    useEffect( () => {
        async function fetchColFilters() {
            let request = {
                json: true,              // Get the response as json
                code: 'atomhubtools',    // Contract that we target
                scope: name,    // Account that owns the data
                table: 'colfilters',     // Table name
                limit: '1000',             // Maximum number of rows that we want to get PER REQUEST PAGE
                reverse: false,          // Optional: Get reversed data
                show_payer: false,       // Optional: Show ram payer
                lower_bound: null,
            };
    
            const res = await rpc.get_table_rows(request) as any
            const newFilters = res.rows
    
            setFilters(newFilters)
        }
    
        fetchColFilters();
    
      }, [rpc]);

      useEffect( () => {
        // Set loading to true so our spinner will cover the page
        dispatch({type:'SET_LOADING', payload:true})
        async function fetchColTheme() {
            let request = {
                json: true,
                code: "neftyblocksa",
                scope: "neftyblocksa",
                table: "colthemedata",
                lower_bound: name,
                upper_bound: name,
                index_position: 1,
                key_type: "",
                limit: 1,
                reverse: false,
                show_payer: false
            }
    
            const res = await rpc.get_table_rows(request) as any
            if ( res.rows.length ) {
                const data = JSON.parse(res.rows[0].theme_data)
                setColTheme(data)
                setThemeLoading(false)
            }
        }
    
        fetchColTheme();
    
      }, []);

    const openImageModal = () => {
        if ( ! collection ) { return }
        const id = modals.openModal({
          withCloseButton: false,
          children: (
            <Image 
                withPlaceholder 
                src={process.env.REACT_APP_IMAGE_PROXY + collection.img} 
                alt={name}
                onClick={modals.closeAll}
            />
          ),
        });
      };

    const imageCard = (
        collection ? 
            <>
                <Image 
                    fit="contain"
                    height={imageConfig.fullHeight}
                    withPlaceholder 
                    radius="lg" 
                    src={process.env.REACT_APP_IMAGE_PROXY + collection.img + '?h='+imageConfig.fullHeight} 
                    alt={name}
                    onClick={openImageModal}
                />
            </>
        : <Skeleton height={140} radius="md" animate={false} />
    )

    const scrubDescription = (desc:string) => {
        if ( ! desc ) { return desc }
        // Replace URLS
        desc = desc.replace('https://wax.atomichub.io/creator/collection/','/explorer/collection/')
        desc = desc.replace('https://wax.atomichub.io/explorer/collection/','/explorer/collection/')
        return desc 
    }

    const description = (
        <ReactMarkdown>{scrubDescription(collection?.data.description)}</ReactMarkdown>
    )

    const summaryCard = (
        collection ? 
            <Card shadow="sm" p="lg" radius="lg">
                <Text>{description}</Text>
            </Card>
        : <Skeleton height={140} radius="md" animate={false} />
    )

    const images = collection?.data.images ? JSON.parse(collection.data.images) : {}
    const heroImgUrl = images['banner_1920x500'] ? ih.resolveUrl(images['banner_1920x500'],'',true) : colTheme?.banner ? ih.resolveUrl(colTheme.banner) : '/images/default-banner.jpg'

    const bigNumberFormat = (num) => {
        if ( ! num ) { num = 0 }
        if(num < 900){
            return num; 
        }
        else if ( num > 999 && num < 1000000 ) {
            return (num/1000).toFixed(1) + 'K';
        }
        else if(num > 1000000){
            return (num/1000000).toFixed(1) + 'M';
        }
        return num;
    }

    const collectionStats = [
        { title: 'items', value: bigNumberFormat(
            ( stats?.assets ?? 0 ) - ( stats?.burned ?? 0 ) 
        ) },

        // { title: 'owners', value: bigNumberFormat(accounts?.length) },
        { title: 'listings', value: bigNumberFormat(sales+0) },
        // { title: 'volume', value: bigNumberFormat( schemas?.length ) },
        { title: 'templates', value: bigNumberFormat(templateCount) },
        { title: 'schemas', value: bigNumberFormat( schemaCount ) },
    ]

    const collectionImgUrl = ih.resolveUrl(collection ? collection.img : '', 'h=130')

    const dataSort = 'issued_supply:desc'
    const dataFilters = [
        {
            range: { issued_supply: {gt: 0} }
        }
    ]

    const backedDataFilters = [
        {
            range: { issued_supply: {gt: 0} },
            // exists: [ 'immutable_data.backedby' ]
            existsAny: [ 'immutable_data.backedby', 'immutable_data.backed tokens' ]
        }
    ]

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const tabItems : ITabItem[] = [
        { 
            label: 'Drops',
            component: InfiniteDataProvider,
            props: {
                withSearch: false,
                title: "Drops", 
                pageSize: 20,
                config: infiniteItemConfigs.neftyDrops,
                additionalQueryParams: {collection_name: name, filters: [], sort: dataSort},
                // component: FixedItemGrid
            }, 
            icon: Book,
            visible: hasDrops,
        },
        { 
            label: 'Backed',
            component: InfiniteDataProvider,
            props: {
                withSearch: false,
                title: "Backed Templates",
                pageSize: 20,
                config: infiniteItemConfigs.backedTemplateSearch,
                additionalQueryParams: {collection_name: name, filters: backedDataFilters, sort: dataSort},
                // component: FixedItemGrid,
            },
            icon: Database,
            visible: hasBackedAssets,
        },
        { 
            label: 'Templates',
            component: InfiniteDataProvider,
            props: {
                withSearch: true, 
                title: "Templates",
                pageSize: 20,
                config: infiniteItemConfigs.templateSearch,
                additionalQueryParams: {collection_name: name, filters: dataFilters, sort: dataSort},
                // component: FixedItemGrid,
            },
            icon: File,
            visible: ( templateCount > 0 ),
        },
        // { 
        //     label: 'NFTs',
        //     component: <InfiniteDataProvider
        //         withSearch={true} 
        //         title="NFTs" 
        //         pageSize={20} 
        //         config={infiniteItemConfigs.asset} 
        //         additionalQueryParams={{collection_name: name}}
        //         component={FixedItemGrid}
        //         />,
        //     icon: LayoutGrid,
        //     visible: true,
        // },
        // { label: 'Schemas', component: <Text>Schemas here</Text>, icon: Book, visible: true },
        { label: 'Accounts', component: <Text>Accounts here</Text>, icon: User, visible: false },
    ] 

    return (
        <>
            <Container fluid px={0} py={0}>
                <Image id='heroImage' height={220} width="100%" src={heroImgUrl} />
                <Container px='xl'>
                    <Group position='center'>
                        <Avatar mt={'-65px'} styles={{root: { backgroundColor: 'white', border: '2px solid white'  }}} radius={130} size={130} src={collectionImgUrl} />
                    </Group>
                    <Group position='center'>
                        <Title order={1}>{collection?.name}</Title>
                    </Group>
                    <Group position='center'>
                        <Title color={lightTextColor} order={6} style={{fontWeight:'normal'}}>Created by {collection?.author}</Title>
                    </Group>
                    <Group position='center'>
                        <CollectionStats data={collectionStats} />
                    </Group>
                </Container>
                <CollectionSocials collection={collection} />
            </Container>
            <Container py="xl">
            <Grid py="md">
                <Grid.Col xs={4}>{imageCard}</Grid.Col>
                <Grid.Col xs={8}>{summaryCard}</Grid.Col>
                <Grid.Col xs={12}>
                    <TabbedContent items={tabItems} />
                </Grid.Col>
            </Grid>
            </Container>
        </>
    );
}