import { Hero } from "./Hero";
import { AAMiddleware } from "../../../../store/AAMiddleware";
import { UserCollections } from "./Balances/UserCollections";

const api = new AAMiddleware()

export function Main() {
    return (
        <>
            <Hero />
            <UserCollections />
        </>
    )
}