import { useState, useEffect, useContext, useCallback } from 'react';
import { UALContext } from 'ual-reactjs-renderer';

import InfiniteScroll from 'react-infinite-scroll-component';
import AssetGrid from '../../components/NFT/AssetGrid';
import {GenericAssetCard} from '../../components/NFT/AssetGrid';

import GridLoader from '../../components/GridLoader';

import { Center, Loader, Space, Container, LoadingOverlay, Grid, Card, Image, Text, Badge, Button, Group, useMantineTheme, ListItem, Title } from '@mantine/core';
import { InfoCircle, Plus } from 'tabler-icons-react';

import { rpc } from '../..';
import { useNavigate } from 'react-router-dom';

import _ from 'lodash';
import { AAMiddleware } from '../../store/AAMiddleware';
import InfiniteDataProvider from '../../providers/InfiniteItemProvider';
import { configs as infiniteItemConfigs} from '../../providers/InfiniteItemProvider'

interface AssetItemProperties {
  asset_id: string;
  name: string;
  img: string;
  data: any;
  collection: any;
  backed_tokens: any;
  burned_at_time: any;
}

const api = new AAMiddleware();

export function Templates() {
  const theme = useMantineTheme();
  const ual = useContext(UALContext) as any;

  const listFilters = {
    'col.blist': [ 'earlyibmfans' ],
  }

  const listAdd = {
    'col.wlist': [ 'earlyibmfans' ],
  }

  const [backedCollections, setBackedCollections] = useState([]) as any
  const [lists, setLists] = useState([]) as any

  const pageSize = 10

  let isLoaded = false;

  useEffect( () => {
    async function fetchLists() {

        let requestLists = {
            json: true,               // Get the response as json
            code: process.env.REACT_APP_NFTBACKER_CONTRACT,     // Contract that we target
            scope: process.env.REACT_APP_NFTBACKER_CONTRACT,    // Account that owns the data
            table: 'collections',     // Table name
            limit: 100,               // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
        };

        const resLists = await rpc.get_table_rows(requestLists) as any
        const newCollections = resLists.rows
        setBackedCollections(newCollections)    

        let request = {
            json: true,               // Get the response as json
            code: 'atomhubtools',     // Contract that we target
            scope: 'atomhubtools',    // Account that owns the data
            table: 'acclists',        // Table name
            limit: 100,               // Maximum number of rows that we want to get PER REQUEST PAGE
            reverse: false,           // Optional: Get reversed data
            show_payer: false,        // Optional: Show ram payer
        };

        const res = await rpc.get_table_rows(request) as any
        const newLists = res.rows

        let formattedLists : any = [];

        const backerColls = newCollections.map((c)=>c.collection_name)

        newLists.forEach((list)=>{
          // Remove nftbacker collections from col blacklist
          if ( list.list_name == 'col.blist' ) {
            list.list = list.list.filter( l => ! backerColls.includes(l) )
          }
          // Add nftbacker collections to col whitelist
          if ( list.list_name == 'col.wlist' ) {
            list.list = [...backerColls, ...list.list ]
          }
          formattedLists.push(list)
        })

        setLists(formattedLists)    
    }

    fetchLists();

  }, [ual.rpc]);


  const secondaryColor = theme.colorScheme === 'dark'
    ? theme.colors.dark[1]
    : theme.colors.gray[7];

  const buttons = [
  ]

  const backedDataFilters = [
    {
        range: { issued_supply: {gt: 0} },
    }
]



  // if ( lists?.length ) { return <></> }

  // const whitelist = _.find(lists, {list_name:'col.wlist'})
  // const collectionWhitelist = whitelist?.list.join(',')

  // if ( ! collectionWhitelist ) { return <></> }

  return (
    <Container px='xl' py='xl'>
        <Grid>
            <Grid.Col xs={12}>
                <InfiniteDataProvider withSearch={true} title="Templates" pageSize={20} config={infiniteItemConfigs.templateSearch} additionalQueryParams={{filters: backedDataFilters}} ></InfiniteDataProvider>
            </Grid.Col>
        </Grid>
    </Container>
  );
}