import { useContext } from 'react';
import { UALContext } from 'ual-reactjs-renderer';

import { showNotification, updateNotification } from '@mantine/notifications';
import { Flame, AlertTriangle, Check } from 'tabler-icons-react';

export default async function BurnAsset(asset, ual) {

    const auth = [{
        actor: ual.activeUser.accountName,
        permission: ual.activeUser.requestPermission,
    }]

    let actions : any = []

    actions.push({
        account: 'atomicassets',
        name: 'burnasset',
        authorization: auth,
        data: {
            asset_owner: ual.activeUser.accountName,
            asset_id: asset.asset_id,
        }
    });

    asset.backed_tokens.map((t) => {
        actions.push({
            account: process.env.REACT_APP_NFTBACKER_CONTRACT,
            name: 'withdraw',
            authorization: auth,
            data: {
                owner: ual.activeUser.accountName,
                token_to_withdraw: `${t.amount} ${t.token_symbol}`,
            }
        })
    })

    console.log('ACTIONS ARE')
    console.log(actions)

    try {
        showNotification({
            id: `burn-asset-${asset.asset_id}`,
            loading: true,
            title: `Burning NFT ${asset.asset_id}`,
            message: 'Preparing to burn this asset and transfer the backed tokens to your wallet.',
            autoClose: false,
            disallowClose: true,
            icon: <Flame />,
        });

        const res = await ual.activeUser.signTransaction(
            {
                actions: actions
            },
            {
                blocksBehind: 3,
                expireSeconds: 60
            }
        );
        updateNotification({
            id: `burn-asset-${asset.asset_id}`,
            color: 'teal',
            title: `Success!`,
            message: `Asset ${asset.asset_id} was successfully burned and the backed tokens were transferred to your wallet.`,
            icon: <Check />,
            autoClose: 10000,
        });
    } catch (e : any ) {
        console.log(e);

        const msg = e.message.replace('assertion failure with message:','');

        updateNotification({
            id: `burn-asset-${asset.asset_id}`,
            color: 'red',
            title: `Unable to burn asset ${asset.asset_id}`,
            message: msg,
            icon: <AlertTriangle />,
            autoClose: false,
        });
    }

}